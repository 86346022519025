import React from 'react'
import AllHeader from '../../../components/Navbar/AllHeader'
import Filter from '../destination/components/Filter'
import BodyBooking from './components/BodyBooking'
import Footer from '../../../components/footerPage/Footer'

function Booking() {
  return (
    <div>
      <AllHeader/>
      <BodyBooking/>
    </div>
  )
}

export default Booking