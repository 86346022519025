import { createSlice } from "@reduxjs/toolkit";
import { getYachtTypes } from "../../API/YachtTypesAPI";

const initialState = {
  YachtTypes: [],
};

export const YachtTypesSlice = createSlice({
  name: "YachtTypesState",
  initialState,
  reducers: {
    updateGetYachtTypes: (state, action) => {
      state.YachtTypes = action.payload;
    },
  },
});

export const { updateGetYachtTypes } = YachtTypesSlice.actions;
export const getYachtTypesDetail = (value) => async (dispatch) => {
  await getYachtTypes(value).then(async (data) => {
    await dispatch(updateGetYachtTypes(data));
    //   await dispatch(stopLoading());
  });
};

export default YachtTypesSlice.reducer;
