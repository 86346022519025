import React, { useRef, useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import ThumbnailCard from "../../../../components/card/ThumbnailCard";
import { useSelector, useDispatch } from "react-redux";
import "react-multi-carousel/lib/styles.css";
import BoatHelm from "../../../../Asset/icon/boat_helm.svg";
import Crab from "../../../../Asset/icon/Crab.svg";
import BinocularsRight from "../../../../Asset/icon/binoculars-right.svg";
import BinocularsLeft from "../../../../Asset/icon/Binoculars-left.svg";
import Image from "../../../../Asset/image/yacht/bebinca-thumb.jpg";
import CustomButtonGroupAsArrows from "../../../../components/carousel/CustomButtonGroupAsArrows";
import { Link, json } from "react-router-dom";
import { getYachtDetail } from "../../../../redux/yacht/YachtSlice";
import { getPromotionDetail } from "../../../../redux/promotion/PromotionSlice";
import { getYachtInformationDetail } from "../../../../redux/yachtInformation/YachtInformationSlice";
import { getYachtInfoSellingPriceRatePlanListDetail } from "../../../../redux/YachtInfoSellingPriceRatePlanList/YachtInfoSellingPriceRatePlanListSlice";
import "../../../../Asset/scss/Homepages/Hotdeals.scss"
import {
  getProvinceDetail,
  getCountryDetail,
  getMarinaDetail,
  getNationalityDetail
} from "../../../../redux/centerData/CenterDataSlice";
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from "react-i18next";
import zIndex from "@mui/material/styles/zIndex";

function Hotdeal() {


  const { t } = useTranslation();
  const selectedLanguage = localStorage.getItem("selectedLanguage");

  const carouselRef1 = useRef(null);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 2999, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1023, min: 720 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 719, min: 0 },
      items: 2,
      partialVisibilityGutter: 30, // Adjust the gutter according to your design
      partialVisibility: true,
    },
  };


  const CustomDot = ({ onClick, index, active }) => (
    <button
      style={{
        background: active ? "#00305c" : "grey",
        width: "5px",
        height: "5px",
        margin: "auto",
        border: "none",
        borderRadius: "50%",
        cursor: "pointer",
      }}
      onClick={() => onClick()}
    ></button>
  );


  const [loading, setLoading] = useState(true); // Initial loading state
  // const [filteredYachtData, setFilteredYachtData] = useState([]);
  // const prevYachtresults = useRef([]);
  const [selectedProvince, setSelectedProvince] = useState("Phuket");

  const todos = useSelector((state) => state);
  const dispatch = useDispatch();

  const calculateDiscountedPrice = (
    originalPrice,
    discountValue,
    discountType
  ) => {
    const parsedOriginalPrice = parseFloat(originalPrice);
    const parsedDiscountValue = parseFloat(discountValue);

    if (isNaN(parsedOriginalPrice) || isNaN(parsedDiscountValue)) {
      return "Invalid Price or Discount";
    }

    let discountedPrice;

    if (discountType === "Percent") {
      // Discount is a percentage
      const discountedAmount =
        (parsedOriginalPrice * parsedDiscountValue) / 100;
      discountedPrice = parsedOriginalPrice - discountedAmount;
    } else {
      // Discount is a fixed amount
      discountedPrice = parsedOriginalPrice - parsedDiscountValue;
    }

    // If the discounted price is less than or equal to 0, return "FREE"
    return discountedPrice <= 0 ? "FREE" : discountedPrice.toFixed(2);
  };

  useEffect(() => {
    // Fetch data from the API

    Promise.all([
      dispatch(getProvinceDetail({})),
      dispatch(getCountryDetail({})),
      dispatch(getMarinaDetail({})),
      dispatch(getYachtDetail({})),
      dispatch(getYachtInformationDetail({})),
      dispatch(getPromotionDetail({})),
      dispatch(getYachtInfoSellingPriceRatePlanListDetail({})),
      dispatch(getNationalityDetail({})),
    ])
      .then(() => {
        // Data loading is complete, set loading state to false
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error if needed
      });
  }, [dispatch]);
  // console.log(todos);
  let globalIndexCounter = 0;
  // let filteredAndIndexedPromotions;
  if (loading) {
    return <div> <CircularProgress style={{ color: '#00305c' }} /> </div>;
  }

  const filteredAndIndexedPromotions =
    todos &&
      todos.YachtInfoSellingPriceRatePlanListSlice &&
      todos.YachtInfoSellingPriceRatePlanListSlice
        .YachtInfoSellingPriceRatePlanList &&
      todos.PromotionSlice &&
      todos.YachtInformationSlice &&
      todos.CenterDataSlice &&
      todos.CenterDataSlice.Marina &&
      todos.CenterDataSlice.Province &&
      todos.YachtSlice
      ? todos.YachtInfoSellingPriceRatePlanListSlice.YachtInfoSellingPriceRatePlanList.results
        .filter((item) => item.rate_plan_use_in_TMY_web_site === 1)
        .map((filteredItem) => {
          // Find matching Promotions
          const matchingPromotions = todos.PromotionSlice.Promotion.results
            .filter(
              (promotionItem) =>
                promotionItem.id_yacht === filteredItem.id_yacht &&
                promotionItem.promotion_type === "CUSTOMIZE"
              //&& new Date(promotionItem.end_date_valid) > new Date() // Check if the promotion is not expired
            )
            .map((promotionItem) => ({
              ...promotionItem,
              value_sellingPrice: filteredItem.value_sellingPrice,
              id_yachtInfo: filteredItem.id_yachtInfo, // Add this line
              uniqueIndex: globalIndexCounter++,
            }));
          return matchingPromotions;
        })
        .flat()
      : null; // Adjust the fallback value based on your needs

 // console.log(filteredAndIndexedPromotions);

  if (!filteredAndIndexedPromotions) {
    console.error("Data not available");
    return <div>Data not available</div>;
  }


  // console.log(todos);
  return (
    <div className="bodyTop-container">
      <div className="Header-Frame" style={{
        width: " 100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        alignContent: " center",
        flexWrap: "wrap",
        flexDirection: "column",
      }}>
        <div>
          <img style={{ cursor: "pointer" }} draggable={false} className="BoatHelm-Tophotdeal" src={BoatHelm} alt="BoatHelm" />
        </div>
        <div className="hotdeal-ofday">
          <h2>
            <img className="BoatHelm-hotdeal" src={Crab} alt="BoatHelm" />
          </h2>
          <h2 style={{ cursor: "pointer" }} onClick={() => setSelectedProvince("")} >{t("hotDeal")}</h2>
          <h2>
            <img className="BoatHelm-hotdeal" draggable={false} src={Crab} alt="BoatHelm" />
          </h2>
        </div>

        <div className="hotdeal-ofday">
          <h2>
            <img
              draggable={false}
              className="BoatHelm-hotdeal"
              src={BinocularsRight}
              alt="BoatHelm"
            />
          </h2>

          <h2 style={{ cursor: "pointer" }}>
            {todos?.CenterDataSlice?.Marina?.results?.length > 0 ? (
              Array.from(
                new Set(
                  todos.CenterDataSlice.Marina.results.map((filteredItem) => {
                    const matchingProvince = todos.CenterDataSlice.Province.results?.find(
                      (provinceItem) => provinceItem.ProvinceID === filteredItem.id_province
                    );

                    const provinceName = matchingProvince
                      ? selectedLanguage === "th"
                        ? matchingProvince.ProvinceThai
                        : matchingProvince.ProvinceEng
                      : null;

                    return JSON.stringify({
                      name: provinceName,
                      engName: matchingProvince?.ProvinceEng,
                    });
                  })
                )
              )
                .slice(0, 5)
                .map((provinceStr, index, array) => {
                  const { name, engName } = JSON.parse(provinceStr);
                  return (
                    <div
                      key={index}
                      className={selectedProvince === engName ? "selected" : ""}
                      onClick={() => setSelectedProvince(engName)}
                    >
                      <span className="province-hot"> {name} </span>
                      {index < array.length - 1}
                    </div>
                  );
                })
            ) : (
              <div>
                <CircularProgress style={{ color: '#00305c' }} />
              </div>
            )}
          </h2>


          <h2>
            <img
              draggable={false}
              className="BoatHelm-hotdeal"
              src={BinocularsLeft}
              alt="BoatHelm"
            />
          </h2>
        </div>
      </div>
      {loading ? (
        <div><CircularProgress style={{ color: '#00305c' }} /></div>
      ) : (
        <div style={{ position: "relative", margin: "0rem", width: "100%" }}>
          <div className="carousel-container">
            <Carousel
              ref={carouselRef1}
              customButtonGroup={
                window.innerWidth >= 719 ? (
                  <CustomButtonGroupAsArrows
                    carouselRefs={[carouselRef1]}
                    customPreviousStyle={{ left: "-10vh", zIndex: "999" }}
                    customNextStyle={{ right: "-10vh", zIndex: "999" }}
                  />
                ) : null
              }
              renderButtonGroupOutside
              additionalTransfrom={0}
              arrows={false}
              centerMode={window.innerWidth >= 719 ? true : false}
              className=""
              containerClass="container"
              dotListClass=""
              draggable
              focusOnSelect={false}
              infinite
              itemClass=""
              keyBoardControl
              minimumTouchDrag={80}
              pauseOnHover
              renderArrowsWhenDisabled={false}
              renderDotsOutside={false}
              responsive={responsive}
              rewind={false}
              rewindWithAnimation={false}
              rtl={false}
              shouldResetAutoplay
              showDots={window.innerWidth >= 719 ? false : true}
              customDot={<CustomDot />}
              sliderClass=""
              slidesToSlide={1}
              swipeable
              autoPlay={true}
              autoPlaySpeed={4000}
            >
              {filteredAndIndexedPromotions.map((promotion, index) => {
                const matchingYachtInformation =
                  todos.YachtInformationSlice.YachtInformation.results.find(
                    (yachtItem) =>
                      yachtItem.id_yachtInfo === promotion.id_yachtInfo
                  );

                const yachtDetail = todos.YachtSlice.Yacht.results.find(
                  (YachtDetailItem) =>
                    YachtDetailItem.id_yacht === promotion.id_yacht
                );
                const matchingMarina =
                  todos.CenterDataSlice.Marina.results.find(
                    (MarinaItem) =>
                      MarinaItem.id_marina ===
                      matchingYachtInformation.id_marina
                  );
                const matchingProvince =
                  todos.CenterDataSlice.Province.results.find(
                    (provinceItem) =>
                      provinceItem.ProvinceID === matchingMarina.id_province
                  );
                if (
                  !selectedProvince ||
                  (matchingProvince &&
                    matchingProvince.ProvinceEng === selectedProvince)
                ) {
                  return (
                    <div key={index} className="row">
                      <div>
                        <Link
                          to={`/yacht/detailYacht/${matchingYachtInformation.id_yachtInfo}`}

                        >
                          <ThumbnailCard
                            image={matchingMarina.marina_img}
                            headline={
                              matchingYachtInformation.name_yacht_yachtInfo
                            }
                            location={selectedLanguage === "th" ? matchingMarina.name_marina_th : matchingMarina.name_marina_en}
                            province={selectedLanguage === "th" ? matchingProvince.ProvinceThai : matchingProvince.ProvinceEng}
                            vaildDate={`${promotion.start_date_valid} - ${promotion.end_date_valid}`}
                            price={calculateDiscountedPrice(
                              promotion.value_sellingPrice,
                              promotion.discount_value_promotion,
                              promotion.discount_promotion
                            )}
                            currency={yachtDetail.currency}
                            discount_promotion={
                              promotion.discount_promotion === "Percent"
                                ? "%"
                                : promotion.discount_promotion
                            }
                            discount_value_promotion={
                              promotion.discount_value_promotion
                            }
                          />
                        </Link>
                      </div>
                    </div>
                  );
                }
              })}
            </Carousel>

          </div>
        </div>
      )}
    </div>
  );
}

export default Hotdeal;
