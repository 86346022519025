import { createSlice } from "@reduxjs/toolkit";
import { getYachtInfoWaterToy } from "../../API/YachtInfoWaterToyAPI";

const initialState = {
  YachtInfoWaterToy: [],
};

export const YachtInfoWaterToySlice = createSlice({
  name: "YachtInfoWaterToyState",
  initialState,
  reducers: {
    updateGetYachtInfoWaterToy: (state, action) => {
      state.YachtInfoWaterToy = action.payload;
    },
  },
});

export const { updateGetYachtInfoWaterToy } = YachtInfoWaterToySlice.actions;
export const getYachtInfoWaterToyDetail = (value) => async (dispatch) => {
  await getYachtInfoWaterToy(value).then(async (data) => {
    await dispatch(updateGetYachtInfoWaterToy(data));
    //   await dispatch(stopLoading());
  });
};

export default YachtInfoWaterToySlice.reducer;
