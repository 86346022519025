import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Grid, CircularProgress } from "@mui/material";
import { ProvincesData } from "../../../../../components/data/ProvincesData";
import BoatHelm from "../../../../../Asset/icon/boat_helm.svg";
import ProvincesCard from "../../../../../components/card/ProvincesCard";
import { Link } from "react-router-dom";
import Image from "../../../../../Asset/image/province/Ayutthaya.jpg";
import {
  getMarinaDetail,
  getProvinceDetail,
  getProvinceDescriptionDetail
} from "../../../../../redux/centerData/CenterDataSlice";
import "../../../../../Asset/scss/destination/ProvinceCard.scss"


function DestinationProvinces() {
  const selectedLanguage = localStorage.getItem("selectedLanguage");
  const { t } = useTranslation();
  const todos = useSelector((state) => state);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Promise.all([
      dispatch(getProvinceDetail({})),
      dispatch(getProvinceDescriptionDetail({})),
      dispatch(getMarinaDetail({})),
    ])
      .then(() => setLoading(false))
      .catch((error) => console.error("Error fetching data:", error));
  }, [dispatch]);


  return (
    <div>
      <div className="mb-4" style={{ justifyContent: "center", alignItems: "center", marginBottom: '2vh' }}>
        <img className="BoatHelm-1" draggable={false} src={BoatHelm} alt="BoatHelm" />
        <h2>{t("Provinces")}</h2>
      </div>
      <div>
        {loading ? (
          <div><CircularProgress style={{ color: '#00305c' }} /></div>
        ) : (
          <Grid container spacing={{ xs: 0, md: 0}} rowGap={2} columnGap={2} justifyContent={"center"}>
            {todos.CenterDataSlice.Marina.results &&
              Array.from(
                new Set(
                  todos.CenterDataSlice.Marina.results.map(
                    (Marina) => Marina.id_province
                  )
                )
              ).map((uniqueProvinceId, index) => {
                const matchingProvince =
                  todos.CenterDataSlice.Province.results.find(
                    (provinceItem) =>
                      provinceItem.ProvinceID === uniqueProvinceId
                  );
                const provinceDescription =
                  todos.CenterDataSlice.ProvinceDescription.results.find(
                    (description) =>
                      description.id_province === uniqueProvinceId
                  );

                return (
                  <div className="ProvinceCard" >
                  <Grid item xs={1} md={12} key={index} >
                    {matchingProvince && (
                      <Link
                        to={`/destination/detailProvince/${matchingProvince.ProvinceEng}`}
                      >
                        <ProvincesCard
                          name={
                            selectedLanguage === 'en'
                              ? matchingProvince.ProvinceEng
                              : matchingProvince.ProvinceThai
                          }
                          imageSrc={provinceDescription.province_description_img}
                          country={
                            selectedLanguage === 'en'
                              ? "Thailand"
                              : "ประเทศไทย"
                          }
                        />
                      </Link>
                    )}
                  </Grid>
                  </div>
                );
              })}
          </Grid>
          
        )}
      </div>
    </div>
  );
}

export default DestinationProvinces;
