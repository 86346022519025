import React, { useState, useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import AllHeader from "../../../components/Navbar/AllHeader";
import Footer from "../../../components/footerPage/Footer";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import { Card, CardContent, Typography, Grid } from "@material-ui/core";
import BodyInformation from "./components/BodyInformation"
import BodyBooking from "./components/BodyBooking"
import BodyReview from "./components/BodyReview"
import BodyWhitelist from "./components/BodyWhitelist"
import { useLocation } from "react-router-dom";
import ProfileMenuSVG from "../../../components/card/profileMenuDetail";

function PersonalInfo() {
    const location = useLocation();
    const { state } = location;
    const initialComponent = state?.selectedComponent || "personal";
    const [selectedComponent, setSelectedComponent] = useState(initialComponent);

    useEffect(() => {
        const handleUpdateSelectedComponent = (event) => {
            setSelectedComponent(event.detail.selectedComponent);
        };

        window.addEventListener('updateSelectedComponent', handleUpdateSelectedComponent);

        return () => {
            window.removeEventListener('updateSelectedComponent', handleUpdateSelectedComponent);
        };
    }, []);

    useEffect(() => {
        if (state?.selectedComponent) {
            setSelectedComponent(state.selectedComponent);
        }
    }, [state]);


    const renderSelectedComponent = () => {
        switch (selectedComponent) {
            case "personal":
                return <BodyInformation />;
            case "booking":
                return <BodyBooking />;
            case "review":
                return <BodyReview />;
            case "whitelist":
                return <BodyWhitelist />;
            default:
                return <BodyInformation />;
        }
    };

    return (
        <div>
            <AllHeader />
            <div className="container">
                <div className="row align-items-stretch">
                    <div className="col-lg-1 order-md-1 d-none d-lg-block">{/* Content for the left column */}</div>
                    <div className="col-lg-4 text-center order-md-1 col-md-1 mb-4">
                        <div style={{ position: 'absolute', top: -315, zIndex: 999 }}>
                            <ProfileMenuSVG onSelect={setSelectedComponent} SelectNow={selectedComponent} />
                        </div>
                    </div>
                    <div className="col-lg-6 text-center order-md-2 col-md-12 mb-4">
                        {renderSelectedComponent()}
                    </div>
                    <div className="col-lg-1 order-md-3 d-none d-lg-block">{/* Content for the right column */}</div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default PersonalInfo;