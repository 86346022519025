import ProviderAPI from "../redux/ProviderAPI";

export const getCountry = async (data, token) => {
  const result = await ProviderAPI({
    type: "GET",
    url: "/center-data/country",
    data,
    token,
  });
  //   console.log(result);
  return result;
};


export const getCountryValue = async (data, token) => {
  const result = await ProviderAPI({
    type: "GET",
    url: "/center-data/countryValue",
    data,
    token,
  });
  //   console.log(result);
  return result;
};

export const getProvince = async (data, token) => {
  const result = await ProviderAPI({
    type: "GET",
    url: "/center-data/Province",
    data,
    token,
  });
  //   console.log(result);
  return result;
};

export const getProvinceDescription = async (data, token) => {
  const result = await ProviderAPI({
    type: "GET",
    url: "/center-data/ProvinceDescription",
    data,
    token,
  });
  //   console.log(result);
  return result;
};

export const getLocationWater = async (data, token) => {
  const result = await ProviderAPI({
    type: "GET",
    url: "/center-data/LocationWater",
    data,
    token,
  });
  //   console.log(result);
  return result;
};
export const getDestination = async (data, token) => {
  const result = await ProviderAPI({
    type: "GET",
    url: "/center-data/Destination",
    data,
    token,
  });
  //   console.log(result);
  return result;
};

export const getMarina = async (data, token) => {
  const result = await ProviderAPI({
    type: "GET",
    url: "/center-data/Marina",
    data,
    token,
  });
  //   console.log(result);
  return result;
};

export const getNationality = async (data, token) => {
  const result = await ProviderAPI({
    type: "GET",
    url: "/center-data/Nationality",
    data,
    token,
  });
  //   console.log(result);
  return result;
};
