import React from "react";
import AllHeader from "../../../components/Navbar/AllHeader";
import Filter from "../destination/components/Filter";
import Footer from "../../../components/footerPage/Footer";
import BodyContact from "../contact/components/BodyContact";
import BodyBecome from "./components/BodyBecome";

function Contact() {
  return (
    <div>
      <AllHeader />
      <BodyBecome />
    </div>
  );
}

export default Contact;
