import React from "react";
import Footer from "../../../components/footerPage/Footer";
import AllHeader from "../../../components/Navbar/AllHeader";
import BodyYacht from "./components/BodyYacht";
function Yacht() {


  return (
    <div>
      <AllHeader />
      <BodyYacht/>
      <Footer/>
    </div>
  );
}

export default Yacht;
