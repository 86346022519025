import ProviderAPI from "../redux/ProviderAPI";

export const getBooking = async (data, token) => {
  const result = await ProviderAPI({
    type: "GET",
    url: "booking/detailBooking",
    data,
    token,
  });
  return result;
};

export const getBookingHistory = async (data) => {
  const result = await ProviderAPI({
    type: "GET",
    url: "booking/bookingHistoryDetail",
    data: data,
    headers: data.token,
  });
  return result;
};

export const createBooking = async (data, token) => {
  const result = await ProviderAPI({
    type: "POST",
    url: "booking/createBooking",
    data,
    token,
  });
  return result;
};

export const login = async (data, token) => {
  const result = await ProviderAPI({
    type: "POST",
    url: "booking/login",
    data,
    token,
  });
  return result;
};


export const cancelBooking = async (data, token) => {
  const result = await ProviderAPI({
    type: "POST",
    url: "booking/cancelBooking",
    data,
    token,
  });
  return result;
};
