import ProviderAPI from "../redux/ProviderAPI";

export const getYachtInfoSellingPriceRatePlanList = async (data, token) => {
  const result = await ProviderAPI({
    type: "GET",
    url: "yachtInfoSellingPriceRatePlanList/detailYachtInfoSellingPriceRatePlanList",
    data,
    token,
  });
  // console.log(result);
  return result;
};
