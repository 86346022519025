import React from "react";

import Header from "./components/Header";
import BodyTopDestinations from "./components/BodyTopDestinations";
import Footer from "../../../components/footerPage/Footer";
import Hotdeal from "./components/Hotdeal";
import BestSeller from "./components/BestSeller";


function HomePage() {


  return (
    <div className="App">
        <Header />
        <BodyTopDestinations />
        <Hotdeal />
        <BestSeller />
        <Footer />
      </div>
  );
}

export default HomePage;
