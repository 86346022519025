import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Element } from "react-scroll";
import { useParams } from "react-router-dom";
import { ProvincesData } from "../data/ProvincesData";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import BoatHelm from "../../Asset/icon/boat_helm.svg";
import Location from "../../Asset/icon/location.svg";
import DetailsAssetsProvince from "../../Asset/icon/detailsAssetsProvince.svg";
import ProvinceDetail from "./provinceAssetDetail";
import { useSelector, useDispatch } from "react-redux";
import { getYachtInfoSellingPriceRatePlanListDetail } from "../../../src/redux/YachtInfoSellingPriceRatePlanList/YachtInfoSellingPriceRatePlanListSlice";
import { getYachtInfoAmenitiesDetail } from "../../../src/redux/YachtInfoAmenities/YachtInfoAmenitiesSlice";
import { getYachtInfoWaterToyDetail } from "../../redux/YachtInfoWaterToy/YachtInfoWaterToySlice";
import { getYachtInfoGalleryDetail } from "../../redux/yachtInfoGallery/YachtInfoGallerySlice";
import { getYachtInfoDestinationPackageDetail } from "../../redux/yachtInfoDestinationPackage/YachtInfoDestinationPackageSlice";
import { getYachtTypesDetail } from "../../redux/YachtTypes/YachtTypesSlice";
import {
  getMarinaDetail,
  getProvinceDetail,
  getDestinationDetail,
  getProvinceDescriptionDetail,
} from "../../redux/centerData/CenterDataSlice";
import { getYachtDetail } from "../../redux/yacht/YachtSlice";
import Image from "../../Asset/image/province/ChiangMai.jpg";
import { useTranslation } from "react-i18next";
import ProvincesCard from "./ProvincesCard";
import DestinationCard from "./DestinationCard"
import ImageUnderCard from "./ImageUnderCard";
import { Grid } from "@mui/material";
import "../../Asset/scss/destination/ProvinceCard.scss"

const ProvinceCard = () => {
  const selectedLanguage = localStorage.getItem("selectedLanguage");
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const itemsToShow = isMobile ? 12 : 6;

  const IconStyle = {
    maxWidth: "2vh",
    height: "auto",
    marginRight: "1vh",
  };
  const { slug } = useParams();
  const todos = useSelector((state) => state);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true); // Initial loading state
  useEffect(() => {
    // Fetch data from the API

    Promise.all([
      dispatch(getMarinaDetail({})),
      dispatch(getProvinceDetail({})),
      dispatch(getDestinationDetail({})),
      dispatch(getYachtDetail({})),
      dispatch(getYachtInfoGalleryDetail({})),
      dispatch(getYachtInfoDestinationPackageDetail({})),
      dispatch(getYachtInfoWaterToyDetail({})),
      dispatch(getYachtInfoSellingPriceRatePlanListDetail({})),
      dispatch(getYachtInfoAmenitiesDetail({})),
      dispatch(getYachtTypesDetail({})),
      dispatch(getProvinceDescriptionDetail({})),

    ])
      .then(() => {
        // Data loading is complete, set loading state to false
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error if needed
      });
  }, [dispatch]);
  console.log(todos);

  const selectedProvince = loading
    ? null // Set selectedProvince to null while loading
    : todos.CenterDataSlice.Province.results.find(
      (province) => province.ProvinceEng === slug
    );

  const selectedProvinceDescription = loading
    ? null
    : todos.CenterDataSlice.ProvinceDescription.results.find(
      (provinceDes) => provinceDes.id_province === selectedProvince.ProvinceID
    );

  const selectedDestinations = loading
    ? []
    : todos.CenterDataSlice.Destination.results.filter(
      (destination) => destination.id_province === selectedProvince.ProvinceID
    );

  const selectedDestination = loading
    ? null
    : todos.CenterDataSlice.Destination.results.filter((Destination) => {
      const matchingProvince = todos.CenterDataSlice.Province.results.find(
        (province) => province.ProvinceID === Destination.id_province
      );

      // Check if matchingProvince exists and matches the selected province
      if (
        !selectedProvince ||
        (matchingProvince &&
          matchingProvince.ProvinceEng === selectedProvince)

      );
    });

  const selectedYacht = loading
    ? null
    : todos.YachtInfoSellingPriceRatePlanListSlice.YachtInfoSellingPriceRatePlanList.results.filter(
      (YachtInfoSellingPriceRatePlanList) =>
        YachtInfoSellingPriceRatePlanList.rate_plan_use_in_TMY_web_site === 1
    );

  const selectedYachtWithMarinaAndProvince = loading
    ? []
    : (() => {
      const selectedYachtWithAdditionalInfo = selectedYacht.map((yacht) => ({
        ...yacht,
        name_marina_en: todos.CenterDataSlice.Marina.results.find(
          (marina) => marina.id_marina === yacht.id_marina
        )?.name_marina_en,

        name_marina_th: todos.CenterDataSlice.Marina.results.find(
          (marina) => marina.id_marina === yacht.id_marina
        )?.name_marina_th,

        ProvinceEng: todos.CenterDataSlice.Province.results.find(
          (province) =>
            province.ProvinceID ===
            todos.CenterDataSlice.Marina.results.find(
              (marina) => marina.id_marina === yacht.id_marina
            )?.id_province
        )?.ProvinceEng,

        ProvinceThai: todos.CenterDataSlice.Province.results.find(
          (province) =>
            province.ProvinceID ===
            todos.CenterDataSlice.Marina.results.find(
              (marina) => marina.id_marina === yacht.id_marina
            )?.id_province
        )?.ProvinceThai,

        destinationIds: todos.YachtInfoDestinationPackageSlice.YachtInfoDestinationPackage.results.find(
          ((desID) => desID.id_destination_package === yacht.id_destination_package)
        )?.destination_included,

        DestinationEng: (() => {
          const destinationIds = todos.YachtInfoDestinationPackageSlice.YachtInfoDestinationPackage.results
            .find((desID) => desID.id_destination_package === yacht.id_destination_package)
            ?.destination_included;

          const destinationIdsArray = destinationIds ? destinationIds.split(",") : [];
          const destinationNames = destinationIdsArray.map((destinationId) => {
            const destination = todos.CenterDataSlice.Destination.results.find(
              (destination) => destination.id_destination === destinationId
            );
            return destination ? destination.name_destination_en : null;
          });

          return destinationNames.join(",");
        })(),

        DestinationThai: (() => {
          const destinationIds = todos.YachtInfoDestinationPackageSlice.YachtInfoDestinationPackage.results
            .find((desID) => desID.id_destination_package === yacht.id_destination_package)
            ?.destination_included;

          const destinationIdsArray = destinationIds ? destinationIds.split(",") : [];
          const destinationNames = destinationIdsArray.map((destinationId) => {
            const destination = todos.CenterDataSlice.Destination.results.find(
              (destination) => destination.id_destination === destinationId
            );
            return destination ? destination.name_destination_th : null;
          });

          return destinationNames.join(",");
        })(),

        travelAgent: todos.YachtSlice.Yacht.results.find(
          (travelAgent) => travelAgent.id_yacht === yacht.id_yacht
        )?.name_yacht,

        YachtInfoAmenities:
          todos.YachtInfoAmenitiesSlice.YachtInfoAmenities.results.filter(
            (yachtInfoAmenities) =>
              yachtInfoAmenities.id_yachtInfo === yacht.id_yachtInfo
          ),

        YachtInfoWaterToy:
          todos.YachtInfoWaterToySlice.YachtInfoWaterToy.results.filter(
            (yachtInfoWaterToy) =>
              yachtInfoWaterToy.id_yachtInfo === yacht.id_yachtInfo
          ),

      }));

      return selectedYachtWithAdditionalInfo.filter((yacht) => {
        if (selectedDestinations) {
          return (
            yacht.ProvinceEng === selectedProvince.ProvinceEng
          );
        }
        return true;
      });
    })();

  // Assuming selectedYachtWithMarinaAndProvince is the array of yacht objects with additional info

  const uniqueTravelAgents = new Set(); // Using a Set to ensure uniqueness

  selectedYachtWithMarinaAndProvince.forEach(yacht => {
    if (yacht.travelAgent) {
      uniqueTravelAgents.add(yacht.travelAgent);
    }
  });

  const numberOfTravelAgents = uniqueTravelAgents.size;
  const numberOfYachts = selectedYachtWithMarinaAndProvince.length;

  console.log("selected", selectedYachtWithMarinaAndProvince)

  return (
    <div>
      {selectedProvince && (
        <div>
          <Row className="d-flex align-items-start mb-4">
            <Col xs={12} md={5} className="image-provice order-3 order-md-1">
              <img
                style={{
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                }}
                // src={selectedProvinceDescription.province_description_img}
                src={selectedProvinceDescription.province_description_img}
                draggable={false}
                alt="selectedProvinceDescriptionImage"
              />
            </Col>
            <Col xs={8} md={5} className="province-des text-left order-1 order-md-2">
              <div>
                <div className="align-items-center">
                  <h4 className="mt-2 mb-0">{selectedLanguage === 'en' ? selectedProvince.ProvinceEng : selectedProvince.ProvinceThai}</h4>
                  <p className="mb-0 text-decoration-underline">{t("Description")}</p>
                  <Element
                    name="test7"
                    className="element"
                    id="containerElement"
                    style={{
                      position: "relative",
                      maxHeight: "10vh",
                      overflowY: "scroll",
                      overflowX: "hidden",
                    }}
                  >
                    <Element
                      name="firstInsideContainer"
                      style={{
                        marginBottom: "1vh",
                      }}
                    >
                      {selectedLanguage === "en" ? (
                        <p>{selectedProvinceDescription?.description_en}</p>
                      ) : (
                        <p>{selectedProvinceDescription?.description_th}</p>
                      )}
                    </Element>
                  </Element>
                </div>
              </div>
            </Col>
            <Col xs={12} md={2} className="order-2 order-md-3">
              <div>
                <ProvinceDetail IslandsLenght={selectedDestinations.length} AgentLenght={numberOfTravelAgents} YachtLenght={numberOfYachts} />
              </div>
            </Col>
          </Row>
          
          <div className="mb-4">
            <img className="BoatHelm-1" src={BoatHelm} draggable={false} alt="BoatHelm" />
            <h3>{t("Destination")}</h3>
            <Grid container spacing={0} sx={{ marginTop: '2vh', justifyContent: 'center' }}>
              {selectedDestinations.map((destination, index) => (
                <Col key={index} xs={5} md={6}>
                  <DestinationCard
                    name={
                      selectedLanguage === "en"
                        ? destination.name_destination_en
                        : destination.name_destination_th
                    }
                    imageSrc={destination.destination_img}
                    destinationEn={destination.name_destination_en}
                  />
                </Col>
              ))}
            </Grid>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProvinceCard;
