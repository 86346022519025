import React from "react";
import Footer from "../../../components/footerPage/Footer";
import AllHeader from "../../../components/Navbar/AllHeader";
import BodyDestination from "./components/BodyDestination";
import Header from "../homePages/components/Header"
function Destination() {


  return (
    <div>
      <AllHeader />
      <BodyDestination />
      <Footer />
    </div>
  );
}

export default Destination;
