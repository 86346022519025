import { useState, useEffect } from "react";
import i18n from "i18next";
import { Modal } from "react-bootstrap";
import enIcon from "../../Asset/image/home/EN_FLAG.png";
import thIcon from "../../Asset/image/home/TH_FLAG.png";
import Close_x from "../../Asset/icon/closeButton.svg";
import circleFrame from "../../Asset/icon/WhitecircleFrame.svg";
import { useTranslation } from "react-i18next";

function ModalLang({ show, onHide, onLangChange, updateLanguageIcon }) {

  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || "en"
  );

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    setPreviewText(
      currentLanguage === "en" ? "Click to switch language" : "กดเพื่อเปลี่ยนภาษา"
    );
  }, [currentLanguage]);

  const handleLanguageSelect = (language) => {
    i18n.changeLanguage(language);
    setCurrentLanguage(language);
    setSelectedLanguage(language);
    localStorage.setItem("selectedLanguage", language);
    onHide();
    //console.log(`Selected language: ${language}`);
  };

  const [previewText, setPreviewText] = useState(
    currentLanguage === "en" ? "Click to switch language" : "กดเพื่อเปลี่ยนภาษา"
  );

  const [isHoveringEN, setIsHoveringEN] = useState(false);
  const [isHoveringTH, setIsHoveringTH] = useState(false);

  const handleMouseEnterEN = () => {
    setIsHoveringEN(true);
  };

  const handleMouseLeaveEN = () => {
    setIsHoveringEN(false);
  };

  const handleMouseEnterTH = () => {
    setIsHoveringTH(true);
  };

  const handleMouseLeaveTH = () => {
    setIsHoveringTH(false);
  };

  const handleLanguagePreview = (language) => {
    setPreviewText(
      language === "en" ? "Click to switch language" : "กดเพื่อเปลี่ยนภาษา"
    );
  };

  return (
    <div>
      <Modal
        show={show}
        fullscreen
        onHide={onHide}
        style={{ paddingRight: "0" }}
      >
        <img
          src={Close_x}
          alt="CloseBtn"
          onClick={onHide}
          style={{
            width: "8vh",
            position: "absolute",
            top: "1rem",
            right: "2rem",
            display: "flex",
            alignItems: "center",
            padding: "0.5rem",
            cursor: 'pointer',
            zIndex: '999'
          }}
        />
        <Modal.Body
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <div className="fs-1" style={{ position: 'sticky', zIndex: 9999, marginBottom: '0' }}>{previewText}</div>
        </Modal.Body>
        <Modal.Body style={{ borderBottom: "none", display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
          <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '5vw' }}>
            <img
              draggable={false}
              src={enIcon}
              alt="enIcon"
              style={{ width: "15vh", zIndex: 9999, marginBottom: "10vw", cursor: "pointer", position: "relative" }}
              onClick={() => handleLanguageSelect("en")}
              onMouseEnter={() => {
                handleLanguagePreview("en");
                handleMouseEnterEN();
              }}
              onMouseLeave={handleMouseLeaveEN}
            />
            {(isHoveringEN &&
              <img
                draggable={false}
                src={circleFrame}
                alt="circleFrame"
                style={{
                  position: 'absolute', width: "17vh", top: '-1vh', left: '-1vh'
                }}
              />)}
          </div>
          <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '5vw' }}>
            <img
              src={thIcon}
              alt="thIcon"
              style={{ width: "15vh", zIndex: 9999, marginBottom: "10vw", cursor: "pointer", position: "relative" }}
              onClick={() => handleLanguageSelect("th")}
              draggable={false}
              onMouseEnter={() => {
                handleLanguagePreview("th");
                handleMouseEnterTH();
              }}
              onMouseLeave={handleMouseLeaveTH}
            />
            {(isHoveringTH &&
              <img
                draggable={false}
                src={circleFrame}
                alt="circleFrame"
                style={{
                  position: 'absolute', width: "17vh", top: '-1vh', left: '-1vh'
                }}
              />)}
          </div>

        </Modal.Body>

      </Modal>
    </div>
  );
}

export default ModalLang;
