import React, { useState } from "react";
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import { Link } from "react-router-dom";

const ProvincesCard = ({ name, imageSrc, country }) => {

  const [isHovered, setIsHovered] = useState(false);


  return (
    <Card
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        minHeight: '200px', width: 300, transition: 'transform 0.3s, border 0.3s',
        '&:hover': {
          transform: 'translateY(-10px)',
          boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)',
        },

        '@media (max-width:720px)': {
          minHeight: '100px', width: 150,
          fontSize: '10px'
        },
      }}>
      <CardCover>
        <img src={imageSrc} alt={name} />
      </CardCover>
      <CardCover
        sx={{
          background: isHovered
            ? 'none'
            : 'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 50px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
        }}
      />
      <CardContent sx={{ justifyContent: 'flex-end', textAlign: 'left' }}>
        <Typography level="title-lg" textColor="#fff" sx={{
          '@media (max-width:720px)': {
            fontSize: '12px'
          },
        }}>
          {name}
        </Typography>
        <Typography sx={{
          color: 'gray',
          '@media (max-width:720px)': {
            fontSize: '10px'
          },
        }} startDecorator={<LocationOnRoundedIcon sx={{
          color: 'gray',
          '@media (max-width:720px)': {
            fontSize: '10px'
          },
        }} />} textColor="neutral.300">
          {country}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ProvincesCard;
