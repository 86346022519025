import React, { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import BoatHelm from "../../Asset/icon/boat_helm.svg";
import CustomButtonGroupAsArrows from "../carousel/CustomButtonGroupAsArrows.js";
import InfoCard from "../card/InfoCard.js";
import { Link } from "react-router-dom";
import {
  getDestinationDetail,
  getProvinceDetail,
  getCountryDetail,
} from "../../redux/centerData/CenterDataSlice.js";
import { useTranslation } from "react-i18next";


function TopMarinaMultiRow() {

  const CustomDot = ({ onClick, index, active }) => (
    <button
      style={{
        background: active ? "#00305c" : "grey",
        width: "10px",
        height: "10px",
        margin: "5px 5px",
        border: "none",
        borderRadius: "50%",
        cursor: "pointer",
      }}
      onClick={() => onClick()}
    ></button>
  );

  const carouselRefs = [useRef(null), useRef(null), useRef(null)];
  const [loading, setLoading] = useState(true);
  const todos = useSelector((state) => state);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedLanguage = localStorage.getItem("selectedLanguage");

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getDestinationDetail({}));
        await dispatch(getProvinceDetail({}));
        await dispatch(getCountryDetail({}));
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  const country =
    todos.CenterDataSlice.Country.results?.find(
      (e) => e.country_name === "Thailand"
    );

  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 2 },
    desktop: { breakpoint: { max: 2999, min: 1024 }, items: 2 },
    tablet: { breakpoint: { max: 1023, min: 720 }, items: 2 },
    mobile: { breakpoint: { max: 719, min: 0 }, items: 2 },
  };

  const renderCarouselItems = (filterCondition) =>
    todos.CenterDataSlice.Destination.results
      ?.filter((_, index) =>
        window.innerWidth >= 719
          ? index % 1 === filterCondition
          : index % 1 === filterCondition
      )
      .map((Destination, i) => {
        const matchingProvinces = todos.CenterDataSlice.Province.results.filter(
          (province) => province.ProvinceID === Destination.id_province
        );
        return (
          <div key={i}>
            {matchingProvinces.map((province, j) => (
              <Link
                key={j}
                to={`/destination/detailIsland/${Destination.name_destination_en}`}
              >
                <InfoCard
                  image={Destination.destination_img}
                  headline={
                    selectedLanguage === 'en'
                      ? Destination.name_destination_en
                      : Destination.name_destination_th
                  }
                  location={
                    selectedLanguage === 'en'
                      ? province.ProvinceEng
                      : province.ProvinceThai
                  }
                  country={
                    selectedLanguage === 'en'
                      ? country?.country_name
                      : 'ประเทศไทย'
                  }
                />
              </Link>
            ))}
          </div>
        );
      });


  if (loading) {
    return <CircularProgress style={{ color: '#00305c' }} />;
  }

  return (
    <div>
      <div className="mb-4">
        <img className="BoatHelm-1" draggable={false} src={BoatHelm} alt="BoatHelm" />
        <h2>{t("topDestination")}</h2>
      </div>
      <div style={{ position: "relative", margin: "0rem", width: "100%" }}>
        <div className="carousel-container-a" style={{ width: "60%", margin: "0 auto" }}>
          {carouselRefs.map((ref, index) => (
            <div className="Marina-container" key={index}>
              <Carousel
                ref={ref}
                renderButtonGroupOutside
                draggable={false}
                responsive={responsive}
                ssr
                infinite
                additionalTransfrom={0}
                arrows={false}
                centerMode={false}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                showDots={window.innerWidth >= 719 ? false : true}
                customDot={<CustomDot />}
                slidesToSlide={2}
                swipeable
                autoPlay={true}
                autoPlaySpeed={4000}
                customButtonGroup={
                  window.innerWidth >= 719 ? (
                    index === 1 && (
                      <CustomButtonGroupAsArrows
                        carouselRefs={carouselRefs}
                        customPreviousStyle={{ left: "0" }}
                        customNextStyle={{ right: "0" }}
                      />
                    )
                  ) : null
                }
              >
                {renderCarouselItems(index)}
              </Carousel>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TopMarinaMultiRow;
