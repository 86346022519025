import React, { useState, useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import BoatHelm from "../../../../../../Asset/icon/boat_helm.svg";
import TopDestinationMultiRow from "../../../../../../components/multiRow/TopDestinationMultiRow";
import AllHeader from "../../../../../../components/Navbar/AllHeader";
import IslandCard from "../../../../../../components/card/IslandCard";
import Filter from "../../Filter";
import YachtCard from "../../../../../../components/card/YachtCard";
import Footer from "../../../../../../components/footerPage/Footer";
import Image from "../../../../../../Asset/image/yacht/fruitcake-thumb.jpg";
import { useParams } from "react-router-dom";
import { ProvincesData } from "../../../../../../components/data/ProvincesData";
import { Link } from "react-router-dom";
import ImageUnderCard from "../../../../../../components/card/ImageUnderCard";
import BestSeller from "../../../../homePages/components/BestSeller";
import { useSelector, useDispatch } from "react-redux";
import { getYachtInformationDetail } from "../../../../../../redux/yachtInformation/YachtInformationSlice";
import { getYachtInfoSellingPriceRatePlanListDetail } from "../../../../../../redux/YachtInfoSellingPriceRatePlanList/YachtInfoSellingPriceRatePlanListSlice";
import { getYachtInfoDestinationPackageDetail } from "../../../../../../redux/yachtInfoDestinationPackage/YachtInfoDestinationPackageSlice";
import { getYachtDetail } from "../../../../../../redux/yacht/YachtSlice";
import { getYachtInfoAmenitiesDetail } from "../../../../../../redux/YachtInfoAmenities/YachtInfoAmenitiesSlice";
import { getYachtInfoWaterToyDetail } from "../../../../../../redux/YachtInfoWaterToy/YachtInfoWaterToySlice";
import {
  getCountryDetail,
  getProvinceDetail,
  getMarinaDetail,
  getDestinationDetail,
} from "../../../../../../redux/centerData/CenterDataSlice";

import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from "react-i18next";
import FilterYacht from "../../../../yacht/components/FilterYacht";
import "../../../../../../Asset/scss/destination/ProvinceCard.scss"

function DetailIsland() {
  const { t } = useTranslation();
  const selectedLanguage = localStorage.getItem("selectedLanguage");

  const yachtCardContainer = {
    boxShadow: "0px 0px 5px 2px rgba(0, 0, 0, 0.2)",
  };
  const { slug } = useParams();
  const todos = useSelector((state) => state);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true); // Initial loading state
  useEffect(() => {
    // Fetch data from the API

    Promise.all([
      dispatch(getDestinationDetail({})),
      dispatch(getProvinceDetail({})),
      dispatch(getMarinaDetail({})),
      dispatch(getCountryDetail({})),
      dispatch(getYachtInformationDetail({})),
      dispatch(getYachtInfoSellingPriceRatePlanListDetail({})),
      dispatch(getYachtInfoDestinationPackageDetail({})),
      dispatch(getYachtDetail({})),
      dispatch(getYachtInfoAmenitiesDetail({})),
      dispatch(getYachtInfoWaterToyDetail({})),
    ])
      .then(() => {
        // Data loading is complete, set loading state to false
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error if needed
      });
  }, [dispatch]);
  // Find the selected province
  const selectedDestination = loading
    ? null
    : todos.CenterDataSlice.Destination.results.find(
      (Destination) => Destination.name_destination_en === slug
    );

  const selectedProvinceDestination = loading
    ? null
    : todos.CenterDataSlice.Province.results.find(
      (province) => province.ProvinceID === selectedDestination?.id_province
    );
  // Now, check if selectedMarina and selectedProvince are defined before accessing their properties
  const country =
    todos.CenterDataSlice.Country.results &&
    todos.CenterDataSlice.Country.results.find(
      (e) => e.country_name === "Thailand"
    );

  // Find the selected yacht match id_marina with id_destination

  // const selectedYacht = loading
  //   ? null
  //   : todos.YachtInfoSellingPriceRatePlanListSlice.YachtInfoSellingPriceRatePlanList.results.filter(
  //     (YachtInfoSellingPriceRatePlanList) =>
  //       YachtInfoSellingPriceRatePlanList.id_marina ===
  //       selectedDestination?.id_destination
  //   );

  const selectedYacht = loading
    ? null
    : todos.YachtInfoSellingPriceRatePlanListSlice.YachtInfoSellingPriceRatePlanList.results.filter(
      (YachtInfoSellingPriceRatePlanList) =>
        YachtInfoSellingPriceRatePlanList.rate_plan_use_in_TMY_web_site === 1
    );

  // const selectedYachtWithMarinaAndProvince = loading
  //   ? null
  //   : selectedYacht.map((yachtInfo) => {

  //     const marina = todos.CenterDataSlice.Marina.results.find(
  //       (marina) => marina.id_marina === yachtInfo.id_marina
  //     );

  //     const province = todos.CenterDataSlice.Province.results.find(
  //       (province) => province.ProvinceID === marina?.id_province
  //     );

  //     const travelAgent =
  //       yachtInfo.id_yacht &&
  //       todos.YachtSlice.Yacht.results.find(
  //         (yacht) => yacht.id_yacht === yachtInfo.id_yacht
  //       );

  //     const YachtInfoAmenities =
  //       todos.YachtInfoAmenitiesSlice.YachtInfoAmenities.results.filter(
  //         (yachtInfoAmenities) =>
  //           yachtInfoAmenities.id_yachtInfo === yachtInfo.id_yachtInfo
  //       );
  //     const YachtInfoWaterToy =
  //       todos.YachtInfoWaterToySlice.YachtInfoWaterToy.results.filter(
  //         (yachtInfoWaterToy) =>
  //           yachtInfoWaterToy.id_yachtInfo === yachtInfo.id_yachtInfo
  //       );
  //     return {
  //       ...yachtInfo,
  //       name_marina_en: marina ? marina.name_marina_en : null,
  //       name_marina_th: marina ? marina.name_marina_th : null,
  //       ProvinceEng: province ? province.ProvinceEng : null,
  //       ProvinceThai: province ? province.ProvinceThai : null,
  //       ProvinceID: province ? province.ProvinceID : null,
  //       travelAgent: travelAgent ? travelAgent.name_yacht : null,
  //       YachtInfoAmenities: YachtInfoAmenities,
  //       YachtInfoWaterToy: YachtInfoWaterToy,
  //     };
  //   })

  const selectedYachtWithMarinaAndProvince = loading
    ? []
    : (() => {
      const selectedYachtWithAdditionalInfo = selectedYacht.map((yacht) => ({
        ...yacht,
        name_marina_en: todos.CenterDataSlice.Marina.results.find(
          (marina) => marina.id_marina === yacht.id_marina
        )?.name_marina_en,

        name_marina_th: todos.CenterDataSlice.Marina.results.find(
          (marina) => marina.id_marina === yacht.id_marina
        )?.name_marina_th,

        ProvinceEng: todos.CenterDataSlice.Province.results.find(
          (province) =>
            province.ProvinceID ===
            todos.CenterDataSlice.Marina.results.find(
              (marina) => marina.id_marina === yacht.id_marina
            )?.id_province
        )?.ProvinceEng,

        ProvinceThai: todos.CenterDataSlice.Province.results.find(
          (province) =>
            province.ProvinceID ===
            todos.CenterDataSlice.Marina.results.find(
              (marina) => marina.id_marina === yacht.id_marina
            )?.id_province
        )?.ProvinceThai,

        // DestinationEng: todos.YachtInfoDestinationPackageSlice.YachtInfoDestinationPackage.results
        //   .find((destination) => destination.id_yacht === yacht.id_yacht)
        //   ?.destination_included,

        destinationIds: todos.YachtInfoDestinationPackageSlice.YachtInfoDestinationPackage.results.find(
          ((desID) => desID.id_destination_package === yacht.id_destination_package)
        )?.destination_included,

        DestinationEng: (() => {
          const destinationIds = todos.YachtInfoDestinationPackageSlice.YachtInfoDestinationPackage.results
            .find((desID) => desID.id_destination_package === yacht.id_destination_package)
            ?.destination_included;

          const destinationIdsArray = destinationIds ? destinationIds.split(",") : [];
          const destinationNames = destinationIdsArray.map((destinationId) => {
            const destination = todos.CenterDataSlice.Destination.results.find(
              (destination) => destination.id_destination === destinationId
            );
            return destination ? destination.name_destination_en : null;
          });

          return destinationNames.join(",");
        })(),


        DestinationThai: (() => {
          const destinationIds = todos.YachtInfoDestinationPackageSlice.YachtInfoDestinationPackage.results
            .find((desID) => desID.id_destination_package === yacht.id_destination_package)
            ?.destination_included;

          const destinationIdsArray = destinationIds ? destinationIds.split(",") : [];
          const destinationNames = destinationIdsArray.map((destinationId) => {
            const destination = todos.CenterDataSlice.Destination.results.find(
              (destination) => destination.id_destination === destinationId
            );
            return destination ? destination.name_destination_th : null;
          });

          return destinationNames.join(",");
        })(),

        travelAgent: todos.YachtSlice.Yacht.results.find(
          (travelAgent) => travelAgent.id_yacht === yacht.id_yacht
        )?.name_yacht,

        YachtInfoAmenities:
          todos.YachtInfoAmenitiesSlice.YachtInfoAmenities.results.filter(
            (yachtInfoAmenities) =>
              yachtInfoAmenities.id_yachtInfo === yacht.id_yachtInfo
          ),

        YachtInfoWaterToy:
          todos.YachtInfoWaterToySlice.YachtInfoWaterToy.results.filter(
            (yachtInfoWaterToy) =>
              yachtInfoWaterToy.id_yachtInfo === yacht.id_yachtInfo
          ),

      }));



      return selectedYachtWithAdditionalInfo.filter(
        (yacht) =>
          yacht.DestinationEng && yacht.DestinationEng.includes(slug)
      );
    })();

  //console.log("select", selectedYachtWithMarinaAndProvince);
  const uniqueTravelAgents = new Set(); // Using a Set to ensure uniqueness

  selectedYachtWithMarinaAndProvince.forEach(yacht => {
    if (yacht.travelAgent) {
      uniqueTravelAgents.add(yacht.travelAgent);
    }
  });

  const numberOfTravelAgents = uniqueTravelAgents.size;
  const numberOfYachts = selectedYachtWithMarinaAndProvince.length;

  
  return (
    <div>
      <AllHeader />
      <div className="container" style={{ marginTop: "5vh" }}>
        <div className="row align-items-stretch">


          <div className="col-lg-1 order-md-2 d-none d-lg-block"></div>
          <div className="col-lg-5 text-center order-md-2 col-md-6 mb-4">
            <div className="mb-4">
              <img className="BoatHelm-1" draggable={false} src={BoatHelm} alt="BoatHelm" />
              <h2>{t("Destination")}</h2>
            </div>


            {!loading ? (
              <div className="ProvinceCard">
                {selectedDestination && selectedProvinceDestination && (
                  <div>
                    <IslandCard
                      Image={selectedDestination.destination_img} // Adjust the property name as per your actual data structure
                      name={
                        selectedLanguage === "en"
                          ? selectedDestination.name_destination_en
                          : selectedDestination.name_destination_th
                      }
                      provinceName={
                        selectedLanguage === "en"
                          ? selectedProvinceDestination.ProvinceEng
                          : selectedProvinceDestination.ProvinceThai
                      }
                      provinceCountry={country?.country_name}
                      description={
                        selectedLanguage === "en"
                          ? selectedDestination.description_en
                          : selectedDestination.description_th
                      }
                      agentLenght={numberOfTravelAgents}
                      yachtLenght={numberOfYachts}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div>
                <CircularProgress style={{ color: "#00305c" }} />
              </div>
            )}


            <div className="mb-4">
              <img className="BoatHelm-1" draggable={false} src={BoatHelm} alt="BoatHelm" />
              <h2>{t("Yacht lists")}</h2>
            </div>

            {!loading ? (
              <div>
                {selectedYachtWithMarinaAndProvince &&
                  selectedYachtWithMarinaAndProvince.length > 0 ? (
                  selectedYachtWithMarinaAndProvince.map((yachtInfo, index) => {
                    //console.log("id_yacht", yachtInfo.id_yachtInfo)
                    return (
                      <Link key={index} to={`/yacht/detailYacht/${yachtInfo.id_yachtInfo}`}>
                        <YachtCard
                          headline={yachtInfo.name_yacht_yachtInfo}
                          image={yachtInfo.logo_yachtInfo_url}
                          marina={selectedLanguage === 'en' ?
                            yachtInfo.name_marina_en : yachtInfo.name_marina_th}
                          province={selectedLanguage === 'en' ?
                            yachtInfo.ProvinceEng : yachtInfo.ProvinceThai}
                          travelAgent={yachtInfo.travelAgent}
                          dayOption={yachtInfo.rate_type}
                          amenities={yachtInfo.YachtInfoAmenities}
                          waterToy={yachtInfo.YachtInfoWaterToy}
                          price={yachtInfo.value_sellingPrice}
                          style={yachtCardContainer}
                          cruising_speed={yachtInfo.cruising_speed}
                          guests_day={yachtInfo.guests_day}
                          guests_sleeping={yachtInfo.guests_sleeping}
                        />
                      </Link>
                    );
                  })
                ) : (
                  <div>{t("No yachts available")}</div>
                )}
              </div>
            ) : (
              <div>
                <CircularProgress style={{ color: '#00305c' }} />
              </div>
            )}


          </div>

          <div className="col-lg-1 order-md-1 d-none d-lg-block">{/* Content for the left column */}</div>
          <div className="col-lg-4 text-center order-md-1 col-md-6 mb-4">
            <TopDestinationMultiRow />
          </div>

          <div className="col-lg-1 order-md-3 d-none d-lg-block">{/* Content for the right column */}</div>
          <div className="col-lg-12 text-center order-md-3 col-md-12 mb-4">
            <BestSeller />
          </div>
        </div>
      </div >
      <Footer />
    </div>
  );
}

export default DetailIsland;
