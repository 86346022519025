import React from "react";
import "react-multi-carousel/lib/styles.css";
import TopDestinationMultiRow from "../../../../components/multiRow/TopDestinationMultiRow";
import DestinationProvinces from "./detaildestination/DestinationProvinces";
import BestSeller from "../../homePages/components/BestSeller";
import BodyTopDestinations from "../../homePages/components/BodyTopDestinations";


function BodyDestination() {
  return (
    <div className="container" style={{ marginTop: "5vh" }}>
      <div className="row align-items-stretch">
        <div className="col-lg-1 order-md-2 d-none d-lg-block">{/* Spacer column visible on larger screens */}</div>
        {/* Order of columns changes for mobile screens */}
        <div className="col-lg-5 text-center order-md-2 col-md-6 mb-4">
          <DestinationProvinces />
        </div>
        <div className="col-lg-1 order-md-1 d-none d-lg-block">{/* Spacer column visible on larger screens */}</div>

        <div className="col-lg-4 text-center order-md-1 col-md-6 mb-4">
          <TopDestinationMultiRow />
        </div>
        {/* End of column order change */}
        <div className="col-lg-1 order-md-3 d-none d-lg-block">{/* Spacer column visible on larger screens */}</div>
        <div className="col-lg-12 text-center order-md-3 col-md-12 mb-4">
          <BestSeller />
        </div>
      </div>
    </div>
  );
}

export default BodyDestination;
