import { createSlice } from "@reduxjs/toolkit";
import { getBooking, createBooking, login, getBookingHistory, cancelBooking } from "../../API/BookingAPI";

const initialState = {
  Booking: [],
  test: [],
  login: [],
  BookingHistroy: [],

};

export const BookingSlice = createSlice({
  name: "BookingState",
  initialState,
  reducers: {
    updateGetBooking: (state, action) => {
      state.Booking = action.payload;
    },
    updateTest: (state, action) => {
      state.test = action.payload;
    },
    updateLogin: (state, action) => {
      state.login = action.payload;
    },
    updateGetBookingHistory: (state, action) => {
      state.BookingHistroy = action.payload;
    },
    updateCancelBooking: (state, action) => {
      state.cancelBookingResult = action.payload;
    },
  },
});

export const { updateGetBooking } = BookingSlice.actions;

export const getBookingDetail = (value) => async (dispatch) => {
  await getBooking(value).then(async (data) => {
    await dispatch(updateGetBooking(data));
    //   await dispatch(stopLoading());
  });
};

export const { updateGetBookingHistory } = BookingSlice.actions;

export const getBookingHistoryDetail = (value) => async (dispatch) => {
  await getBookingHistory(value).then(async (data) => {
    await dispatch(updateGetBookingHistory(data));
    //   await dispatch(stopLoading());
  });
};


export const { updateTest } = BookingSlice.actions;

export const createNewBooking = (bookingData) => async (dispatch) => {
  try {
    const data = await createBooking(bookingData);
    dispatch(updateTest(data));
    return data;
  } catch (error) {

  }
};


export const { updateLogin } = BookingSlice.actions;

export const LoginNew = (LoginData) => async (dispatch) => {
  try {
    const data = await login(LoginData);
    dispatch(updateLogin(data));
    return data;
  } catch (error) {

  }
};

export const { updateCancelBooking } = BookingSlice.actions;

export const cancelBookingAction = (bookingData) => async (dispatch) => {
  try {
    const data = await cancelBooking(bookingData);
    dispatch(updateCancelBooking(data)); // Dispatch action to update state with result
    return data;
  } catch (error) {
    // Handle error
  }
};

export default BookingSlice.reducer;
