// Reviews.js
import React from 'react';
import BoatHelm from "../../../../Asset/icon/boat_helm.svg";
import { useTranslation } from "react-i18next";

const Reviews = () => {
  const { t } = useTranslation();

  return (
    <div>
      <img className="BoatHelm-1" draggable={false} style={{ marginTop: "5vh" }} src={BoatHelm} alt="BoatHelm" />
      <h3>{t("Review(s)")}</h3>
    </div>
  );
};

export default Reviews;
