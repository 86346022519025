import React from 'react';
import { useTranslation } from "react-i18next";
import "../../Asset/scss/destination/svgDetail.scss"

const YachtType = ({ value_yachtTypes, refit_year, crew, max_speed, year }) => {
    const { t } = useTranslation();
    const selectedLanguage = localStorage.getItem("selectedLanguage");

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="334" height="99" viewBox="0 0 334 99"
        >
            <g id="Group_3416" data-name="Group 3416" transform="translate(-1231 -699)">
                <g id="Rectangle_403" data-name="Rectangle 403" transform="translate(1356 750)" fill="#fff" stroke="#151b47" stroke-width="1">
                    <rect width="85" height="48" stroke="none" />
                    <rect x="0.5" y="0.5" width="84" height="47" fill="none" />
                </g>
                <g id="Rectangle_409" data-name="Rectangle 409" transform="translate(1441 750)" fill="#fff" stroke="#151b47" stroke-width="1">
                    <path d="M0,0H124a0,0,0,0,1,0,0V37a11,11,0,0,1-11,11H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" stroke="none" />
                    <path d="M.5.5h123a0,0,0,0,1,0,0V37A10.5,10.5,0,0,1,113,47.5H.5a0,0,0,0,1,0,0V.5A0,0,0,0,1,.5.5Z" fill="none" />
                </g>
                <g id="Rectangle_411" data-name="Rectangle 411" transform="translate(1231 750)" fill="#fff" stroke="#151b47" stroke-width="1">
                    <path d="M0,0H125a0,0,0,0,1,0,0V48a0,0,0,0,1,0,0H12A12,12,0,0,1,0,36V0A0,0,0,0,1,0,0Z" stroke="none" />
                    <path d="M.5.5h124a0,0,0,0,1,0,0v47a0,0,0,0,1,0,0H12A11.5,11.5,0,0,1,.5,36V.5A0,0,0,0,1,.5.5Z" fill="none" />
                </g>
                <g id="Rectangle_405" data-name="Rectangle 405" transform="translate(1231 702)" fill="#fff" stroke="#151b47" stroke-width="1">
                    <path d="M9,0H325a9,9,0,0,1,9,9V48a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V9A9,9,0,0,1,9,0Z" stroke="none" />
                    <path d="M9,.5H325A8.5,8.5,0,0,1,333.5,9V47.5a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0V9A8.5,8.5,0,0,1,9,.5Z" fill="none" />
                </g>
                <g id="Rectangle_410" data-name="Rectangle 410" transform="translate(1441 750)" fill="#151b47" stroke="#151b47" stroke-width="1">
                    <rect width="124" height="15" stroke="none" />
                    <rect x="0.5" y="0.5" width="123" height="14" fill="none" />
                </g>
                <g id="Rectangle_412" data-name="Rectangle 412" transform="translate(1231 750)" fill="#151b47" stroke="#151b47" stroke-width="1">
                    <rect width="125" height="15" stroke="none" />
                    <rect x="0.5" y="0.5" width="124" height="14" fill="none" />
                </g>
                <g id="Rectangle_407" data-name="Rectangle 407" transform="translate(1231 702)" fill="#151b47" stroke="#151b47" stroke-width="1">
                    <path d="M9,0H325a9,9,0,0,1,9,9v6a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V9A9,9,0,0,1,9,0Z" stroke="none" />
                    <path d="M9,.5H325A8.5,8.5,0,0,1,333.5,9v5.5a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0V9A8.5,8.5,0,0,1,9,.5Z" fill="none" />
                </g>
                <text id="Max_Speed" data-name="Max Speed" transform="translate(1474 761)" fill="#fff" font-size="12" font-family="SegoeUI, Segoe UI">
                    <tspan x="0" y="0">{t("Max Speed")}</tspan>
                    <tspan x="30" y="20" fill="#151b47" textAnchor="middle" fontSize="16" fontWeight="bold">{max_speed}</tspan>
                    <tspan x="30" y="30" fill="#999999" textAnchor="middle" fontSize="12" fontWeight="bold">knots</tspan>
                </text>
                <text id="Year_Refit_Year" data-name="Year / Refit Year" transform="translate(1252 761)" fill="#fff" font-size="12" font-family="SegoeUI, Segoe UI">
                    <tspan x="0" y="0">Year / Refit Year</tspan>
                    <tspan x="40" y="25" fill="#151b47" textAnchor="middle" fontSize="16" fontWeight="bold">{year}/{refit_year}</tspan></text>
                <text id="Yacht_Type" data-name="Yacht Type" transform="translate(1370 712)" fill="#fff" font-size="12" font-family="SegoeUI, Segoe UI">
                    <tspan x="0" y="0">Yacht Type</tspan>
                    <tspan x="30" y="25" fill="#151b47" textAnchor="middle" fontSize="16" fontWeight="bold">{value_yachtTypes}</tspan></text>
                <g id="Rectangle_408" data-name="Rectangle 408" transform="translate(1356 750)" fill="#151b47" stroke="#151b47" stroke-width="1">
                    <rect width="85" height="15" stroke="none" />
                    <rect x="0.5" y="0.5" width="84" height="14" fill="none" />
                </g>
                <text id="Crew" transform="translate(1385 761)" fill="#fff" font-size="12" font-family="SegoeUI, Segoe UI">
                    <tspan x="0" y="0">Crew</tspan>
                    <tspan x="15" y="20" fill="#151b47" textAnchor="middle" fontSize="16" fontWeight="bold">{crew}</tspan>
                    <tspan x="15" y="30" fill="#999999" textAnchor="middle" fontSize="12" fontWeight="bold">pax</tspan>
                </text>
            </g>
        </svg>
    );
};

export default YachtType;
