/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import LogoSail from "../../../src/Asset/image/home/logo_Sail.png";
import MenuSail from "../../../src/Asset/icon/menuSail.svg";
import enIcon from "../../../src/Asset/image/home/EN_FLAG.png";
import thIcon from "../../../src/Asset/image/home/TH_FLAG.png";
import ProfileUnLogin from "../../../src/Asset/icon/profileUnLogin.svg";
import ModalLang from "../../../src/components/pagesModal/ModalLang"
import ModalPage from "../../../src/components/pagesModal/ModalPage"
import "react-datepicker/dist/react-datepicker.css";
import BgHome from "../../../src/Asset/image/home/back_top.jpg";

import "./Allheader.scss"

import { Link } from "react-router-dom";
import SearchFormHeader from "../../views/app/homePages/components/SearchFormHeader"

import LoginModal from '../../../src/views/user/login'
import SignUpModal from '../../../src/views/user/register'
import ProfileMenu from '../../views/app/homePages/components/ProfileMenu';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles'; import Card from "@mui/material/Card";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#00305c',
    color: '#ffff',
    boxShadow: theme.shadows[10],
    fontSize: 14,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#00305c',
  },
}));

function AllHeader() {
  // Login modal
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const handleOpenLoginModal = () => setLoginModalOpen(true);
  const handleCloseLoginModal = () => setLoginModalOpen(false);
  // Register modal
  const [isRegisterOpen, setIsRegisterOpen] = useState(false);
  const handleOpenRegister = () => setIsRegisterOpen(true);
  const handleCloseRegister = () => setIsRegisterOpen(false);

  const switchToSignUp = () => setIsRegisterOpen(true);

  // Language change
  const { t } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem("selectedLanguage") || i18n.language);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  const handleChangeLanguage = (newLanguage) => {
    i18n.changeLanguage(newLanguage);
    setCurrentLanguage(newLanguage);
    localStorage.setItem("selectedLanguage", newLanguage);
    setLangVisible(false);
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem("selectedLanguage");
    if (storedLanguage) {
      setCurrentLanguage(storedLanguage);
    }
  }, []);

  const [isLangVisible, setLangVisible] = useState(false);
  const handleLangToggle = () => setLangVisible(!isLangVisible);

  // Modal for MenuSail
  const [isModalVisible, setModalVisible] = useState(false);
  const handleModalToggle = () => setModalVisible(!isModalVisible);

  return (
    <div >
      <div className="container-a">
        <nav className="navbarhome-a" id="navbar">
          <ul className="nav-left-a">
            <li>
              <Link to="/">
                <LightTooltip title={t('Home')} arrow>
                  <img
                    draggable={false}
                    className="logoSail-image-a"
                    src={LogoSail}
                    alt="Lang"
                    variant="primary"
                    onClick={handleLangToggle}
                  />
                </LightTooltip>
              </Link>
            </li>
            <li>
              <LightTooltip title={t('Change Language')} arrow>
                <img
                  className="lang-svg-a"
                  draggable={false}
                  src={currentLanguage === "en" ? enIcon : thIcon}
                  alt="Lang"
                  onClick={handleLangToggle}
                />
              </LightTooltip>
              <ModalLang show={isLangVisible} onHide={handleLangToggle} onLangChange={handleChangeLanguage} />
            </li>
          </ul>
          <ul className="nav-right-a">
            <li>
              <LightTooltip title={t('Open Menu')} arrow>
                <img
                  className="menuSail-svg-a"
                  draggable={false}
                  src={MenuSail}
                  alt="MenuSail"
                  onClick={handleModalToggle}
                />
              </LightTooltip>
            </li>
            <li className="menuSail-svg-a">
              <ProfileMenu
                isLoginModalOpen={isLoginModalOpen}
                handleOpenLoginModal={handleOpenLoginModal}
                handleCloseLoginModal={handleCloseLoginModal}
                switchToSignUp={switchToSignUp}
              />
            </li>
          </ul>
          <SearchFormHeader />
        </nav>
        <SignUpModal
          open={isRegisterOpen}
          onClose={handleCloseRegister}
        />

        <ModalPage show={isModalVisible} onHide={handleModalToggle} />

        {/* Overlay */}
        <div className="overlay-a"></div>

        {/* Background Image */}
        <img
          className="background-image-a"
          draggable={false}
          //style={{height: '250px'}}
          src={BgHome}
          alt="Header-Background"
        />
      </div>
    </div>


  );
}

export default AllHeader;
