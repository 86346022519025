import axios from "axios";

// const BASE_URL =
//   "http://127.0.0.1:5001/api-tmy-web-2024/asia-southeast2/take_my_yacht";

const BASE_URL =
  "https://asia-southeast2-api-tmy-web-2024.cloudfunctions.net/take_my_yacht";


const instance = axios.create({
  baseURL: BASE_URL,
});

export const api_provider = (props) => {
  let { type, url, headers, data } = props;
  const headers_req = {
    "x-has-accesstoken": headers,
  };
    console.log("headers_req",headers_req);
  console.log("headers",headers);
  return new Promise(async function (resolve, reject) {
    try {
      let resData;
      switch (type) {
        case "GET":
          resData = await instance.get(url, {
            params: data,
            headers: headers_req,
          });
          break;
        case "POST":
          resData = await instance.post(url, data, {
            headers: headers_req,
          });
          break;
        case "PUT":
          resData = await instance.put(url, data, {
            headers: headers_req,
          });
          break;
        case "DELETE":
          resData = await instance.delete(url, {
            params: data,
            headers: headers_req,
          });
          break;
        default:
          break;
      }
      resolve(resData.data);
    } catch (e) {
      console.log(e);
      // window.location.href = "/error";
    }
  });
};

export default api_provider;
