import React, { useState, useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import TopDestinationMultiRow from "../../../../components/multiRow/TopDestinationMultiRow";
import YachtCard from "../../../../components/card/YachtCard";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';

import ImageUnderCard from "../../../../components/card/ImageUnderCard";
import BoatHelm from "../../../../Asset/icon/boat_helm.svg";
import detailsAssetsYachtType from "../../../../Asset/icon/detailsAssetsYachtType.svg";
import TripDetailCard from "../../../../components/card/TripDetailCard";
import PackagesCard from "../../../../components/card/PackagesCard";
import BookingForm from "./BookingForm";
import BestSeller from "../../homePages/components/BestSeller";
import { getYachtInfoSellingPriceRatePlanListDetail } from "../../../../redux/YachtInfoSellingPriceRatePlanList/YachtInfoSellingPriceRatePlanListSlice";
import { getYachtInfoAmenitiesDetail } from "../../../../redux/YachtInfoAmenities/YachtInfoAmenitiesSlice";
import { getYachtInfoWaterToyDetail } from "../../../../redux/YachtInfoWaterToy/YachtInfoWaterToySlice";
import { getYachtInfoGalleryDetail } from "../../../../redux/yachtInfoGallery/YachtInfoGallerySlice";
import { getYachtInfoDestinationPackageDetail } from "../../../../redux/yachtInfoDestinationPackage/YachtInfoDestinationPackageSlice";
import { getYachtDetail } from "../../../../redux/yacht/YachtSlice";
import {
  getMarinaDetail,
  getDestinationDetail,
  getProvinceDetail,
} from "../../../../redux/centerData/CenterDataSlice";
import { useTranslation } from "react-i18next";


function BodyBooking() {
  const { t } = useTranslation();
  const selectedLanguage = localStorage.getItem("selectedLanguage");
  const { slug } = useParams();
  const todos = useSelector((state) => state);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true); // Initial loading state
  useEffect(() => {
    // Fetch data from the API

    Promise.all([
      dispatch(getDestinationDetail({})),
      dispatch(getYachtInfoSellingPriceRatePlanListDetail({})),
      dispatch(getMarinaDetail({})),
      dispatch(getProvinceDetail({})),
      dispatch(getYachtDetail({})),
      dispatch(getYachtInfoAmenitiesDetail({})),
      dispatch(getYachtInfoWaterToyDetail({})),
      dispatch(getYachtInfoGalleryDetail({})),
      dispatch(getYachtInfoDestinationPackageDetail({})),
    ])
      .then(() => {
        // Data loading is complete, set loading state to false
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error if needed
      });
  }, [dispatch]);
  
  const selectedYacht = loading
    ? null
    : todos.YachtInfoSellingPriceRatePlanListSlice.YachtInfoSellingPriceRatePlanList.results.find(
      (YachtInfoSellingPriceRatePlanList) =>
        YachtInfoSellingPriceRatePlanList.id_sellingPrice === slug
    );

const selectedYachtWithMarinaAndProvince = loading
    ? null
    : (() => {
      const marina = todos.CenterDataSlice.Marina.results.find(
        (marina) => marina.id_marina === selectedYacht.id_marina
      );

      const province = todos.CenterDataSlice.Province.results.find(
        (province) => province.ProvinceID === marina?.id_province
      );

      const travelAgent =
        selectedYacht.id_yacht &&
        todos.YachtSlice.Yacht.results.find(
          (yacht) => yacht.id_yacht === selectedYacht.id_yacht
        );

      const YachtInfoAmenities =
        todos.YachtInfoAmenitiesSlice.YachtInfoAmenities.results.filter(
          (yachtInfoAmenities) =>
            yachtInfoAmenities.id_yachtInfo === selectedYacht.id_yachtInfo
        );
      const YachtInfoWaterToy =
        todos.YachtInfoWaterToySlice.YachtInfoWaterToy.results.filter(
          (yachtInfoWaterToy) =>
            yachtInfoWaterToy.id_yachtInfo === selectedYacht.id_yachtInfo
        );
      const YachtInfoGallery =
        todos.YachtInfoGallerySlice.YachtInfoGallery.results.filter(
          (yachtInfoGallery) =>
            yachtInfoGallery.id_yachtInfo === selectedYacht.id_yachtInfo
        );
      const YachtInfoDestinationPackage =
        todos.YachtInfoDestinationPackageSlice.YachtInfoDestinationPackage.results.find(
          (yachtInfoDestinationPackage) =>
            yachtInfoDestinationPackage.id_destination_package ===
            selectedYacht.id_destination_package
        );
        
      const destinationIds = todos.YachtInfoDestinationPackageSlice.YachtInfoDestinationPackage.results
        .find((desID) => desID.id_destination_package === selectedYacht.id_destination_package)
        ?.destination_included;

      const destinationIdsArray = destinationIds ? destinationIds.split(",") : [];
      const DestinationEng = destinationIdsArray.map((destinationId) => {
        const destination = todos.CenterDataSlice.Destination.results.find(
          (destination) => destination.id_destination === destinationId
        );
        return destination ? destination.name_destination_en : null;
      }).join(",");

      const DestinationThai = destinationIdsArray.map((destinationId) => {
        const destination = todos.CenterDataSlice.Destination.results.find(
          (destination) => destination.id_destination === destinationId
        );
        return destination ? destination.name_destination_th : null;
      }).join(",");

      return {
        ...selectedYacht,
        name_marina_en: marina ? marina.name_marina_en : null,
        name_marina_th: marina ? marina.name_marina_th : null,
        ProvinceEng: province ? province.ProvinceEng : null,
        ProvinceThai: province ? province.ProvinceThai : null,
        ProvinceID: province ? province.ProvinceID : null,
        travelAgent: travelAgent ? travelAgent.name_yacht : null,
        YachtInfoAmenities: YachtInfoAmenities,
        YachtInfoWaterToy: YachtInfoWaterToy,
        YachtInfoGallery: YachtInfoGallery,
        YachtInfoDestinationPackage: YachtInfoDestinationPackage,
        destinationIds: destinationIds,
        DestinationEng: DestinationEng,
        DestinationThai: DestinationThai
      };
    })();

  //console.log(selectedYachtWithMarinaAndProvince);

  return (
    <div className="container" style={{ marginTop: "5vh" }}>
      <div className="row align-items-stretch">

        <div className="col-lg-1 d-none d-lg-block"></div>

        <div className="col-lg-5 text-center order-md-2 col-md-6 mb-4 p-0 mx-auto">
          <div className="mb-4">
            <img className="BoatHelm-1" draggable={false} src={BoatHelm} alt="BoatHelm" />
            <h2>{t("Yacht lists")}</h2>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-10 col-12 text-center order-md-3 mb-4 p-0 mx-auto">
            {!loading ? (
              <div>
                {selectedYachtWithMarinaAndProvince ? (
                  <div>
                    <YachtCard
                      headline={
                        selectedYachtWithMarinaAndProvince.name_yacht_yachtInfo
                      }
                      image={
                        selectedYachtWithMarinaAndProvince.logo_yachtInfo_url
                      }
                      marina={selectedLanguage === 'en' ? selectedYachtWithMarinaAndProvince.name_marina_en : selectedYachtWithMarinaAndProvince.name_marina_th}
                      province={selectedLanguage === 'en' ?
                        selectedYachtWithMarinaAndProvince.ProvinceEng : selectedYachtWithMarinaAndProvince.ProvinceThai
                      }
                      destination={
                        selectedLanguage === "en" ? selectedYachtWithMarinaAndProvince.DestinationEng : selectedYachtWithMarinaAndProvince.DestinationThai
                      }
                      travelAgent={
                        selectedYachtWithMarinaAndProvince.travelAgent
                      }
                      dayOption={selectedYachtWithMarinaAndProvince.rate_type}
                      price={
                        selectedYachtWithMarinaAndProvince.value_sellingPrice
                      }
                      amenities={selectedYachtWithMarinaAndProvince.YachtInfoAmenities}
                      waterToy={selectedYachtWithMarinaAndProvince.YachtInfoWaterToy}
                      cruising_speed={selectedYachtWithMarinaAndProvince.cruising_speed}
                      guests_day={selectedYachtWithMarinaAndProvince.guests_day}
                      guests_sleeping={selectedYachtWithMarinaAndProvince.guests_sleeping}

                    />


                    <ImageUnderCard
                      Image={selectedYachtWithMarinaAndProvince.YachtInfoGallery.map(
                        (YachtInfoGallery) =>
                          YachtInfoGallery.photoUrl_yachtInfoGallery
                      )}
                    />
                    <BookingForm bookingYacht={selectedYachtWithMarinaAndProvince} />
                  </div>
                ) : (
                  <div>{t("No yachts available")}</div>
                )}
              </div>
            ) : (
              <div><CircularProgress style={{ color: '#00305c' }} /></div>
            )}
          </div>
        </div>
        <div className="col-lg-1 order-md-6 d-none d-lg-block"></div>
        <div className="col-lg-4 text-center order-md-1 col-md-6 mb-4">
          <TopDestinationMultiRow />
        </div>
      </div>
      <div className="col-lg-12 text-center order-md-6 col-md-12 mb-4">
        <BestSeller />
      </div>
    </div>
  );
}

export default BodyBooking;
