import React, { useState } from "react";
import { Card, Form, Button, Row, Col, InputGroup } from 'react-bootstrap';
import { Stepper, Step, StepLabel } from '@mui/material';
import './bookingDepositCard.scss';

const steps = ['Booking Detail(s)', 'Payment Detail(s)'];


const ExpandedBookingCard = ({ initialFormFields, activeStep, handleStepChange }) => {
    const [isEditMode, setIsEditMode] = useState(false); // State variable to track edit mode
        const [agreed, setAgreed] = useState(false); // State variable to track checkbox

    const handleStepClick = (step) => {
        handleStepChange(step); // Update activeStep when a step is clicked
    };

    const [formFields, setFormFields] = useState(initialFormFields); // Initialize formFields state

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormFields({
            ...formFields,
            [name]: value
        });
    };

    const handleEditClick = () => {
        setIsEditMode(!isEditMode); // Enable edit mode when Edit button is clicked
    };

    const handleNumberKeyDown = (e) => {
        if (e.key === '-' || e.key === '+' || e.key === 'e') {
            e.preventDefault(); // Prevent typing of '-' or '+'
        }
    };

    const handleNumberChange = (e, max) => {
        let value = parseInt(e.target.value);
        if (value > max) {
            value = max;
        }
        setFormFields({
            ...formFields,
            [e.target.name]: value.toString(), // Convert value to string before updating state
        });
    };

    const handlePhoneNumberChange = (e) => {
        let value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
        if (value.length > 12) {
            value = value.slice(0, 12); // Limit to 12 characters
        }
        setFormFields({
            ...formFields,
            [e.target.name]: value, // Update form field with cleaned value
        });
    };

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setFormFields({
            ...formFields,
            [e.target.name]: value, // Update form field if email is valid or empty
        });
    };

    const handleSaveClick = () => {
        setIsEditMode(false);
    };

    const handleCancelClick = () => {
        setIsEditMode(false); // Disable edit mode
        setFormFields(initialFormFields); // Revert to the initial form fields
    };

    const handleDepositPaymentClick = () => {
        handleStepChange(1); // Switch to the second step
    };

    return (
        <Card className="expanded-box">
            <Card.Body>
                <Row>
                    <div className='stepper-space'>
                        <Stepper activeStep={activeStep}>
                            {steps.map((label, index) => (
                                <Step key={label} onClick={() => handleStepClick(index)}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </div>
                    <Col sm={12}>
                        <Form className="mt-auto" disabled={!isEditMode}> {/* Disable form based on edit mode */}
                            {activeStep === 0 && (
                                <>
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column sm={3}>
                                            Contact Person*
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Form.Control
                                                type="text"
                                                name="contactPerson"
                                                value={formFields.contactPerson}
                                                onChange={handleInputChange}
                                                disabled={!isEditMode}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column sm={3}>
                                            Mobile Number*
                                        </Form.Label>
                                        <Col sm={3}>
                                            <Form.Control
                                                type="text"
                                                name="mobileNumber"
                                                value={formFields.mobileNumber}
                                                onChange={handlePhoneNumberChange}
                                                disabled={!isEditMode}
                                            />
                                        </Col>
                                        <Form.Label column sm={2}>
                                            Email*
                                        </Form.Label>
                                        <Col sm={4}>
                                            <Form.Control
                                                type="email"
                                                name="email"
                                                value={formFields.email}
                                                onChange={handleEmailChange}
                                                disabled
                                            />

                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column sm={3}>
                                            No. of Adults*
                                        </Form.Label>
                                        <Col sm={3}>
                                            <InputGroup>
                                                <Form.Control
                                                    type="number"
                                                    name="noOfAdults"
                                                    value={formFields.noOfAdults}
                                                    onChange={(e) => handleNumberChange(e, 30)}
                                                    disabled={!isEditMode}
                                                    min={1} // Minimum value is 1
                                                    max={30}
                                                    onKeyDown={handleNumberKeyDown} // Prevent typing '-' or '+'
                                                />
                                                <InputGroup.Text>pax</InputGroup.Text>
                                            </InputGroup>
                                        </Col>
                                        <Form.Label column sm={3}>
                                            No. of Children*
                                        </Form.Label>
                                        <Col sm={3}>
                                            <InputGroup>
                                                <Form.Control
                                                    type="number"
                                                    name="noOfChildren"
                                                    value={formFields.noOfChildren}
                                                    onChange={(e) => handleNumberChange(e, 30)}
                                                    disabled={!isEditMode}
                                                    min={0} // Minimum value is 1
                                                    max={30}
                                                    onKeyDown={handleNumberKeyDown} // Prevent typing '-' or '+'
                                                />
                                                <InputGroup.Text>pax</InputGroup.Text>
                                            </InputGroup>
                                        </Col>
                                    </Form.Group>
                                    <div className="button-step1">
                                        {isEditMode ? (
                                            <>
                                                <Button className="button-Save" variant="primary" type="button" onClick={handleSaveClick}>
                                                    Save
                                                </Button>
                                                <Button className="button-Cancel" variant="secondary" type="button" onClick={handleCancelClick}>
                                                    Cancel
                                                </Button>
                                            </>
                                        ) : (
                                            <Button className="button-Edit" variant="primary" type="button" onClick={handleEditClick}>
                                                Edit
                                            </Button>
                                        )}
                                        <Button className="button-Deposit" variant="primary" type="button" onClick={handleDepositPaymentClick}>
                                            Deposit Payment
                                        </Button>
                                    </div>
                                </>
                            )}
                            {activeStep === 1 && (
                                <>
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column sm={4} >
                                            <div className="deposit-text col-center">Deposit Payment Amount*</div>
                                        </Form.Label>
                                        <Col sm={3}>
                                            <InputGroup>
                                                <Form.Control
                                                    type="number"
                                                    name="depositAmount"
                                                    value={formFields.depositAmount}
                                                    onChange={handleInputChange}
                                                    disabled
                                                />
                                                <InputGroup.Text>THB</InputGroup.Text>
                                            </InputGroup>
                                        </Col>
                                        <Form.Label column sm={2}>
                                            <div className="deposit-text col-center"> Due Date*</div>
                                        </Form.Label>
                                        <Col sm={3}>
                                            <Form.Control
                                                type="date"
                                                name="dueDate"
                                                value={formFields.dueDate}
                                                onChange={handleInputChange}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3">
                                        <Col sm={{ span: 10, offset: 1 }}>
                                            <Form.Check
                                                type="checkbox"
                                                label="I agree to the terms and conditions, the privacy policy, and the refundable policy"
                                                checked={agreed}
                                                onChange={() => setAgreed(!agreed)}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <div>
                                        <Button className="button-step2" variant="secondary" type="button">
                                            Print Invoice
                                        </Button>
                                        <Button className="button-step2" variant="primary" type="button" disabled={!agreed}>
                                            Click to Pay
                                        </Button>
                                    </div>

                                </>
                            )}

                        </Form>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default ExpandedBookingCard;
