import React from 'react';
import { useTranslation } from "react-i18next";
import "../../Asset/scss/destination/svgDetail.scss"


const IslandSVG = ({ IslandsLenght, AgentLenght, YachtLenght }) => {
    const { t } = useTranslation();
    const selectedLanguage = localStorage.getItem("selectedLanguage");

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="85" height="144" viewBox="0 0 85 144" className="yacht-svg"
        >
            <g id="Group_3416" data-name="Group 3416" transform="translate(-1605.75 -554)">
                <g id="Rectangle_1662" data-name="Rectangle 1662" transform="translate(1605.75 556)" fill="#fff" stroke="#151b47" strokeWidth="1">
                    <path d="M9,0H76a9,9,0,0,1,9,9V55a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V9A9,9,0,0,1,9,0Z" stroke="none" />
                    <path d="M9,.5H76A8.5,8.5,0,0,1,84.5,9V54.5a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0V9A8.5,8.5,0,0,1,9,.5Z" fill="none" />
                </g>
                <g id="Rectangle_1663" data-name="Rectangle 1663" transform="translate(1605.75 556)" fill="#151b47" stroke="#151b47" strokeWidth="1">
                    <path d="M9,0H76a9,9,0,0,1,9,9v6a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V9A9,9,0,0,1,9,0Z" stroke="none" />
                    <path d="M9,.5H76A8.5,8.5,0,0,1,84.5,9v5.5a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0V9A8.5,8.5,0,0,1,9,.5Z" fill="none" />
                </g>
                <text id="Islands" transform="translate(1630.75 567)" fill="#fff" fontSize="12" fontFamily="SegoeUI, Segoe UI"><tspan x="0" y="0">Islands</tspan>
                    <tspan x="18" y="25" fill="#151b47" textAnchor="middle" fontSize="16" fontWeight="bold">{IslandsLenght}</tspan>
                </text>
                <g id="Rectangle_384" data-name="Rectangle 384" transform="translate(1605.75 650)" fill="#fff" stroke="#151b47" strokeWidth="1">
                    <path d="M0,0H85a0,0,0,0,1,0,0V39a9,9,0,0,1-9,9H9a9,9,0,0,1-9-9V0A0,0,0,0,1,0,0Z" stroke="none" />
                    <path d="M.5.5h84a0,0,0,0,1,0,0V39A8.5,8.5,0,0,1,76,47.5H9A8.5,8.5,0,0,1,.5,39V.5A0,0,0,0,1,.5.5Z" fill="none" />
                </g>
                <g id="Rectangle_390" data-name="Rectangle 390" transform="translate(1605.75 603)" fill="#fff" stroke="#151b47" strokeWidth="1">
                    <path d="M9,0H76a9,9,0,0,1,9,9V48a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V9A9,9,0,0,1,9,0Z" stroke="none" />
                    <path d="M9,.5H76A8.5,8.5,0,0,1,84.5,9V47.5a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0V9A8.5,8.5,0,0,1,9,.5Z" fill="none" />
                </g>
                <g id="Rectangle_389" data-name="Rectangle 389" transform="translate(1605.75 603)" fill="#151b47" stroke="#151b47" strokeWidth="1">
                    <rect width="85" height="15" stroke="none" />
                    <rect x="0.5" y="0.5" width="84" height="14" fill="none" />
                </g>
                <text id="Travel_Agents" data-name="Travel Agents" transform="translate(1613.75 614)" fill="#fff" fontSize="12" fontFamily="SegoeUI, Segoe UI"><tspan x="0" y="0">Travel Agents</tspan>
                    <tspan x="35" y="25" fill="#151b47" textAnchor="middle" fontSize="16" fontWeight="bold">{AgentLenght}</tspan>
                </text>
                <g id="Rectangle_387" data-name="Rectangle 387" transform="translate(1605.75 650)" fill="#151b47" stroke="#151b47" strokeWidth="1">
                    <rect width="85" height="15" stroke="none" />
                    <rect x="0.5" y="0.5" width="84" height="14" fill="none" />
                </g>
                <text id="Yacht_Lists" data-name="Yacht Lists" transform="translate(1622.75 661)" fill="#fff" fontSize="12" fontFamily="SegoeUI, Segoe UI"><tspan x="0" y="0">Yacht Lists</tspan>
                    <tspan x="25" y="25" fill="#151b47" textAnchor="middle" fontSize="16" fontWeight="bold">{YachtLenght}</tspan></text>
            </g>
        </svg>
    );
};

export default IslandSVG;