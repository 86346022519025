import { createSlice } from "@reduxjs/toolkit";
import { getPromotion, getTest } from "../../API/PromotionAPI";
const initialState = {
  Promotion: [],
};

export const PromotionSlice = createSlice({
  name: "PromotionState",
  initialState,
  reducers: {
    updateGetPromotion: (state, action) => {
      state.Promotion = action.payload;
    },
  },
});

export const { updateGetPromotion } = PromotionSlice.actions;
export const getPromotionDetail = (value) => async (dispatch) => {
  await getPromotion(value).then(async (data) => {
    await dispatch(updateGetPromotion(data));
    //   await dispatch(stopLoading());
  });
};

export default PromotionSlice.reducer;
