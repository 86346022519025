import { createSlice } from "@reduxjs/toolkit";
import { getYachtInfoSellingPriceRatePlanList } from "../../API/YachtInfoSellingPriceRatePlanListAPI";
const initialState = {
  YachtInfoSellingPriceRatePlanList: [],
};

export const YachtInfoSellingPriceRatePlanListSlice = createSlice({
  name: "YachtInfoSellingPriceRatePlanListState",
  initialState,
  reducers: {
    updateGetYachtInfoSellingPriceRatePlanList: (state, action) => {
      state.YachtInfoSellingPriceRatePlanList = action.payload;
    },
  },
});

export const { updateGetYachtInfoSellingPriceRatePlanList } =
  YachtInfoSellingPriceRatePlanListSlice.actions;
export const getYachtInfoSellingPriceRatePlanListDetail =
  (value) => async (dispatch) => {
    await getYachtInfoSellingPriceRatePlanList(value).then(async (data) => {
      await dispatch(updateGetYachtInfoSellingPriceRatePlanList(data));
      //   await dispatch(stopLoading());
    });
  };

export default YachtInfoSellingPriceRatePlanListSlice.reducer;
