import ProviderAPI from "../redux/ProviderAPI";

export const login = async (data) => {
    const result = await ProviderAPI({
        type: "POST",
        url: "/authprofile/login",
        data: data,
    });
    console.log(result);
    return result;
};

export const register = async (data) => {
    const result = await ProviderAPI({
        type: "POST",
        url: "/authprofile/register",
        data: data,
    });
    console.log(result);
    return result;
};

export const detailUser = async (data) => {
    const result = await ProviderAPI({
        type: "GET",
        url: "/authprofile/detailUser",
        data: data,
        headers: data.token,
    });
    console.log(result);
    return result;
};


export const editUser = async (data) => {
    const result = await ProviderAPI({
        type: "POST",
        url: "/authprofile/editUser",
        data: data.dataForm,
        headers: data.token,
    });
    console.log(result);
    return result;
};