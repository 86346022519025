// Whitelists.js
import React from 'react';
import BoatHelm from "../../../../Asset/icon/boat_helm.svg";
import { useTranslation } from "react-i18next";
const Whitelists = () => {
  const { t } = useTranslation();
  return (
    <div>
      <img className="BoatHelm-1" draggable={false} style={{ marginTop: "5vh" }} src={BoatHelm} alt="BoatHelm" />
      <h3>{t("Whitelist(s)")}</h3>
    </div>
  );
};

export default Whitelists;
