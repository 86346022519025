
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './redux/store';
import "../src/Asset/scss/main.scss"
import 'bootstrap/dist/css/bootstrap.min.css';
import './i18n.js'
// import "./Asset/scss/bootstrap.css.map"
// import "./Asset/scss/bootstrap.min.css"

import "./Asset/scss/bootstrap.min.map.css"
import "./Asset/scss/bootstrap.rtl.only.min.css"

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);