import React, { useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import Close_x from "../../Asset/icon/closeButton.svg";
import LeftArrow from "../../Asset/icon/whitePrevious.svg";
import RightArrow from "../../Asset/icon/whiteNext.svg";
import "../../Asset/scss/yacht/yachtImageUnder.scss"

const ImageUnderCard = ({ Image }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleImageClick = (image, index) => {
    setSelectedImage(image);
    setCurrentIndex(index);
    setShowModal(true);
  };

  const handleNextImage = () => {
    const nextIndex = (currentIndex + 1) % Image.length;
    setSelectedImage(Image[nextIndex]);
    setCurrentIndex(nextIndex);
  };

  const handlePreviousImage = () => {
    const previousIndex = (currentIndex - 1 + Image.length) % Image.length;
    setSelectedImage(Image[previousIndex]);
    setCurrentIndex(previousIndex);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <Row className="mt-4 mb-4 pl-0 justify-content-center">
        {Image.slice(0, 6).map((image, index) => (
          <Col key={index} xs={12} md={2}>
            <img
              style={{
                width: "10dvh",
                height: "7dvh",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                cursor: "pointer",
              }}
              draggable={false}
              src={image}
              alt="imageUnder"
              onClick={() => handleImageClick(image, index)}
            />
          </Col>
        ))}
      </Row>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-fullscreen"
      >
        <Modal.Body className="d-flex justify-content-center align-items-center">
          <div className="position-relative">
            <img
              src={selectedImage}
              draggable={false}
              alt="selectedImage"
              style={{ maxWidth: "100%", maxHeight: "80vh" }}
            />
            <button
              className="modal-nav-button"
              style={{ left: "10rem" }}
              onClick={handlePreviousImage}
            >
              <img src={LeftArrow} alt="Previous" draggable={false} style={{ width: "5vh", height: "5vh" }} />
            </button>
            <button
              className="modal-nav-button"
              style={{ right: "10rem" }}
              onClick={handleNextImage}
            >
              <img src={RightArrow} alt="Next" draggable={false} style={{ width: "5vh", height: "5vh" }} />
            </button>
            <div className="modal-dot-container">
              {Image.map((_, index) => (
                <span
                  key={index}
                  onClick={() => {
                    setSelectedImage(Image[index]);
                    setCurrentIndex(index);
                  }}
                  className={`modal-dot ${currentIndex === index ? 'active' : ''}`}
                />
              ))}
            </div>
            <button
              className="btn modal-close-button"
              onClick={handleCloseModal}
            >
              <img src={Close_x} alt="Close" draggable={false} style={{
                width: "8vh",
                position: "absolute",
                top: "1rem",
                right: "2rem",
                display: "flex",
                alignItems: "center",
                padding: "0.5rem",
                cursor: 'pointer'
              }} />
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ImageUnderCard;
