/* eslint-disable no-undef */
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import i18n from "i18next";

import { styled } from '@mui/system';
import { Box, Typography, IconButton, Popover, Grid, ClickAwayListener, Alert, Snackbar } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';

import "react-datepicker/dist/react-datepicker.css";


import "../../../../Asset/scss/Homepages/header.scss";

import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

//Datepicker
import { ConfigProvider, DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/th'; // Import Thai locale for dayjs
import 'dayjs/locale/en';
import locale from 'antd/es/locale/th_TH';

import { Link } from 'react-router-dom';

//
import { useSelector, useDispatch } from "react-redux";

import {
    getMarinaDetail,
    getProvinceDetail,
    getDestinationDetail,
} from "../../../../redux/centerData/CenterDataSlice";


function SearchForm() {

    //language change
    const { t } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem("selectedLanguage") || i18n.language);
    dayjs.locale(currentLanguage);

    //Form Data
    const storedDestination = JSON.parse(localStorage.getItem("Destination"));
    const initialDestination = storedDestination ? { ...storedDestination } : { name: '', eng: '', type: '' };

    const [destination, setDestination] = useState(initialDestination);

    const [tripType, setTripType] = useState(localStorage.getItem("Trip") || "");

    const [date, setDate] = useState(() => {
        // Check if there is a stored date in local storage
        const storedDate = localStorage.getItem('selectedDate');
        // Parse the stored date if it exists
        if (storedDate) {
            const dates = storedDate.split(',');
            if (dates.length === 1) {
                return dayjs(dates[0], 'YYYY/MM/DD');
            } else if (dates.length === 2) {
                return [dayjs(dates[0], 'YYYY/MM/DD'), dayjs(dates[1], 'YYYY/MM/DD')];
            }
        }
        return null;
    });

    const [counters, setCounters] = useState(() => {
        const storedCounters = JSON.parse(localStorage.getItem("Passenger"));
        return storedCounters || { adults: 1, children: 0, infants: 0 };
    });
    //Trip Trype
    //const [datePop, setdatePop] = useState(localStorage.getItem("DatePopup") || false);
    //Passenger Option
    const passengerOptions = [
        { key: "adults", labelKey: "adults", type: "adult", rangeKey: "adultsRange" },
        { key: "children", labelKey: "children", type: "child", rangeKey: "childrenRange" },
        { key: "infants", labelKey: "infants", type: "infant", rangeKey: "infantsRange" }
    ];

    // Ensure localStorage also defaults to 1 adult if not set
    useEffect(() => {
        const storedCounters = JSON.parse(localStorage.getItem("Passenger"));
        if (!storedCounters || storedCounters.adults < 1) {
            setCounters((prevCounters) => ({ ...prevCounters, adults: 1 }));
        }
    }, []);


    useEffect(() => {
        localStorage.setItem("Passenger", JSON.stringify(counters));
    }, [counters]);

    //Fetch
    const todos = useSelector((state) => state);
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true); // Initial loading state
    useEffect(() => {
        // Fetch data from the API

        Promise.all([
            dispatch(getMarinaDetail({})),
            dispatch(getProvinceDetail({})),
            dispatch(getDestinationDetail({})),
        ])
            .then(() => {
                // Data loading is complete, set loading state to false
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                // Handle error if needed
            });
    }, [dispatch]);
    //console.log("Header:", todos);

    const handleDestinationChange = (event, value) => {
        // If value is falsy or an empty string, set destination to null
        const destinationValue = value ? value : null;
        setDestination(destinationValue);
        localStorage.setItem("Destination", JSON.stringify(destinationValue));
    };

    //Autocomplete Data
    const marinaIds = new Set(todos?.CenterDataSlice?.Marina?.results?.map((marina) => marina.id_province));
    // Consolidate data fetching
    const marinaResults = todos?.CenterDataSlice?.Marina?.results || [];
    const provinceResults = todos?.CenterDataSlice?.Province?.results || [];
    const destinationResults = todos?.CenterDataSlice?.Destination?.results || [];

    // Function to generate options with translations
    const generateOptions = (items, nameKeyThai, nameKeyEng, type) => {
        return items.map(item => ({
            name: currentLanguage === 'th' ? item[nameKeyThai] : item[nameKeyEng],
            eng: item[nameKeyEng],
            type: t(type)
        }));
    };

    const provincesWithOptions = provinceResults.filter(province => marinaIds.has(province.ProvinceID));
    const marinasWithOptions = marinaResults;
    const destinationsWithOptions = destinationResults;
    const optionsA = [
        ...generateOptions(provincesWithOptions, 'ProvinceThai', 'ProvinceEng', 'province'),
        ...generateOptions(marinasWithOptions, 'name_marina_th', 'name_marina_en', 'marina'),
        ...generateOptions(destinationsWithOptions, 'name_destination_th', 'name_destination_en', 'destination')
    ];

    const handleTripTypeChange = (value) => {
        setTripType(value);
        setDate('')
        localStorage.setItem("Trip", value);
        localStorage.setItem("selectedDate", '');
    };

    const handleDatePickerChange = (selectedDates) => {
        // Update the state with the selected date(s)
        setDate(selectedDates);

        // Clear tripType if selectedDates is cleared
        if (!selectedDates) {
            setTripType('');
            localStorage.removeItem('Trip');
        }

        // Store the selected date(s) in local storage
        if (selectedDates === null) {
            // Clear the date from local storage
            localStorage.removeItem('selectedDate');
        } else if (Array.isArray(selectedDates)) {
            const dateString = selectedDates.map(date => date.format('YYYY/MM/DD')).join(',');
            localStorage.setItem('selectedDate', dateString);
        } else {
            localStorage.setItem('selectedDate', selectedDates.format('YYYY/MM/DD'));
        }
    };


    //Passenger
    const [anchorEl, setAnchorEl] = useState(null);

    const boxRef = useRef(null);

    const handleOpenPopover = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    // Handle counter changes ensuring adults cannot go below 1
    const handleCounterChange = (key, operation, event) => {
        event.stopPropagation();
        setCounters((prevCounters) => {
            const newCounters = { ...prevCounters };
            if (operation === "increase") {
                newCounters[key]++;
            } else if (operation === "decrease") {
                if (key === "adults" && newCounters[key] > 1) {
                    newCounters[key]--;
                } else if (key !== "adults" && newCounters[key] > 0) {
                    newCounters[key]--;
                }
            }
            return newCounters;
        });
        localStorage.setItem("Passenger", JSON.stringify(counters));
    };



    useEffect(() => {
        // Update currentLanguage state when language changes
        setCurrentLanguage(i18n.language);
    }, [i18n.language]);


    useEffect(() => {
        const storedLanguage = localStorage.getItem("selectedLanguage");
        if (storedLanguage) {
            setCurrentLanguage(storedLanguage);
        }
    }, []);

    //............................
    const dropdownRef = useRef(null);
    const { RangePicker } = DatePicker;

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowPassengerCount(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const [showAlert, setShowAlert] = useState(false);

    const handleButtonClick = () => {
        if (!destination || !destination.eng) {
            setShowAlert(true);
        } else {
            const url = tripType
                ? date
                    ? `/yacht/${destination.eng}/${tripType}/${Array.isArray(date) ? date.map(d => d.format('YYYY-MM-DD')).join('_') : date.format('YYYY-MM-DD')}`
                    : `/yacht/${destination.eng}/${tripType}`
                : `/yacht/${destination.eng}`;
            window.location.href = url;
        }
    };

    const isOptionEqualToValue = (option, value) => {
        return (
            option.name === value?.name &&
            option.eng === value?.eng &&
            option.type === value?.type
        );
    };


    return (
        <Box className='container-form' sx={{
            flexGrow: 1,
            width: '100%', // Set the width to fill the parent container
            maxWidth: '70%',
            marginTop: '0',
            zIndex: 5,
            '@media (max-width:600px)': {
                marginTop: '150px',
                maxWidth: '80%'
            },
        }}>


            <Grid container spacing={0.5} rowGap={0.8} alignItems="center" justifyContent="center" >
                <Grid item xs={12} md={3} style={{ display: 'flex', alignItems: 'center', order: 1 }}>
                    <Autocomplete
                        id="combo-box-demo"
                        options={optionsA}
                        value={destination || { name: '', eng: '', type: '' }}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={isOptionEqualToValue}
                        renderOption={(props, option) => (
                            <li {...props} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant="body1">
                                    {option.name}
                                    <span style={{ color: 'rgba(0, 0, 0, 0.4)' }}>
                                        &nbsp;&nbsp; - {option.type}
                                    </span>
                                </Typography>
                            </li>
                        )}
                        sx={{
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'white', // Set the background color
                            borderRadius: 8, // Set the border radius
                            '& .MuiOutlinedInput-root': {
                                borderRadius: 8,
                                height: '100%',
                                width: '100%',
                            },
                            '@media (max-width:600px)': {
                                height: '100%',
                                width: '100%',
                                marginBottom: 1,
                                fontSize: '1rem',
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: 8,
                                    height: '100%',
                                    width: '100%',
                                    fontSize: '1rem',
                                },

                            },

                        }}
                        renderInput={(params) => <TextField {...params} label={(params.inputProps.value || params.inputProps.value === 0 || params.selectedItem) ? t("enterDestination") : t("enterDestination")}

                            sx={{
                                '& label.Mui-focused': {
                                    color: 'white',
                                    top: -8,
                                },
                                '& label': {
                                    color: params.inputProps.value || params.inputProps.value === 0 || params.selectedItem ? 'white' : 'gray',
                                    top: params.inputProps.value || params.inputProps.value === 0 || params.selectedItem ? -8 : 0,
                                },
                            }
                            } />
                        }
                        style={{ Index: 9999 }}
                        onChange={handleDestinationChange}
                    />
                </Grid>

                <Grid item xs={tripType ? 5 : 12} md={tripType ? 1 : 2} style={{ display: 'flex', alignItems: 'center', order: 2 }}>
                    <TextField
                        select
                        label={t("Select trip type")}
                        variant="outlined"
                        size="large"
                        value={tripType}
                        onChange={(e) => handleTripTypeChange(e.target.value)}
                        sx={{
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'white',
                            borderRadius: 8,
                            '& .MuiOutlinedInput-root': {
                                borderRadius: 8,
                                width: '100%',
                                height: '100%',
                            },
                            '& label': {
                                color: tripType ? 'white' : 'gray', // Set label color based on whether tripType is selected
                                top: tripType ? -8 : 0, // Move label up if tripType is selected
                            },
                            '& label.Mui-focused': {
                                color: 'white',
                                top: -8,
                            },
                            '@media (max-width:600px)': {
                                maxWidth: '100%'
                            },
                        }}
                    >
                        <MenuItem value="HalfDay">{t("Half Day")}</MenuItem>
                        <MenuItem value="DayTrip">{t("Full Day")}</MenuItem>
                        <MenuItem value="Overnight">{t("Overnight")}</MenuItem>
                    </TextField>
                </Grid>

                <Grid item xs={tripType ? 8 : 0} md={tripType ? 3 : 0} style={{ display: 'flex', alignItems: 'center' }} className='OrderGrid3' >
                    <Space direction="vertical" style={{ marginLeft: 1, width: '100%' }}>
                        {tripType ? (
                            tripType === "HalfDay" || tripType === "DayTrip" ? (
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <ConfigProvider locale={currentLanguage === 'th' ? locale : 'en'}>
                                        <DatePicker
                                            className="custom-range-picker"
                                            onChange={handleDatePickerChange}
                                            size='15'
                                            value={date}
                                            picker="date"
                                            format="YYYY/MM/DD"
                                            disabled={!tripType} // Disable when no trip type is selected
                                        />
                                    </ConfigProvider>
                                </LocalizationProvider>
                            ) : (
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <ConfigProvider locale={currentLanguage === 'th' ? locale : 'en'}>
                                        <RangePicker
                                            className="custom-range-picker"
                                            onChange={handleDatePickerChange}
                                            size='15'
                                            value={date}
                                            picker="date"
                                            format="YYYY/MM/DD"
                                            disabled={!tripType} // Disable when no trip type is selected
                                        />
                                    </ConfigProvider>
                                </LocalizationProvider>
                            )
                        ) : null}
                    </Space>
                </Grid>

                <ClickAwayListener onClickAway={handleClosePopover}>
                    <Grid item xs={tripType ? 7 : 7} md={2} className='OrderGrid4' style={{ display: 'flex', alignItems: 'center' }}>
                        <Box
                            ref={boxRef}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                                height: '3vw',
                                backgroundColor: 'white',
                                borderRadius: 8,
                                cursor: 'pointer',
                                '@media (max-width:600px)': {
                                    height: '8vh',
                                    width: '100%',
                                },
                            }}
                            onClick={handleOpenPopover}
                        >
                            <Grid container spacing={0} justifyContent={'center'}>
                                {passengerOptions.map((option) => (
                                    <Grid item xs={2} md={3} key={option.key}>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            width: '100%',
                                            '@media (max-width:600px)': {
                                                height: '12vw',
                                                width: '100%',
                                            },
                                        }}>
                                            <Typography variant="" sx={{ fontWeight: 'bold', marginLeft: '2vw', marginTop: '3px' }} style={{ marginLeft: '1vw' }}>{counters[option.key]}</Typography>
                                            <Typography variant="" align="center"
                                                sx={{
                                                    fontSize: '0.7vw',
                                                    marginLeft: '2vw',
                                                    '@media (max-width:600px)': {
                                                        fontSize: '0.8rem',
                                                        height: '12vw',
                                                        width: '100%',
                                                    },
                                                }}
                                                style={{ marginLeft: '1vw' }}>{t(option.labelKey)}</Typography>
                                        </Box>
                                    </Grid>
                                ))}
                                <Grid item md={1}
                                    sx={{
                                        marginLeft: '0.5vw',
                                        marginTop: '0.5vw',
                                        alignItems: 'center',
                                        '@media (max-width:600px)': {
                                            marginTop: '2vw',
                                        },
                                    }}>
                                    {anchorEl ? <ArrowDropUpIcon fontSize="medium" /> : <ArrowDropDownIcon fontSize="medium" />}
                                </Grid>

                            </Grid>

                            <Popover
                                open={Boolean(anchorEl)}
                                anchorEl={boxRef.current}
                                onClose={handleClosePopover}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left"
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left"
                                }}
                            >
                                <Box p={2}>
                                    {passengerOptions.map((option) => (
                                        <div key={option.key} style={{ display: "flex", alignItems: "center", justifyContent: 'space-between', marginBottom: 10 }}>
                                            <div style={{ textAlign: 'left' }}>
                                                <Typography variant="" sx={{ fontWeight: 'bold' }}>{t(option.labelKey)}</Typography>
                                                <Typography variant="body2">{t(option.rangeKey)}</Typography>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <IconButton size="small" onClick={(event) => handleCounterChange(option.key, "decrease", event)}>
                                                    <RemoveIcon />
                                                </IconButton>
                                                <Typography>{counters[option.key] || 0}</Typography>
                                                <IconButton size="small" onClick={(event) => handleCounterChange(option.key, "increase", event)}>
                                                    <AddIcon />
                                                </IconButton>
                                            </div>
                                        </div>
                                    ))}
                                </Box>
                            </Popover>
                        </Box>
                    </Grid>
                </ClickAwayListener>

                <Grid item xs={4} md={2} style={{ display: 'flex', alignItems: 'center', order: 5 }}>
                    {/* <Link
                        to={
                            destination && destination.eng && tripType
                                ? date
                                    ? `/yacht/${destination.eng}/${tripType}/${Array.isArray(date) ? date.map(d => d.format('YYYY-MM-DD')).join('_') : date.format('YYYY-MM-DD')}`
                                    : `/yacht/${destination.eng}/${tripType}`
                                : window.location.pathname
                        } style={{ textDecoration: 'none', width: '100%' }}
                    > */}
                    <Button
                        variant="contained"
                        endIcon={<SearchIcon
                            sx={{ color: "white" }} />}
                        sx={{
                            height: '6vh',
                            width: '100%',
                            backgroundColor: '#00305b',
                            borderRadius: 8,
                            '& .MuiOutlinedInput-root': {
                                borderRadius: 8,
                                fontSize: '1rem',
                            },
                            '@media (max-width:600px)': {
                                height: '8vh',
                                width: '100%',
                                fontSize: '0.5rem',
                            },

                        }}

                        onClick={handleButtonClick}
                    >
                        {t("letsGoButton")}
                    </Button>
                    {/* </Link> */}
                </Grid>
            </Grid>

            <Snackbar
                style={{ top: -30 }}
                open={showAlert}
                autoHideDuration={3000}
                onClose={() => setShowAlert(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert sx={{
                    borderRadius: 8,
                    '@media (max-width:600px)': {
                        height: '50%',
                        width: '100%',
                        fontSize: '0.6rem',
                        '& .MuiOutlinedInput-root': {
                            borderRadius: 8,
                            height: '60%',
                            width: '100%',
                            fontSize: '0.6rem',
                        },

                    },
                }} elevation={6} variant="filled" severity="error" onClose={() => setShowAlert(false)}>
                    You need to select a destination and trip.
                </Alert>
            </Snackbar>

        </Box>

    );
}

export default SearchForm;