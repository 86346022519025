import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enJSON from './locale/en.json'
import thJSON from './locale/th.json'
i18n.use(initReactI18next).init({
    resources: {
        en: { ...enJSON },
        th: { ...thJSON },
    },
    lng: localStorage.getItem("selectedLanguage") || "en",
    fallbackLng: localStorage.getItem("selectedLanguage") || "en",
});

// Reload page on language change
i18n.on('languageChanged', (lng) => {
    window.location.reload();
});


