import React, { useState } from 'react';
import {
  Button, TextField, FormControlLabel, Checkbox, Link, Grid, Box, Typography,
  Container, Modal, IconButton, Stack, SvgIcon
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import GoogleIcon from '@mui/icons-material/Google';
import CloseIcon from '@mui/icons-material/Close';
import leafLeft from "../../Asset/icon/leafLeft.svg";
import leafRight from "../../Asset/icon/leafRight.svg";
import { useDispatch } from 'react-redux';
import { RegisterNew } from '../../redux/Auth/AuthSlice';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  fbButton: {
    backgroundColor: '#1877F2',
    color: 'white',
    '&:hover': {
      backgroundColor: '#155DB2',
    },
    borderRadius: '40px',
  },
  googleButton: {
    backgroundColor: 'white',
    color: '#4285F4',
    border: '1px solid #DADCE0',
    '&:hover': {
      backgroundColor: '#F5F5F5',
    },
    borderRadius: '40px',
  },
  googleIcon: {
    color: '#4285F4',
  },
  signUpText: {
    marginTop: '20px',
    textAlign: 'center',
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '40px',
    },
  },

  modalContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '40px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
    maxWidth: '400px',
    width: '80%',
  },
  '@media (max-width: 720px)': {
    modalContent: {
      maxWidth: '350px',
      width: '80%',
    },
  },
}));

const FacebookWhiteIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M22.675 0H1.325C0.593 0 0 0.593 0 1.325v21.351C0 23.407 0.593 24 1.325 24H12.82V14.708h-3.286V11.083h3.286V8.413c0-3.261 1.994-5.038 4.905-5.038 1.392 0 2.589 0.104 2.937 0.15v3.408h-2.016c-1.578 0-1.883 0.75-1.883 1.85v2.424h3.766l-0.491 3.625h-3.275V24h6.419C23.407 24 24 23.407 24 22.675V1.325C24 0.593 23.407 0 22.675 0z" fill="white" />
  </SvgIcon>
);

const SignUpModal = ({ open, onClose }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    allowExtraEmails: 0,
  });

  const [formErrors, setFormErrors] = useState({});
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const updatedValue = type === 'checkbox' ? (checked ? 1 : 0) : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: updatedValue,
    }));
  };

  const validateForm = () => {
    let errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formData.firstName) errors.firstName = t("First name is required");
    if (!formData.lastName) errors.lastName = t("Last name is required");
    if (!formData.email) {
      errors.email = t("Email is required");
    } else if (!emailRegex.test(formData.email)) {
      errors.email = t("Please enter a valid email address");
    }
    if (!formData.password) {
      errors.password = t("Password is required");
    } else if (formData.password.length < 8 || !/[!@#$%^&*(),.?":{}|<>0-9a-zA-Z]/.test(formData.password)) {
      errors.password = t("Password must be at least 8 characters long and contain a mix of letters, numbers, and special characters");
    }
    if (!formData.confirmPassword) errors.confirmPassword = t("Confirm password is required");
    if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword = t("Passwords do not match");
    }
    return errors;
  };


  //console.log(formData);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = validateForm();
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      try {
        const dataRegister = await dispatch(RegisterNew({
          firstname: formData.firstName,
          lastame: formData.lastName,
          email: formData.email,
          password: formData.password,
          receiveEmail: formData.allowExtraEmails
        }));
        //console.log('Form submitted successfully', dataRegister);

        if (dataRegister.status) {
          setFormData({
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
            allowExtraEmails: 0,
          });
          setError('');
          onClose();
        }
      } catch (error) {
        setError("Registration failed. Please try again.");
        console.error("Registration error:", error);
      }
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Container className={classes.modalContent} component="main" maxWidth="xs" sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        padding: '20px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
        borderRadius: '40px',
      }}>
        <Box sx={{
          marginTop: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          <IconButton style={{ position: 'absolute', top: '10px', right: '10px' }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <div className="d-flex align-items-center" style={{ justifyContent: 'center', marginBottom: '20px' }}>
            <img className="img-fluid" draggable={false} src={leafLeft} alt="leftImage" />
            <h3 className="m-2">{t("Sign Up")}</h3>
            <img className="img-fluid" draggable={false} src={leafRight} alt="rightImage" />
          </div>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.textField}
                  sx={{ backgroundColor: 'white', borderRadius: 8 }}
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label={t("First Name")}
                  autoFocus
                  value={formData.firstName}
                  onChange={handleChange}
                  error={!!formErrors.firstName}
                  helperText={formErrors.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.textField}
                  sx={{ backgroundColor: 'white', borderRadius: 8 }}
                  required
                  fullWidth
                  id="lastName"
                  label={t("Last Name")}
                  name="lastName"
                  autoComplete="family-name"
                  value={formData.lastName}
                  onChange={handleChange}
                  error={!!formErrors.lastName}
                  helperText={formErrors.lastName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className={classes.textField}
                  sx={{ backgroundColor: 'white', borderRadius: 8 }}
                  required
                  fullWidth
                  id="email"
                  label={t("Email Address")}
                  name="email"
                  autoComplete="email"
                  value={formData.email}
                  onChange={handleChange}
                  error={!!formErrors.email}
                  helperText={formErrors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className={classes.textField}
                  sx={{ backgroundColor: 'white', borderRadius: 8 }}
                  required
                  fullWidth
                  name="password"
                  label={t("Password")}
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  value={formData.password}
                  onChange={handleChange}
                  error={!!formErrors.password}
                  helperText={formErrors.password}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className={classes.textField}
                  sx={{ backgroundColor: 'white', borderRadius: 8 }}
                  required
                  fullWidth
                  name="confirmPassword"
                  label={t("Confirm Password")}
                  type="password"
                  id="confirmPassword"
                  autoComplete="new-password"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  error={!!formErrors.confirmPassword}
                  helperText={formErrors.confirmPassword}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox name="allowExtraEmails" checked={formData.allowExtraEmails} onChange={handleChange} color="primary" />}
                  label={t("ReceiveEmailPrompt")}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, borderRadius: 8, backgroundColor: '#00305c' }}
            >
              {t("Sign Up")}
            </Button>
            {error && (
              <Typography variant="body2" color="error">
                {error}
              </Typography>
            )}
            <Grid container justifyContent="center">
              <Grid item>
                <Typography variant="body2" margin={'15px'}>
                  {t("AlreadyHaveAccount")}{' '}
                  <Link href="#" variant="body2" onClick={onClose}>
                    {t("Login")}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Modal>
  );
};

export default SignUpModal;

