import React from "react";
import AllHeader from "../../../components/Navbar/AllHeader";
import Filter from "../destination/components/Filter";
import Footer from "../../../components/footerPage/Footer";
import BodyContact from "./components/BodyContact";

function Contact() {
  return (
    <div>
      <AllHeader />
      <BodyContact/>
    </div>
  );
}

export default Contact;
