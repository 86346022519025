/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Range, getTrackBackground } from "react-range";
import RopeBlue from "../../../../../Asset/icon/rope_blue.svg";
import { useTranslation } from "react-i18next";

const SelectSearch = ({ setPriceRange, filterYacht }) => {

  const initialRange = [0, 1000000];
  const [priceRange, setInternalPriceRange] = useState(initialRange);

  const handleClearRange = () => {
    setInternalPriceRange(initialRange);
    setPriceRange(initialRange);
  };
  const { t } = useTranslation();
  return (
    <div className="d-flex flex-column align-items-center position-relative">
      <div className="mb-4">
        <div
          style={{
            position: "absolute",
            left: "0px",
            color: "#151B47",
          }}
        >
          {t("Price")} (THB)
        </div>
        <div
          style={{
            position: "absolute",
            right: "0px",
            cursor: "pointer",
            color: "#007BFF",
          }}
        >
          <a onClick={handleClearRange}>{t("CLEAR")}</a>
        </div>
      </div>
      <div className="mt-4" style={{ width: "80%" }}>
        {/* Range component code here */}
        <Range
          values={priceRange}
          step={10000}
          min={0}
          max={1000000}
          onChange={(values) => setInternalPriceRange(values)}
          onFinalChange={() => {
            setPriceRange(priceRange);
            filterYacht(priceRange); // Pass the filtered range to BodyYacht
          }}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: "6px",
                background: getTrackBackground({
                  values: priceRange,
                  colors: [
                    priceRange[0] > 0 ? "#D1D1D1" : "#D1D1D1", // Color before the first thumb
                    "#151B47", // Color between the thumbs
                    priceRange[1] < 1000000 ? "#D1D1D1" : "#D1D1D1", // Color after the second thumb
                  ],
                  min: 0,
                  max: 1000000,
                }),
                width: "100%",
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: "5px",
                  width: "100%",
                  borderRadius: "4px",
                  alignSelf: "center",
                }}
              >
                <div
                  style={{
                    height: "2vh",
                    width: "2vh",
                    borderRadius: "50%",
                    background: "#D1D1D1",
                    position: "absolute",
                    top: "-6px",
                    left: "-8px",
                  }}
                ></div>
                <div
                  style={{
                    height: "2vh",
                    width: "2vh",
                    borderRadius: "50%",
                    background: "#D1D1D1",
                    position: "absolute",
                    top: "-6px",
                    right: "-8px",
                  }}
                ></div>
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, index }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: "2vh",
                width: "2vh",
                borderRadius: "50%", // Set border radius to 50% for a circular shape
                backgroundColor: "#151B47",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0px 2px 6px #AAA",
              }}
            >
              {" "}
              {index === 0 && priceRange[0] > 0 && (
                <span
                  style={{
                    position: "absolute",
                    top: "-25px",
                    color: "#151B47",
                  }}
                >
                  {priceRange[0] < 10000
                    ? priceRange[0]
                    : priceRange[0] < 1000000
                    ? priceRange[0] / 1000 + "K"
                    : "1M"}
                </span>
              )}
              {index === 1 && priceRange[1] < 1000000 && (
                <span
                  style={{
                    position: "absolute",
                    top: "-25px",
                    color: "#151B47",
                  }}
                >
                  {priceRange[1] < 1000
                    ? priceRange[1]
                    : priceRange[1] < 1000000
                    ? priceRange[1] / 1000 + "K"
                    : "1M"}
                </span>
              )}
            </div>
          )}
        />
        <output
          style={{
            marginTop: "10px",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <p>0</p>
          <p>1M</p>
        </output>
      </div>
      <div>
        <img className="m-4" draggable={false} src={RopeBlue} alt="RopeBlue" />
      </div>
    </div>
  );
};

export default SelectSearch;
