import React from "react";
import Star from "../../Asset/icon/star.svg";
import Location from "../../Asset/icon/location.svg";
import Rope from "../../Asset/icon/Rope.svg";

const ThumbnailCard = ({
  image,
  headline,
  location,
  province,
  vaildDate,
  price,
  currency,
  discount_promotion,
  discount_value_promotion,
}) => {
  const formatPrice = (price) => {
    return new Intl.NumberFormat().format(price);
  };
  return (
    <div>
      <div className="card-hotdeal">
        <div className="image-container-hotdeal">
          <img
            aria-hidden={true}
            draggable={false}
            src={image}
            className="carousel-image-hotdeal"
            alt="carousel-image-hotdeal"
          />
          <div className="hotDeal-OFF">
            <img src={Star} draggable={false} className="star-hotDeal" alt="star-hotDeal" />
            <p>
              Hot deal {discount_value_promotion} {discount_promotion} OFF
            </p>
          </div>
        </div>
        <div className="content-hotdeal">
          <h3 style={{ paddingTop: "10px" }}>{headline}</h3>
          <div className="location">
            <img src={Location} draggable={false} alt="Location" />
            <p>{`${location}${province ? `, ${province}` : ""}`}</p>
          </div>
          <div className="vaildDate">
            <h3>Valid Date</h3>
            <p> {vaildDate}</p>
          </div>

          <div className="row price">
            <div className="col-5">
              <img src={Rope} draggable={false} className="hover-rope" alt="hover-rope" />
            </div>
            <div className="content-price">
              <h3>{currency}</h3>
              <p> {formatPrice(price)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThumbnailCard;
