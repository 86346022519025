import { createSlice } from "@reduxjs/toolkit";
import { getYachtInfoGallery } from "../../API/YachtInfoGalleryAPI";

const initialState = {
  YachtInfoGallery: [],
};

export const YachtInfoGallerySlice = createSlice({
  name: "YachtInfoGalleryState",
  initialState,
  reducers: {
    updateGetYachtInfoGallery: (state, action) => {
      state.YachtInfoGallery = action.payload;
    },
  },
});

export const { updateGetYachtInfoGallery } = YachtInfoGallerySlice.actions;
export const getYachtInfoGalleryDetail = (value) => async (dispatch) => {
  await getYachtInfoGallery(value).then(async (data) => {
    await dispatch(updateGetYachtInfoGallery(data));
    //   await dispatch(stopLoading());
  });
};

export default YachtInfoGallerySlice.reducer;
