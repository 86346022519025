import { createSlice } from "@reduxjs/toolkit";
import { login, register, detailUser, editUser } from "../../API/AuthAPI";

const initialState = {
  isAuthenticated: false,
  user: {},
  token: null,
};

const loadState = () => {
  try {
    const serializedState = localStorage.getItem("authState");
    if (serializedState === null) {
      return initialState;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    console.log("Error loading state from local storage:", error);
    return initialState;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("authState", serializedState);
  } catch (error) {
    console.log("Error saving state to local storage:", error);
  }
};

export const AuthSlice = createSlice({
  name: "AuthState",
  initialState: loadState(),
  reducers: {
    updateLogin: (state, action) => {
      state.isAuthenticated = action.payload.auth;
      state.user = action.payload.result;
      state.token = action.payload.token;
      saveState(state); // Save state to local storage
    },
    updateRegister: (state, action) => {
      state.register = action.payload;
    },
    updateGetUser: (state, action) => {
      state.user = action.payload.user;
    },
    updateEditUser: (state, action) => {
      state.user = action.payload.user;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = {};
      state.token = null;
      localStorage.removeItem("avatarBackgroundColor")
      saveState(state); // Save state to local storage
    },
  },
});

export const { updateLogin, updateRegister, updateGetUser, updateEditUser, logout } = AuthSlice.actions;

export const LoginNew = (LoginData) => async (dispatch) => {
  try {
    const data = await login(LoginData);
    if (data.status) {
      dispatch(updateLogin(data));
    }
    return data;
  } catch (error) {
    console.error("Login failed. Please try again.", error);
  }
};

export const RegisterNew = (RegisterData) => async (dispatch) => {
  try {
    const data = await register(RegisterData);
    dispatch(updateRegister(data));
    return data;
  } catch (error) {
    console.error("Register failed. Please try again.", error);
  }
};

export const getUserDetail = (token) => async (dispatch) => {
  try {
    const data = await detailUser(token);
    dispatch(updateGetUser(data));
    return data;
  } catch (error) {
    console.error("Failed to fetch user details", error);
    throw error;
  }
};

export const editUserDetail = (editUserData) => async (dispatch) => {
  try {
    const data = await editUser(editUserData);
    dispatch(updateEditUser(data));
    return data;
  } catch (error) {
    console.error("Failed to update user details", error);
    throw error;
  }
};

export default AuthSlice.reducer;
