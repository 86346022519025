import React, { useState } from "react";
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import { Link } from "react-router-dom";
//
const DestinationCard = ({ name, imageSrc, destinationEn }) => {
  const [isHovered, setIsHovered] = useState(false);


  return (
    <Card component={Link}
      to={`/destination/detailIsland/${destinationEn}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        marginBottom: '3vw',
        minHeight: '200px', width: 300,
        transition: 'transform 0.3s, border 0.3s',
        '&:hover': {
          transform: 'translateY(-10px)',
          boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)',
        },

        '@media (max-width:720px)': {
          minHeight: '100px', width: 150,
        },
      }}>
      <CardCover>
        <img src={imageSrc} draggable={false} alt={name} />
      </CardCover>
      <CardCover
        sx={{
          background: isHovered
            ? 'none'
            : 'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 50px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
        }}
      />
      <CardContent sx={{ justifyContent: 'flex-end', textAlign: 'left' }}>
        <Typography level="title-lg" textColor="#fff" sx={{
          '@media (max-width:720px)': {
            fontSize: '12px'
          },
        }}>
          {name}
        </Typography>

      </CardContent>
    </Card>
  );
};

export default DestinationCard;
