import React, { useState } from "react";
import { Card, Row, Col, Button } from 'react-bootstrap';
import { FaMapMarkerAlt, FaTimesCircle, FaCheckCircle, FaAddressBook, FaHourglassHalf, FaGrin, FaCalendarCheck } from 'react-icons/fa'; // Import the FaTimesCircle icon
import testImage from "../../Asset/image/yacht/cheesecake-thumb.jpg";
import "./bookingHistoryCard.scss";
import { useTranslation } from "react-i18next";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import { cancelBookingAction, getBookingDetail, getBookingHistoryDetail } from "../../redux/booking/BookingSlice";

const CardComponent = ({ onBookNowClick, yachtImageUrl, yachtName, date, marinaNameEn, marinaNameTh, agent, status, rateType, price, provinceNameEng, provinceNameThai, pending, idBooking }) => {
    const selectedLanguage = localStorage.getItem("selectedLanguage");
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();
    const getToken = useSelector((state) => state.AuthSlice.token);

    const formatPrice = (price) => {
        return new Intl.NumberFormat().format(price);
    };

    const handleCancelClick = () => {
        setShowModal(true);
    };

    const handleBookNowClick = () => {
        // Handle book now action here
        console.log("Now booking");
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleCancelBooking = async () => {
        const dataLogin = await dispatch(cancelBookingAction({ id_booking: idBooking }));
        if (dataLogin.status) {
            dispatch(getBookingHistoryDetail({ token: getToken }));
            dispatch(getBookingDetail)
            setShowModal(false);
        }
        console.log("Cancel booking");
    };

    const getStatusMessage = () => {
        if (pending === 0) {
            return t("Pending for your booking inquiry");
        } else {
            switch (status) {
                case "BOOKED":
                    return t("Ready for your journey");
                case "CANCELLED":
                    return t("Thank you for your sharing experience");
                case "PENDING":
                    return t("Pending for your booking inquiry");
                case "COMPLETED":
                    return t("Have a great vacation, Bon Voyage");
                case "CONFIRMED":
                    return t("Welcome to our Luxurious Journey");
                default:
                    return "";
            }
        }
    };

    const getStatusIcon = () => {
        if (pending === 0) {
            return <FaHourglassHalf className="status-icon booked" />;
        } else {
            switch (status) {
                case "BOOKED":
                    return <FaAddressBook className="status-icon booked" />;
                case "CANCELLED":
                    return <FaGrin className="status-icon cancelled" />;
                case "PENDING":
                    return <FaCheckCircle className="status-icon pending" />;
                case "COMPLETED":
                    return <FaCheckCircle className="status-icon completed" />;
                case "CONFIRMED":
                    return <FaCalendarCheck className="status-icon confirmed" />;
                default:
                    return null;
            }
        }
    };

    return (
        <Card className='cardBH'>
            <div className="status-message" title={getStatusMessage(status)}>
                {getStatusIcon()} {/* Render the status icon */}
                <span>{getStatusMessage(status)}</span>
            </div>
            <Card.Body className='cardBH-body'>
                <Row className="h-100 align-items-center">
                    <Col md={2} className="p-0">
                        <Card.Img src={yachtImageUrl || testImage} />
                    </Col>
                    <Col md={3} className="d-flex flex-column justify-content-center">
                        <div className="d-flex align-items-center">
                            <Card.Title className="mb-0 cardBH-title"> {yachtName}</Card.Title>
                            <span className="text-muted date-size">{date}</span>
                        </div>
                        <Card.Text className="mt-2 cardBH-text">
                            <FaMapMarkerAlt /> {selectedLanguage === 'en' ? marinaNameEn : marinaNameTh}, {selectedLanguage === 'en' ? provinceNameEng : provinceNameThai}
                        </Card.Text>
                        <Card.Link className='cardBH-link' href="#">{t("Operated")} {agent}</Card.Link>
                    </Col>
                    <Col md={2} className="col-center ">
                        <h2 className="day-text-bh">฿{formatPrice(price)}</h2>
                    </Col>
                    <Col md={2} className="col-center ">
                        <h2 className="day-text-bh"> {t(rateType)} </h2>
                    </Col>
                    <Col md={3} className="d-flex status-col col-center">
                        {status === "BOOKED" && pending === 1 ? (
                            <button className="book-now-button" onClick={() => onBookNowClick(idBooking)}>
                                {t("Book Now")}
                            </button>
                        ) : (
                            <span className="text-muted">{pending === 0 && status !== "CANCELLED" ? t("PENDING") : t(status)}</span>
                        )}
                        {status !== "COMPLETED" && status !== "CONFIRMED" && status !== "CANCELLED" && (
                            <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip>{t("Cancel Booking")}</Tooltip>} // Tooltip content
                            >
                                <div>
                                    <FaTimesCircle
                                        className="cancel-button"
                                        color={status === "CANCELLED" ? "grey" : "red"} // Change color based on status
                                        size={30}
                                        onClick={status === "CANCELLED" ? null : handleCancelClick}
                                        style={{
                                            cursor: status === "CANCELLED" ? 'not-allowed' : 'pointer', // Change cursor based on status
                                            marginLeft: '10px'
                                        }}
                                    />
                                </div>
                            </OverlayTrigger>
                        )}
                    </Col>
                </Row>
            </Card.Body>

            {/* Cancellation Modal */}
            {showModal && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: '999'
                    }}
                    onClick={handleCloseModal}
                >
                    <div
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            backgroundColor: 'white',
                            color: 'black',
                            padding: '20px',
                            borderRadius: '5px',
                            textAlign: 'center',
                            zIndex: '999',
                            width: '90%', // Adjusted width for smaller screens
                            maxWidth: '600px', // Limiting max width for larger screens
                            height: 'auto',
                            boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.7)',
                        }}
                        onClick={(e) => e.stopPropagation()}
                        className="mx-auto" // Centering the modal horizontally
                    >
                        <h2 style={{ textAlign: 'left' }}>{t("Cancellation")}</h2>
                        <button
                            onClick={handleCloseModal}
                            style={{
                                position: 'absolute',
                                top: '10px',
                                right: '30px',
                                background: 'transparent',
                                border: 'none',
                                fontSize: '40px',
                                cursor: 'pointer'
                            }}
                        >
                            &times;
                        </button>

                        <hr style={{ width: '100%', margin: 'auto', marginTop: '15px', marginBottom: '15px', border: 'none', borderBottom: '1px solid #000' }} />
                        <p>{t("Do you want to cancel this booking?")}</p>
                        <div className="d-flex align-items-center justify-content-center">
                            <Button variant="secondary" className="no-button" onClick={handleCloseModal}>
                                {t("No")}
                            </Button>
                            <Button variant="primary" className="yes-button" onClick={handleCancelBooking}>
                                {t("Yes")}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </Card>
    );
}

export default CardComponent;
