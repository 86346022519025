import React from "react";
import leafLeft from "../../Asset/icon/leafLeft.svg";
import leafRight from "../../Asset/icon/leafRight.svg";
import { useTranslation } from "react-i18next";
import "../../Asset/scss/yacht/tripeDetailCard.scss"

const TripDetailCard = ({ tripDetail, title }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="d-flex align-items-center title-container">
        <img className="img-fluid" draggable={false} src={leafLeft} alt="leftImage" />
        <h3 className="m-2 title-text">{t(title)}</h3>
        <img className="img-fluid" draggable={false} src={leafRight} alt="rightImage" />
      </div>

      <div dangerouslySetInnerHTML={{ __html: tripDetail }} className="detail-text" />
    </div>
  );
};

export default TripDetailCard;
