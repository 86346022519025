// Redux slice
import { createSlice } from "@reduxjs/toolkit";
import {
  getCountry,
  getCountryValue,
  getProvince,
  getProvinceDescription,
  getLocationWater,
  getDestination,
  getMarina,
  getNationality,
} from "../../API/CenterDataAPI";

const initialState = {
  Country: [],
  CountryValue: [],
  Province: [],
  ProvinceDescription: [],
  LocationWater: [],
  Destination: [],
  Marina: [],
  Nationality: [],
};

export const CenterDataSlice = createSlice({
  name: "CenterDataState",
  initialState,
  reducers: {
    updateCountry: (state, action) => {
      state.Country = action.payload;
    },
    updateCountryValue: (state, action) => {
      state.CountryValue = action.payload;
    },
    updateProvince: (state, action) => {
      state.Province = action.payload;
    },
    updateProvinceDescription: (state, action) => {
      state.ProvinceDescription = action.payload;
    },
    updateLocationWater: (state, action) => {
      state.LocationWater = action.payload;
    },
    updateDestination: (state, action) => {
      state.Destination = action.payload;
    },
    updateMarina: (state, action) => {
      state.Marina = action.payload;
    },
    updateNationality: (state, action) => {
      state.Nationality = action.payload;
    },
  },
});

export const {
  updateCountry,
  updateCountryValue,
  updateProvince,
  updateProvinceDescription,
  updateLocationWater,
  updateDestination,
  updateMarina,
  updateNationality,
} = CenterDataSlice.actions;

export const getCountryDetail = () => async (dispatch) => {
  await getCountry().then(async (data) => {
    await dispatch(updateCountry(data));
  });
};

export const getCountryValueDetail = () => async (dispatch) => {
  await getCountryValue().then(async (data) => {
    await dispatch(updateCountryValue(data));
  });
};

export const getProvinceDetail = () => async (dispatch) => {
  await getProvince().then(async (data) => {
    await dispatch(updateProvince(data));
  });
};

export const getProvinceDescriptionDetail = () => async (dispatch) => {
  await getProvinceDescription().then(async (data) => {
    await dispatch(updateProvinceDescription(data));
  });
};

export const getLocationWaterDetail = () => async (dispatch) => {
  await getLocationWater().then(async (data) => {
    await dispatch(updateLocationWater(data));
  });
};

export const getDestinationDetail = () => async (dispatch) => {
  await getDestination().then(async (data) => {
    await dispatch(updateDestination(data));
  });
};

export const getMarinaDetail = () => async (dispatch) => {
  await getMarina().then(async (data) => {
    await dispatch(updateMarina(data));
  });
};

export const getNationalityDetail = () => async (dispatch) => {
  await getNationality().then(async (data) => {
    await dispatch(updateNationality(data));
  });
};

export default CenterDataSlice.reducer;
