import { createSlice } from "@reduxjs/toolkit";
import { getYachtInfoDestinationPackage } from "../../API/YachtInfoDestinationPackageAPI";

const initialState = {
  YachtInfoDestinationPackage: [],
};

export const YachtInfoDestinationPackageSlice = createSlice({
  name: "YachtInfoDestinationPackageState",
  initialState,
  reducers: {
    updateGetYachtInfoDestinationPackage: (state, action) => {
      state.YachtInfoDestinationPackage = action.payload;
    },
  },
});

export const { updateGetYachtInfoDestinationPackage } = YachtInfoDestinationPackageSlice.actions;
export const getYachtInfoDestinationPackageDetail = (value) => async (dispatch) => {
  await getYachtInfoDestinationPackage(value).then(async (data) => {
    await dispatch(updateGetYachtInfoDestinationPackage(data));
    //   await dispatch(stopLoading());
  });
};

export default YachtInfoDestinationPackageSlice.reducer;
