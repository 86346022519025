import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { createNewBooking } from '../../../../redux/booking/BookingSlice';
import BoatHelm from "../../../../Asset/icon/boat_helm.svg";
import { Form, FormGroup, FormControl, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';

import { getYachtInfoSellingPriceRatePlanListDetail } from "../../../../redux/YachtInfoSellingPriceRatePlanList/YachtInfoSellingPriceRatePlanListSlice";
import { getYachtInfoAmenitiesDetail } from "../../../../redux/YachtInfoAmenities/YachtInfoAmenitiesSlice";
import { getYachtInfoWaterToyDetail } from "../../../../redux/YachtInfoWaterToy/YachtInfoWaterToySlice";
import { getYachtInfoGalleryDetail } from "../../../../redux/yachtInfoGallery/YachtInfoGallerySlice";
import { getYachtInfoDestinationPackageDetail } from "../../../../redux/yachtInfoDestinationPackage/YachtInfoDestinationPackageSlice";
import { getYachtDetail } from "../../../../redux/yacht/YachtSlice";
import {
  getMarinaDetail,
  getDestinationDetail,
  getProvinceDetail,
} from "../../../../redux/centerData/CenterDataSlice";
import { getUserDetail } from "../../../../redux/Auth/AuthSlice";
import LoginModal from '../../../user/login';

const BookingForm = ({ bookingYacht }) => {
  const { t } = useTranslation();
  const { slug } = useParams();
  const todos = useSelector((state) => state);
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.AuthSlice.isAuthenticated);
  const token = useSelector((state) => state.AuthSlice.token);
  const userDetails = useSelector((state) => state.AuthSlice.user); // Get user details from the state
  //console.log("userDetails", userDetails);

  const [loading, setLoading] = useState(true); // Initial loading state
  const [showLoginPrompt, setShowLoginPrompt] = useState(false); // State for login prompt modal

  useEffect(() => {
    const fetchData = async () => {
      if (isAuth && token) {
        await dispatch(getUserDetail({ token }));
      }

      Promise.all([
        dispatch(getDestinationDetail({})),
        dispatch(getYachtInfoSellingPriceRatePlanListDetail({})),
        dispatch(getMarinaDetail({})),
        dispatch(getProvinceDetail({})),
        dispatch(getYachtDetail({})),
        dispatch(getYachtInfoAmenitiesDetail({})),
        dispatch(getYachtInfoWaterToyDetail({})),
        dispatch(getYachtInfoGalleryDetail({})),
        dispatch(getYachtInfoDestinationPackageDetail({})),
      ])
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };

    fetchData();
  }, [dispatch, isAuth, token]);

  const selectedYacht = loading
    ? null
    : todos.YachtInfoSellingPriceRatePlanListSlice.YachtInfoSellingPriceRatePlanList.results.find(
      (YachtInfoSellingPriceRatePlanList) =>
        YachtInfoSellingPriceRatePlanList.id_sellingPrice === slug
    );

  const selectedYachtWithMarinaAndProvince = loading
    ? null
    : (() => {
      const marina = todos.CenterDataSlice.Marina.results.find(
        (marina) => marina.id_marina === selectedYacht.id_marina
      );

      const province = todos.CenterDataSlice.Province.results.find(
        (province) => province.ProvinceID === marina?.id_province
      );

      const travelAgent =
        selectedYacht.id_yacht &&
        todos.YachtSlice.Yacht.results.find(
          (yacht) => yacht.id_yacht === selectedYacht.id_yacht
        );

      const YachtInfoAmenities =
        todos.YachtInfoAmenitiesSlice.YachtInfoAmenities.results.filter(
          (yachtInfoAmenities) =>
            yachtInfoAmenities.id_yachtInfo === selectedYacht.id_yachtInfo
        );
      const YachtInfoWaterToy =
        todos.YachtInfoWaterToySlice.YachtInfoWaterToy.results.filter(
          (yachtInfoWaterToy) =>
            yachtInfoWaterToy.id_yachtInfo === selectedYacht.id_yachtInfo
        );
      const YachtInfoGallery =
        todos.YachtInfoGallerySlice.YachtInfoGallery.results.filter(
          (yachtInfoGallery) =>
            yachtInfoGallery.id_yachtInfo === selectedYacht.id_yachtInfo
        );
      const YachtInfoDestinationPackage =
        todos.YachtInfoDestinationPackageSlice.YachtInfoDestinationPackage.results.find(
          (yachtInfoDestinationPackage) =>
            yachtInfoDestinationPackage.id_destination_package ===
            selectedYacht.id_destination_package
        );

      const destinationIds = todos.YachtInfoDestinationPackageSlice.YachtInfoDestinationPackage.results
        .find((desID) => desID.id_destination_package === selectedYacht.id_destination_package)
        ?.destination_included;

      const destinationIdsArray = destinationIds ? destinationIds.split(",") : [];
      const DestinationEng = destinationIdsArray.map((destinationId) => {
        const destination = todos.CenterDataSlice.Destination.results.find(
          (destination) => destination.id_destination === destinationId
        );
        return destination ? destination.name_destination_en : null;
      }).join(",");

      const DestinationThai = destinationIdsArray.map((destinationId) => {
        const destination = todos.CenterDataSlice.Destination.results.find(
          (destination) => destination.id_destination === destinationId
        );
        return destination ? destination.name_destination_th : null;
      }).join(",");

      return {
        ...selectedYacht,
        name_marina_en: marina ? marina.name_marina_en : null,
        name_marina_th: marina ? marina.name_marina_th : null,
        ProvinceEng: province ? province.ProvinceEng : null,
        ProvinceThai: province ? province.ProvinceThai : null,
        ProvinceID: province ? province.ProvinceID : null,
        travelAgent: travelAgent ? travelAgent.name_yacht : null,
        YachtInfoAmenities: YachtInfoAmenities,
        YachtInfoWaterToy: YachtInfoWaterToy,
        YachtInfoGallery: YachtInfoGallery,
        YachtInfoDestinationPackage: YachtInfoDestinationPackage,
        destinationIds: destinationIds,
        DestinationEng: DestinationEng,
        DestinationThai: DestinationThai
      };
    })();


  const [tel, setTel] = useState(userDetails?.mobile_no || ""); // Pre-fill with user detail
  const [firstName, setFirstName] = useState(userDetails?.firstname || "");
  const [lastName, setLastName] = useState(userDetails?.lastname || "");
  const [email, setEmail] = useState(userDetails?.email || "");
  const [comment, setComment] = useState("");
  const [selectedDate, setSelectedDate] = useState(localStorage.getItem("selectedDate"));
  const [tripType, setTripType] = useState(selectedYachtWithMarinaAndProvince?.rate_type || "");
  const [counters, setCounters] = useState(JSON.parse(localStorage.getItem("Passenger")) || { adults: 0, children: 0, infants: 0 });
  const [showSuccess, setShowSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
  };

  const [bookingData, setBookingData] = useState({
    activity_date: selectedDate,
    adult: counters.adults,
    child: counters.children,
    sum_all_price: bookingYacht.value_sellingPrice,
    status: "BOOKED",
    id_customer_type: "0000000001",
    activity_type: bookingYacht.rate_type,
    overnight_stay: bookingYacht.rate_type === "Overnight" ? "overnight" : null,
    issued_date: formatDate(new Date()),
    guest_name: `${firstName} ${lastName}`,
    id_yachtInfo: bookingYacht.id_yachtInfo,
    yacht_name: bookingYacht.name_yacht_yachtInfo,
    comment: comment,
    id_yacht: bookingYacht.id_yacht,
    email: userDetails?.email,
    firstName: userDetails?.firstname,
    lastName: userDetails?.lastname,
    tel: userDetails?.mobile_no,
    idPassport: userDetails?.id_passport,
    birthDate: userDetails?.date_of_birth,
    id_country: userDetails?.country_value,
    id_nationality: userDetails?.idNationality,
    gender: userDetails?.gender,
    sum_price: bookingYacht.value_sellingPrice,
    sum_surcharge: 0,
    id_destination_package: bookingYacht.id_destination_package,
    id_ratePlanList: bookingYacht.id_ratePlanList,
    id_sellingPrice: bookingYacht.id_sellingPrice,
    type_sellingPrice: bookingYacht.type_sellingPrice,
    value_sellingPrice: bookingYacht.value_sellingPrice,
    name_ratePlan: bookingYacht.name_ratePlan,
    id_ratePlan: bookingYacht.id_ratePlan,
  });

  //console.log("bookingData", bookingData);

  useEffect(() => {
    if (userDetails && bookingYacht) {
      setBookingData(prevState => ({
        ...prevState,
        tel: userDetails?.mobile_no,
        email: userDetails?.email,
        firstName: userDetails?.firstname,
        lastName: userDetails?.lastname,
        mobileNo: userDetails?.mobile_no,
        idPassport: userDetails?.id_passport,
        birthDate: userDetails?.date_of_birth,
        id_country: userDetails?.country_value,
        id_nationality: userDetails?.idNationality,
        gender: userDetails?.gender,
        activity_date: selectedDate,
        sum_all_price: bookingYacht?.value_sellingPrice || 0,
        activity_type: bookingYacht?.rate_type || '',
        overnight_stay: bookingYacht?.rate_type === "Overnight" ? "overnight" : null,
        guest_name: `${userDetails?.firstname || ''} ${userDetails?.lastname || ''}`,
        id_yachtInfo: bookingYacht?.id_yachtInfo || '',
        yacht_name: bookingYacht?.name_yacht_yachtInfo || '',
        id_yacht: bookingYacht?.id_yacht || '',
        comment: comment || '',
        sum_price: bookingYacht.value_sellingPrice,
        sum_surcharge: 0,  // Default value, you might change this as needed
        id_destination_package: bookingYacht.id_destination_package,
        id_ratePlanList: bookingYacht.id_ratePlanList,
        id_sellingPrice: bookingYacht.id_sellingPrice,
        type_sellingPrice: bookingYacht.type_sellingPrice,
        value_sellingPrice: bookingYacht.value_sellingPrice,
      }));
    }
  }, [userDetails, bookingYacht, selectedDate, comment]);

  useEffect(() => {
    if (userDetails) {
      setFirstName(userDetails.firstname || "");
      setLastName(userDetails.lastname || "");
      setTel(userDetails.mobile_no || "");
      setEmail(userDetails.email || "");
    }
  }, [userDetails]);


  useEffect(() => {
    const storedDate = localStorage.getItem("selectedDate");
    if (storedDate) {
      setSelectedDate(storedDate);
    }

    if (!loading && selectedYachtWithMarinaAndProvince) {
      const storedTripType = selectedYachtWithMarinaAndProvince.rate_type;
      if (storedTripType && tripType !== storedTripType) {
        setTripType(storedTripType);
      }
    }

    const storedPassenger = JSON.parse(localStorage.getItem("Passenger"));
    if (storedPassenger && JSON.stringify(counters) !== JSON.stringify(storedPassenger)) {
      setCounters(storedPassenger);
    }
  }, [loading, selectedYachtWithMarinaAndProvince]);

  const handleTelChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setTel(value);
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isAuth) {
      setIsSubmitting(true);
      dispatch(createNewBooking(bookingData))
        .then((response) => {
          //console.log("Booking response:", response);
          setShowSuccess(true);
          setIsSubmitting(false);
        })
        .catch((error) => {
          console.error("Error creating booking:", error);
          setIsSubmitting(false);
        });
    } else {
      setShowLoginPrompt(true); // Show login prompt if user is not authenticated
    }
  };

  const handleClose = () => setShowSuccess(false);

  // Login modal
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const handleOpenLoginModal = () => setLoginModalOpen(true);
  const handleCloseLoginModal = () => setLoginModalOpen(false);

  // Register modal
  const [isRegisterOpen, setIsRegisterOpen] = useState(false);
  const handleOpenRegister = () => setIsRegisterOpen(true);
  const handleCloseRegister = () => setIsRegisterOpen(false);

  const switchToSignUp = () => setIsRegisterOpen(true);

  //handle login booking
  const handleCloseLogin = () => {
    setShowLoginPrompt(false)
    setLoginModalOpen(true)
  };

  return (
    <div style={{ position: 'relative' }}>
      <div className="mb-4">
        <img className="BoatHelm-1" draggable={false} src={BoatHelm} alt="BoatHelm" />
        <h2>{t("Booking Inquiry")}</h2>
      </div>
      <Form className="row justify-content-center col-sm-6 col-md-12 mx-auto " onSubmit={handleSubmit}>
        <FormGroup className="col-12 col-xs-12 col-md-6 mb-2"  >
          <Form.Label className="d-flex align-items-start m-0">
            {t("First Name")} *
          </Form.Label>
          <FormControl
            type="text"
            placeholder={t("First Name")}
            value={firstName}
            onChange={handleFirstNameChange}
            required
            disabled={isAuth}
          />
        </FormGroup>
        <FormGroup className="col-12 col-xs-12 col-md-6 mb-2">
          <Form.Label className="d-flex align-items-start m-0">
            {t("Last Name")} *
          </Form.Label>
          <FormControl
            type="text"
            placeholder={t("Last Name")}
            value={lastName}
            onChange={handleLastNameChange}
            required
            disabled={isAuth}

          />
        </FormGroup>

        <FormGroup className="col-12 col-xs-12 col-md-6 mb-2">
          <Form.Label className="d-flex align-items-start m-0">
            {t("Tel.")} *
          </Form.Label>
          <FormControl
            type="tel"
            placeholder={t("Tel.")}
            value={tel}
            onChange={handleTelChange}
            required
            disabled={isAuth && tel}

          />
        </FormGroup>

        <FormGroup className="col-12 col-xs-12 col-md-6 mb-2">
          <Form.Label className="d-flex align-items-start m-0">
            {t("Email")} *
          </Form.Label>
          <FormControl
            type="email"
            placeholder={t("Email")}
            value={email}
            onChange={handleEmailChange}
            required
            disabled={isAuth}

          />
        </FormGroup>

        <FormGroup className="col-12 col-xs-12 col-md-6 mb-2">
          <Form.Label className="d-flex align-items-start m-0">
            {t("Activity Date")}
          </Form.Label>
          <FormControl
            type="text"
            value={`${tripType} ${selectedDate || ''}`}
            required
            disabled
          />
        </FormGroup>

        <FormGroup className="col-12 col-xs-12 col-md-6 mb-2">
          <Form.Label className="d-flex align-items-start m-0">
            {t("Destination Package")}
          </Form.Label>
          <FormControl
            type="text"
            value={`${bookingYacht.YachtInfoDestinationPackage.name_destination_package} (${slug})`}
            required
            disabled
          />
        </FormGroup>

        <FormGroup className="col-12 mb-2">
          <Form.Label className="d-flex align-items-start m-0">
            {t("Message")}
          </Form.Label>
          <FormControl
            as="textarea"
            rows={3}
            placeholder={t("Message")}
            value={comment}
            onChange={handleCommentChange}
          />
        </FormGroup>

        <div className="col-12 col-xs-12 col-md-12 mb-2 mx-auto">
          {isSubmitting ? (
            <CircularProgress />
          ) : (
            <Button className="col-2" type="submit" id="province-dropdown">
              {t("Submit")}
            </Button>
          )}
        </div>
      </Form>

      {showSuccess && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '999'
          }}
          onClick={handleClose}
        >
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'white',
              color: 'black',
              padding: '20px',
              border: '1px solid #000',
              borderRadius: '5px',
              textAlign: 'center',
              zIndex: '999',
              width: '90%', // Adjusted width for smaller screens
              maxWidth: '600px', // Limiting max width for larger screens
              height: 'auto',
            }}
            onClick={(e) => e.stopPropagation()}
            className="mx-auto" // Centering the modal horizontally
          >
            <h2 style={{ textAlign: 'left' }}>{t("Booking Inquiry")}</h2>
            <button
              onClick={handleClose}
              style={{
                position: 'absolute',
                top: '10px',
                right: '30px',
                background: 'transparent',
                border: 'none',
                fontSize: '40px',
                cursor: 'pointer'
              }}
            >
              &times;
            </button>

            <hr style={{ width: '100%', margin: 'auto', marginTop: '15px', marginBottom: '15px', border: 'none', borderBottom: '1px solid #000' }} />
            <p>{t()}</p>
            <p>{t("Thank you for inquiry. Your inquiry has been sent to the agent successfully.")}</p>
            <p>{t("We will get back to you as soon as possible.")}</p>
            <Button id="province-dropdown" variant="primary" onClick={handleClose}>
              {t("Done")}
            </Button>
          </div>
        </div>
      )}

      <LoginModal
        open={isLoginModalOpen}
        onClose={handleCloseLoginModal}
        onSignUp={switchToSignUp}
      />

      {showLoginPrompt && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: '999'
        }}
        >
          <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            color: 'black',
            padding: '20px',
            border: '1px solid #000',
            borderRadius: '5px',
            textAlign: 'center',
            zIndex: '999',
            width: '90%',
            maxWidth: '500px', 
            height: 'auto',
          }}
            className="mx-auto"
          >
            <h2>{t("Please Log In")}</h2>
            <p>{t("You need to log in to complete the booking.")}</p>
            <Button onClick={handleCloseLogin}>{t("OK")}</Button>
          </div>
        </div>
      )}
    </div>

  );
};

export default BookingForm;