import ProviderAPI from "../redux/ProviderAPI";


export const getYachtInfoGallery = async (data, token) => {
    const result = await ProviderAPI({
      type: "GET",
      url: "yachtInfoGallery/detailYachtInfoGallery",
      data,
      token,
    });
      // console.log(result);
    return result;
  };
    