/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Next from "../../Asset/icon/next.svg";
import Previous from "../../Asset/icon/previous.svg";

const CustomButtonGroupAsArrows = ({
  goToSlide,
  carouselRefs,
  customClass,
  customPreviousStyle,
  customNextStyle,
  ...rest
}) => {
  const handlePrevious = () => {
    carouselRefs.forEach((ref) => ref.current.previous());
  };

  const handleNext = () => {
    carouselRefs.forEach((ref) => ref.current.next());
  };

  return (
    <div className={`nextPre-button ${customClass}`} style={{ display: "", marginLeft: '10px' }}>
      <div>
        <a onClick={handlePrevious} className="thumbnail-button " >
          <img className="PreviousBtn" draggable={false} src={Previous} alt="Previous" style={customPreviousStyle}/>
        </a>
      </div>
      <div>
        <a onClick={handleNext} className="thumbnail-button">
          <img className="NextBtn" draggable={false} src={Next} alt="Next" style={customNextStyle}/>
        </a>
      </div>
    </div>
  );
};

export default CustomButtonGroupAsArrows;
