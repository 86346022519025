import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import BestSeller from "../../homePages/components/BestSeller";
import TopDestinationMultiRow from "../../../../components/multiRow/TopDestinationMultiRow";
import YachtCard from "../../../../components/card/YachtCard";
import PriceRange from "./filterYacht/PriceRange";
import TravelAgent from "./filterYacht/TravelAgent";
import YachtType from "./filterYacht/YachtType";
import { YachtData } from "../../../../components/data/YachtData";
import Malina from "./filterYacht/Malina";
import BoatHelmNorm from "../../../../Asset/icon/boat_helm.svg";
import BoatHelm from "../../../../Asset/icon/boat_helm_gray.svg";

import { useSelector, useDispatch } from "react-redux";
import { getYachtInfoSellingPriceRatePlanListDetail } from "../../../../redux/YachtInfoSellingPriceRatePlanList/YachtInfoSellingPriceRatePlanListSlice";
import { getYachtInfoAmenitiesDetail } from "../../../../redux/YachtInfoAmenities/YachtInfoAmenitiesSlice";
import { getYachtInfoWaterToyDetail } from "../../../../redux/YachtInfoWaterToy/YachtInfoWaterToySlice";
import { getYachtInfoGalleryDetail } from "../../../../redux/yachtInfoGallery/YachtInfoGallerySlice";
import { getYachtInfoDestinationPackageDetail } from "../../../../redux/yachtInfoDestinationPackage/YachtInfoDestinationPackageSlice";
import { getYachtTypesDetail } from "../../../../redux/YachtTypes/YachtTypesSlice";
import {
  getMarinaDetail,
  getProvinceDetail,
  getDestinationDetail,
} from "../../../../redux/centerData/CenterDataSlice";
import { getYachtDetail } from "../../../../redux/yacht/YachtSlice";

import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import "./bodyYacht.scss"

function BodyYacht() {
  const { t } = useTranslation();
  const params = useParams();

  const { desName, rateType, date } = useParams(); // Extracting the date parameter
 // console.log("Destination", desName);
 // console.log("Rate Type:", rateType);

  let startDate = null;
  let endDate = null;
  let activityDate = null;

  if (date) {
    const parts = date.split('_');
    if (parts.length === 1) {
      // Single date scenario
      activityDate = parts[0];
      const activityDateParts = activityDate.split('-');
      activityDate = `${activityDateParts[0]}/${parseInt(activityDateParts[1])}/${parseInt(activityDateParts[2])}`;
      //console.log("Activity Date:", activityDate);
    } else if (parts.length === 2) {
      // Date range scenario
      const startDateParts = parts[0].split('-');
      const endDateParts = parts[1].split('-');
      startDate = `${startDateParts[0]}/${parseInt(startDateParts[1])}/${parseInt(startDateParts[2])}`;
      endDate = `${endDateParts[0]}/${parseInt(endDateParts[1])}/${parseInt(endDateParts[2])}`;
      //console.log("Start Date:", startDate);
      //console.log("End Date:", endDate);
    } else {
      console.log("Invalid date format in slug");
    }
  } else {
    console.log("Date not provided in slug");
  }

  const yachtCardContainer = {
    boxShadow: "0px 0px 5px 2px rgba(0, 0, 0, 0.2)",
  };

  const todos = useSelector((state) => state);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true); // Initial loading state
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State for dropdown visibility
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 720); // State for screen width

  // Effect to update isMobile state on window resize
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 720);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Fetch data from the API

    Promise.all([
      dispatch(getMarinaDetail({})),
      dispatch(getProvinceDetail({})),
      dispatch(getDestinationDetail({})),
      dispatch(getYachtDetail({})),
      dispatch(getYachtInfoGalleryDetail({})),
      dispatch(getYachtInfoDestinationPackageDetail({})),
      dispatch(getYachtInfoWaterToyDetail({})),
      dispatch(getYachtInfoSellingPriceRatePlanListDetail({})),
      dispatch(getYachtInfoAmenitiesDetail({})),
      dispatch(getYachtTypesDetail({})),
    ])
      .then(() => {
        // Data loading is complete, set loading state to false
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error if needed
      });
  }, [dispatch]);

  const selectedYacht = loading
    ? null
    : todos.YachtInfoSellingPriceRatePlanListSlice.YachtInfoSellingPriceRatePlanList.results.filter(
      (YachtInfoSellingPriceRatePlanList) =>
        YachtInfoSellingPriceRatePlanList.rate_plan_use_in_TMY_web_site === 1 &&
        (rateType
          ? YachtInfoSellingPriceRatePlanList.rate_type === rateType
          : true) &&
        (date
          ? // Check if only activityDate is provided
          activityDate
            ? // Check if activityDate is within the availability range
            YachtInfoSellingPriceRatePlanList.start_rate_plan_date <= activityDate &&
            YachtInfoSellingPriceRatePlanList.end_rate_plan_date >= activityDate
            : // Check if startDate and endDate are provided
            startDate && endDate
              ? // Check if the yacht is available for the entire duration
              YachtInfoSellingPriceRatePlanList.start_rate_plan_date <= startDate &&
              YachtInfoSellingPriceRatePlanList.end_rate_plan_date >= endDate
              : true
          : true) // If no date is provided, include the result
    );

  //console.log("sselectedYacht", selectedYacht)


  const selectedYachtWithMarinaAndProvince = loading
    ? []
    : (() => {
      const selectedYachtWithAdditionalInfo = selectedYacht.map((yacht) => ({
        ...yacht,
        name_marina_en: todos.CenterDataSlice.Marina.results.find(
          (marina) => marina.id_marina === yacht.id_marina
        )?.name_marina_en,

        name_marina_th: todos.CenterDataSlice.Marina.results.find(
          (marina) => marina.id_marina === yacht.id_marina
        )?.name_marina_th,

        ProvinceEng: todos.CenterDataSlice.Province.results.find(
          (province) =>
            province.ProvinceID ===
            todos.CenterDataSlice.Marina.results.find(
              (marina) => marina.id_marina === yacht.id_marina
            )?.id_province
        )?.ProvinceEng,

        ProvinceThai: todos.CenterDataSlice.Province.results.find(
          (province) =>
            province.ProvinceID ===
            todos.CenterDataSlice.Marina.results.find(
              (marina) => marina.id_marina === yacht.id_marina
            )?.id_province
        )?.ProvinceThai,

        destinationIds: todos.YachtInfoDestinationPackageSlice.YachtInfoDestinationPackage.results.find(
          (desID) => desID.id_destination_package === yacht.id_destination_package
        )?.destination_included,

        DestinationEng: (() => {
          const destinationIds = todos.YachtInfoDestinationPackageSlice.YachtInfoDestinationPackage.results
            .find((desID) => desID.id_destination_package === yacht.id_destination_package)
            ?.destination_included;

          const destinationIdsArray = destinationIds ? destinationIds.split(",") : [];
          const destinationNames = destinationIdsArray.map((destinationId) => {
            const destination = todos.CenterDataSlice.Destination.results.find(
              (destination) => destination.id_destination === destinationId
            );
            return destination ? destination.name_destination_en : null;
          });

          return destinationNames.join(",");
        })(),

        DestinationThai: (() => {
          const destinationIds = todos.YachtInfoDestinationPackageSlice.YachtInfoDestinationPackage.results
            .find((desID) => desID.id_destination_package === yacht.id_destination_package)
            ?.destination_included;

          const destinationIdsArray = destinationIds ? destinationIds.split(",") : [];
          const destinationNames = destinationIdsArray.map((destinationId) => {
            const destination = todos.CenterDataSlice.Destination.results.find(
              (destination) => destination.id_destination === destinationId
            );
            return destination ? destination.name_destination_th : null;
          });

          return destinationNames.join(",");
        })(),

        travelAgent: todos.YachtSlice.Yacht.results.find(
          (travelAgent) => travelAgent.id_yacht === yacht.id_yacht
        )?.name_yacht,

        YachtInfoAmenities:
          todos.YachtInfoAmenitiesSlice.YachtInfoAmenities.results.filter(
            (yachtInfoAmenities) =>
              yachtInfoAmenities.id_yachtInfo === yacht.id_yachtInfo
          ),

        YachtInfoWaterToy:
          todos.YachtInfoWaterToySlice.YachtInfoWaterToy.results.filter(
            (yachtInfoWaterToy) =>
              yachtInfoWaterToy.id_yachtInfo === yacht.id_yachtInfo
          ),
      }));

      return selectedYachtWithAdditionalInfo.filter((yacht) => {
        if (desName) {
          return (
            yacht.ProvinceEng === desName ||
            yacht.name_marina_en === desName ||
            (yacht.DestinationEng && yacht.DestinationEng.includes(desName))
          );
        }
        return true;
      });
    })();


  const marinaSet = new Set();
  const yachtAgentSet = new Set();
  const yachtTypesSet = new Set();

  selectedYachtWithMarinaAndProvince.forEach((yacht) => {
    marinaSet.add(yacht.name_marina_en);
  });

  selectedYachtWithMarinaAndProvince.forEach((yacht) => {
    yachtAgentSet.add(yacht.travelAgent);
  });

  selectedYachtWithMarinaAndProvince.forEach((yacht) => {
    yachtTypesSet.add(yacht.value_yachtTypes);
  });

  //console.log("data", selectedYachtWithMarinaAndProvince);
  //console.log("marinaSet", marinaSet);
  //console.log("yachtAgentSet", yachtAgentSet);
  //console.log("yachtTypesSet", yachtTypesSet);

  const [filteredPriceRange, setFilteredPriceRange] = useState([0, 1000000]);
  const [selectedTravelAgents, setSelectedTravelAgents] = useState([]);
  const [selectedYachtTypes, setSelectedYachtTypes] = useState([]);
  const [selectedMalina, setSelectedMalina] = useState([]);

  const handleMalinaFilter = (selectedMalina) => {
    setSelectedMalina(selectedMalina);
  };

  const handleYachtTypeFilter = (selectedTypes) => {
    setSelectedYachtTypes(selectedTypes);
  };

  const handleFilterYacht = (priceRange) => {
    setFilteredPriceRange(priceRange);
  };

  const handleTravelAgentFilter = (selectedAgents) => {
    setSelectedTravelAgents(selectedAgents);
  };

  const filteredYachtData = selectedYachtWithMarinaAndProvince.filter(
    (yacht) =>
      yacht.name_marina_en &&
      yacht.value_sellingPrice >= filteredPriceRange[0] &&
      yacht.value_sellingPrice <= filteredPriceRange[1] &&
      (selectedTravelAgents.length === 0 ||
        selectedTravelAgents.includes(yacht.travelAgent)) &&
      (selectedYachtTypes.length === 0 ||
        selectedYachtTypes.includes(yacht.value_yachtTypes)) &&
      (selectedMalina.length === 0 ||
        selectedMalina.includes(yacht.name_marina_en))
  );

  //console.log("filteredYachtData", filteredYachtData);
  const selectedLanguage = localStorage.getItem("selectedLanguage");


  const popupRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        // Click occurred outside of popup, close the popup
        setIsDropdownOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef]);

  const uniqueYachts = filteredYachtData.reduce((uniqueYachts, yacht) => {
    // Check if the id_yacht of the current yacht already exists in the uniqueYachts array
    const existingYacht = uniqueYachts.find((uniqueYacht) => uniqueYacht.id_yachtInfo === yacht.id_yachtInfo);

    // If the current yacht is not a duplicate, add it to the uniqueYachts array
    if (!existingYacht) {
      // Find all destinations for the current yacht
      const destinationsEng = filteredYachtData
        .filter((y) => y.id_yachtInfo === yacht.id_yachtInfo)
        .map((y) => y.DestinationEng)
        .filter((destination, index, self) => self.indexOf(destination) === index)
        .join(", ");

      const destinationsThai = filteredYachtData
        .filter((y) => y.id_yachtInfo === yacht.id_yachtInfo)
        .map((y) => y.DestinationThai)
        .filter((destination, index, self) => self.indexOf(destination) === index)
        .join(", ");

      // Find the lowest price for the current yacht
      const lowestPrice = Math.min(
        ...filteredYachtData
          .filter((y) => y.id_yachtInfo === yacht.id_yachtInfo)
          .map((y) => y.value_sellingPrice)
      );

      // Add the yacht to uniqueYachts along with its destinations and lowest price
      uniqueYachts.push({
        ...yacht,
        destinations: { eng: destinationsEng, thai: destinationsThai },
        lowestPrice: lowestPrice
      });
    }

    return uniqueYachts;
  }, []);

  //console.log("uniqueYachts with destinations and lowest price", uniqueYachts);


  return (
    <div className="container" style={{ marginTop: "5vh" }}>
      <div className="row align-items-stretch">
        <div className="col-lg-1 order-md-2 d-none d-lg-block">{/* Content for the left column */}</div>
        <div className="col-lg-4 text-center order-md-1 col-md-6 mb-4">
          <div className="row justify-content-center">
            {loading ? (
              <div>
                <CircularProgress style={{ color: "#00305c" }} />
              </div>
            ) : (



              <div className="col-12 col-md-8 col-xs-12">

                {isMobile ? (
                  <>
                    <img className="BoatHelm-1" draggable={false} src={BoatHelmNorm} alt="BoatHelm" />
                    <h2 style={{ justifyContent: 'center' }}>{t("Yacht lists")}</h2>
                    <button
                      className="filter-button"
                      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    >
                      {t("Filters")}
                    </button>
                    {isDropdownOpen && (
                      <>
                        <div ref={popupRef} className="popup-content">
                          {todos.CenterDataSlice.Marina.results &&
                            todos.CenterDataSlice.Marina.results.length > 0 ? (
                            <div>
                              <PriceRange
                                setPriceRange={handleFilterYacht}
                                filterYacht={handleFilterYacht}
                              />

                              <Malina
                                malinaData={todos.CenterDataSlice.Marina.results.map(
                                  (marina) => marina.name_marina_en //add fitler on this!!
                                )}
                                setMalinaFilter={handleMalinaFilter}
                                preMarina={marinaSet}
                              />

                              <TravelAgent
                                travelAgents={todos.YachtSlice.Yacht.results.map(
                                  (yacht) => yacht.name_yacht
                                )}
                                setTravelAgentFilter={handleTravelAgentFilter}
                                // clearTravelAgentFilter={clearTravelAgentFilter}
                                preYachtAgent={yachtAgentSet}
                              />
                              <YachtType
                                yachtTypes={todos.YachtTypesSlice.YachtTypes.results.map(
                                  (type) => type.yacht_types
                                )}
                                setYachtTypeFilter={handleYachtTypeFilter}
                                preYachtTypes={yachtTypesSet}
                              />

                            </div>

                          ) : (
                            <div>{t("No marinas available")}</div>
                          )}
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <div>
                    {todos.CenterDataSlice.Marina.results &&
                      todos.CenterDataSlice.Marina.results.length > 0 ? (
                      <div>
                        <PriceRange
                          setPriceRange={handleFilterYacht}
                          filterYacht={handleFilterYacht}
                        />

                        <Malina
                          malinaData={todos.CenterDataSlice.Marina.results.map(
                            (marina) => marina.name_marina_en
                          )}
                          setMalinaFilter={handleMalinaFilter}
                          preMarina={marinaSet}
                        />

                        <TravelAgent
                          travelAgents={todos.YachtSlice.Yacht.results.map(
                            (yacht) => yacht.name_yacht
                          )}
                          setTravelAgentFilter={handleTravelAgentFilter}
                          // clearTravelAgentFilter={clearTravelAgentFilter}
                          preYachtAgent={yachtAgentSet}
                        />
                        <YachtType
                          yachtTypes={todos.YachtTypesSlice.YachtTypes.results.map(
                            (type) => type.yacht_types
                          )}
                          setYachtTypeFilter={handleYachtTypeFilter}
                          preYachtTypes={yachtTypesSet}
                        />
                      </div>
                    ) : (
                      <div>{t("No marinas available")}</div>
                    )}

                  </div>
                )}
                {/* <div className="col-lg-12 text-center order-md-5 order-mb-5 col-md-12 mb-6">
                  <TopDestinationMultiRow />
                </div> */}
              </div>
            )}
          </div>

        </div>



        <div div className="col-lg-1 order-md-2 d-none d-lg-block">{/* Content for the right column */}</div>

        <div className="col-lg-5 text-center order-md-2 col-md-6 mb-4">
          {loading ? (
            <div>
              <CircularProgress style={{ color: "#00305c" }} />
            </div>
          ) : selectedYachtWithMarinaAndProvince.length === 0 ? (
            <div
              style={{
                position: "absolute",
                top: "30%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                padding: "20px",
                borderRadius: "10px",
                textAlign: "center",
              }}
            >
              <div
                style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
              >
                <img
                  draggable={false}
                  src={BoatHelm}
                  alt="BoatHelm"
                  style={{
                    width: "100px" /* Adjust width as needed */,
                    height: "auto" /* Maintain aspect ratio */,
                  }}
                />
                <h2 style={{ color: "gray", fontSize: "1rem" }}>
                  "{t("No yachts available")}"
                </h2>
              </div>
            </div>
          ) : (

            uniqueYachts.map((item, index) => (
              <Link to={`/yacht/detailYacht/${item.id_yachtInfo}`} key={index}>
                <YachtCard
                  headline={item.name_yacht_yachtInfo}
                  image={item.logo_yachtInfo_url}
                  marina={
                    selectedLanguage === "en" ? item.name_marina_en : item.name_marina_th
                  }
                  province={
                    selectedLanguage === "en" ? item.ProvinceEng : item.ProvinceThai
                  }
                  travelAgent={item.travelAgent}
                  dayOption={item.rate_type}
                  destination={
                    selectedLanguage === "en" ? item.destinations.eng : item.destinations.thai
                  }
                  price={item.lowestPrice}
                  cruising_speed={item.cruising_speed}
                  guests_day={item.guests_day}
                  guests_sleeping={item.guests_sleeping}
                  amenities={item.YachtInfoAmenities}
                  waterToy={item.YachtInfoWaterToy}
                  style={yachtCardContainer}
                />
              </Link>
            ))
          )}
        </div>
        <div className="col-lg-1 order-md-4 d-none d-lg-block">{/* Content for the right column */}</div>
        <div className="col-lg-12 text-center order-md-4 col-md-12 mb-4">
          <BestSeller />
        </div>
      </div>
    </div >
  );
}

export default BodyYacht;
