import React from 'react';
import { useTranslation } from "react-i18next";
import "../../Asset/scss/destination/svgDetail.scss"

const ProvinceSVG = ({AgentLenght, YachtLenght }) => {
    const { t } = useTranslation();
    const selectedLanguage = localStorage.getItem("selectedLanguage");

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="85" height="97" viewBox="0 0 85 97" className="yacht-svg">
            <g id="Group_3418" data-name="Group 3418" transform="translate(-1555 -601)">
                <g id="Rectangle_1636" data-name="Rectangle 1636" transform="translate(1555 650)" fill="#fff" stroke="#151b47" stroke-width="1">
                    <path d="M0,0H85a0,0,0,0,1,0,0V39a9,9,0,0,1-9,9H9a9,9,0,0,1-9-9V0A0,0,0,0,1,0,0Z" stroke="none" />
                    <path d="M.5.5h84a0,0,0,0,1,0,0V39A8.5,8.5,0,0,1,76,47.5H9A8.5,8.5,0,0,1,.5,39V.5A0,0,0,0,1,.5.5Z" fill="none" />
                </g>
                <g id="Rectangle_1637" data-name="Rectangle 1637" transform="translate(1555 603)" fill="#fff" stroke="#151b47" stroke-width="1">
                    <path d="M9,0H76a9,9,0,0,1,9,9V48a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V9A9,9,0,0,1,9,0Z" stroke="none" />
                    <path d="M9,.5H76A8.5,8.5,0,0,1,84.5,9V47.5a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0V9A8.5,8.5,0,0,1,9,.5Z" fill="none" />
                </g>
                <g id="Rectangle_1638" data-name="Rectangle 1638" transform="translate(1555 603)" fill="#151b47" stroke="#151b47" stroke-width="1">
                    <path d="M9,0H76a9,9,0,0,1,9,9v6a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V9A9,9,0,0,1,9,0Z" stroke="none" />
                    <path d="M9,.5H76A8.5,8.5,0,0,1,84.5,9v5.5a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0V9A8.5,8.5,0,0,1,9,.5Z" fill="none" />
                </g>
                <text id="Travel_Agents" data-name="Travel Agents" transform="translate(1563 614)" fill="#fff" font-size="12" fontFamily="SegoeUI, Segoe UI"><tspan x="0" y="0">Travel Agents</tspan>
                    <tspan x="33" y="22" fill="#151b47" textAnchor="middle" fontSize="16" fontWeight="bold">{AgentLenght}</tspan>
                </text>
                <g id="Rectangle_1639" data-name="Rectangle 1639" transform="translate(1555 650)" fill="#151b47" stroke="#151b47" stroke-width="1">
                    <rect width="85" height="15" stroke="none" />
                    <rect x="0.5" y="0.5" width="84" height="14" fill="none" />
                </g>
                <text id="Agent" transform="translate(1586 647)" fill="#5d5d5d" font-size="10" fontFamily="SegoeUI, Segoe UI"><tspan x="0" y="0">agent</tspan></text>
                <text id="Yacht_Lists" data-name="Yacht Lists" transform="translate(1572 661)" fill="#fff" font-size="12" fontFamily="SegoeUI, Segoe UI">
                    <tspan x="0" y="0">Yacht Lists</tspan>
                    <tspan x="25" y="22" fill="#151b47" textAnchor="middle" fontSize="16" fontWeight="bold">{YachtLenght}</tspan>
                </text>
                <text id="yacht" transform="translate(1587 695)" fill="#5d5d5d" font-size="10" fontFamily="SegoeUI, Segoe UI">
                    <tspan x="0" y="0">yacht</tspan>
                </text>
            </g>
        </svg>
    );
};

export default ProvinceSVG;