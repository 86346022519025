import React from "react";
import TopDestinationMultiRow from "../../../../components/multiRow/TopDestinationMultiRow";
import BestSeller from "../../homePages/components/BestSeller";
import BecomePartner from "./BecomePartner";

function BodyBecome() {
  return (
    <div className="container" style={{ marginTop: "5vh" }}>
      <div className="row align-items-stretch">
        <div className="col-lg-1 order-md-2 d-none d-lg-block">{/* Content for the left column */}</div>
        <div className="col-lg-5 text-center order-md-2 mb-4">
          <BecomePartner />
        </div>
        <div className="col-lg-1 order-md-1 d-none d-lg-block">{/* Content for the left column */}</div>
        <div className="col-lg-4 text-center order-md-1 mb-4">
          <TopDestinationMultiRow />
        </div>
        <div className="col-lg-1 order-md-3 d-none d-lg-block">{/* Content for the left column */}</div>
        <div className="col-lg-12 text-center order-md-3 col-md-12 mb-4">
          <BestSeller />
        </div>
      </div>
    </div>
  );
}

export default BodyBecome;
