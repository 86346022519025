/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import "./customCheckbox.css"; // Import the custom CSS file

import RopeBlue from "../../../../../Asset/icon/rope_blue.svg";
import { Form } from "react-bootstrap";
import { YachtData } from "../../../../../components/data/YachtData"; // Import YachtData
import { useSelector, useDispatch } from "react-redux";
import { getYachtInfoSellingPriceRatePlanListDetail } from "../../../../../redux/YachtInfoSellingPriceRatePlanList/YachtInfoSellingPriceRatePlanListSlice";
import { getMarinaDetail } from "../../../../../redux/centerData/CenterDataSlice";
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from "react-i18next";

const Malina = ({ malinaData, setMalinaFilter, preMarina }) => {
  const { t } = useTranslation();
  const [selectedMalina, setSelectedMalina] = useState(Array.from(preMarina));
  const todos = useSelector((state) => state);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true); // Initial loading state
  useEffect(() => {
    // Fetch data from the API

    Promise.all([
      dispatch(getYachtInfoSellingPriceRatePlanListDetail({})),
      dispatch(getMarinaDetail({})),
    ])
      .then(() => {
        // Data loading is complete, set loading state to false
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error if needed
      });
  }, [dispatch]);
  const AllYacht = loading
    ? [] // Set an initial value, for example, an empty array
    : todos.YachtInfoSellingPriceRatePlanListSlice.YachtInfoSellingPriceRatePlanList.results.map(
      (yacht) => ({
        ...yacht,
        name_marina_en: todos.CenterDataSlice.Marina.results.find(
          (marina) => marina.id_marina === yacht.id_marina
        )?.name_marina_en,
      })
    );

  //console.log("AllYacht", AllYacht);
 // console.log("Malina", malinaData)


  const handleCheckboxChange = (event) => {
    const selectedMalinaItem = event.target.value;
    setSelectedMalina((prevSelected) => {
      if (prevSelected.includes(selectedMalinaItem)) {
        return prevSelected.filter((malina) => malina !== selectedMalinaItem);
      } else {
        return [...prevSelected, selectedMalinaItem];
      }
    });
    setMalinaFilter((prevSelected) => {
      if (prevSelected.includes(selectedMalinaItem)) {
        return prevSelected.filter((malina) => malina !== selectedMalinaItem);
      } else {
        return [...prevSelected, selectedMalinaItem];
      }
    });
  };

  const handleClearFilter = () => {
    setSelectedMalina([]);
    setMalinaFilter([]); // Clear the filter immediately
  };

  return (
    <div className="d-flex flex-column align-items-center position-relative">
      <div className="mb-4">
        <div
          style={{
            position: "absolute",
            left: "0px",
            color: "#151B47",
          }}
        >
          {t("Marina")}
        </div>
        <div
          style={{
            position: "absolute",
            right: "0px",
            cursor: "pointer",
            color: "#007BFF",
          }}
        >
          <a onClick={handleClearFilter}>{t("CLEAR")}</a>
        </div>
      </div>
      <div className="mt-4" style={{ width: "80%" }}>
        <Form className="d-flex flex-column align-items-start">
          {loading ? (
            <div><CircularProgress style={{ color: '#00305c' }} /></div>
          ) : (
            malinaData.map((malina) => {
              //console.log(malina); // Log the current malina object
             // console.log("selectedMalina", selectedMalina)
              return (
                <Form.Check
                  className="custom-checkbox"
                  key={malina.id_marina} // Assuming id_marina is a unique identifier
                  type="checkbox"
                  id={malina.id_marina} // Assuming id_marina is a unique identifier
                  label={
                    <div style={{ textAlign: 'left' }}>
                      {malina} ({AllYacht.filter((yacht) => yacht.name_marina_en === malina).length})
                    </div>
                  }
                  value={malina}
                  onChange={handleCheckboxChange}
                  checked={selectedMalina.includes(malina)}

                />

              );

            })

          )}

        </Form>
      </div>
      <div>
        <img className="m-4" draggable={false} src={RopeBlue} alt="RopeBlue" />
      </div>
    </div>
  );
};

export default Malina;
