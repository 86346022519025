import React from "react";
import { Modal } from "react-bootstrap"; // Make sure to import Modal from react-bootstrap
import { useLocation, useNavigate } from "react-router-dom";
import BoatHelmW from "../../Asset/icon/boat_helm_w.svg";
import Rope_w from "../../Asset/icon/rope_w.svg";
import Close_x from "../../Asset/icon/closeButton.svg";
import Rubber from "../../Asset/icon/rubber.svg";
import { useTranslation } from "react-i18next";

function ModalPage({ show, onHide }) {
  const { t } = useTranslation();

  const bodyContainer = {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    zIndex: 500000
  };
  const linkStyles = {
    display: "flex",
    width: "100%",
    justifyContent: "space-around",
    cursor: "pointer",
    zIndex: 500000
  };

  const WithStyles = ({ headline }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const isCurrentPage = location.pathname
      .toLowerCase()
      .includes(headline.toLowerCase());

    const handleClick = () => {
      if (!isCurrentPage) {
        navigate(`/${headline.toLowerCase()}`);
      } else if (location.pathname.toLowerCase().startsWith(`/${headline.toLowerCase()}`)) {
        // Check if the current page is the "Yacht" page with additional parameters
        navigate(`/${headline.toLowerCase()}`);
        localStorage.removeItem('Trip');
        localStorage.removeItem('Destination');
        localStorage.removeItem('selectedDate');
        window.location.reload(true); // Force refresh
        onHide();
      } else {
        //console.log('Stay Home');
        onHide();
        window.history.replaceState({}, document.title, window.location.pathname); // Clear query parameters
        window.location.reload(true); // Force refresh
      }
    };
    return (
      <div
        className={`modal-body-container ${isCurrentPage ? "visible" : "hidden"
          }`}
        style={bodyContainer}
      >
        <div onClick={handleClick} style={linkStyles}>
          <img
            draggable={false}
            className="Rubber"
            src={Rubber}
            alt="Rubber"
            style={{ visibility: isCurrentPage ? "visible" : "hidden" }}
          />
          <h4 className="fs-1">{t(headline)}</h4>
          <img
            draggable={false}
            className="Rubber"
            src={Rubber}
            alt="Rubber"
            style={{ visibility: isCurrentPage ? "visible" : "hidden" }}
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      <Modal
        show={show}
        fullscreen
        onHide={onHide}
        style={{ paddingRight: "0" }}
      >
        <img
          src={Close_x}
          alt="CloseBtn"
          onClick={onHide}
          style={{
            width: "8vh",
            position: "absolute",
            top: "1rem",
            right: "2rem",
            display: "flex",
            alignItems: "center",
            padding: "0.5rem",
            cursor: 'pointer',
            zIndex: '999'
          }}
        />
        <Modal.Header style={{ marginTop: "10vh", borderBottom: "none" }}>
          <img src={BoatHelmW} alt="BoatHelmW" draggable={false} style={{ width: "15vh" }} />
        </Modal.Header>
        <Modal.Body
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <WithStyles headline="Home" />
          <WithStyles headline="Destination" />
          <WithStyles headline="Yacht" />
          <WithStyles headline="Contact us" />
          <WithStyles headline="Become a Partner" />
        </Modal.Body>
        <Modal.Footer style={{ marginBottom: "10vh", borderTop: "none" }}>
          <img src={Rope_w} alt="Rope_w" draggable={false} style={{ width: "15vh" }} />
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ModalPage;
