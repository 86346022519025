import React, { useState, useEffect } from "react";
import Facebook from "../../Asset/icon/facebook.svg";
import Line from "../../Asset/icon/line.svg";
import Mail from "../../Asset/icon/mail.svg";
import Tel from "../../Asset/icon/tel.svg";
import { useTranslation } from "react-i18next";
import "./Footer.scss"; // Import your CSS file
import { useSelector, useDispatch } from "react-redux";

import { getBookingDetail } from "../../redux/booking/BookingSlice";
import { getYachtInformationDetail } from "../../redux/yachtInformation/YachtInformationSlice";
import { getYachtInfoSellingPriceRatePlanListDetail } from "../../redux/YachtInfoSellingPriceRatePlanList/YachtInfoSellingPriceRatePlanListSlice";
import { getYachtDetail } from "../../redux/yacht/YachtSlice";
import { getYachtInfoDestinationPackageDetail } from "../../redux/yachtInfoDestinationPackage/YachtInfoDestinationPackageSlice";
import {
  getCountryDetail,
  getProvinceDetail,
  getMarinaDetail,
  getDestinationDetail
} from "../../redux/centerData/CenterDataSlice";

function Footer() {
  const selectedLanguage = localStorage.getItem("selectedLanguage");
  const currentYear = new Date().getFullYear();
  const { t } = useTranslation();
  const todos = useSelector((state) => state);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true); // Initial loading state

  useEffect(() => {
    Promise.all([
      dispatch(getProvinceDetail({})),
      dispatch(getCountryDetail({})),
      dispatch(getMarinaDetail({})),
      dispatch(getBookingDetail({})),
      dispatch(getYachtInformationDetail({})),
      dispatch(getYachtDetail({})),
      dispatch(getYachtInfoSellingPriceRatePlanListDetail({})),
      dispatch(getDestinationDetail({})),
      dispatch(getYachtInfoDestinationPackageDetail({})),
    ])
      .then(() => {
        // Data loading is complete, set loading state to false
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error if needed
      });
  }, [dispatch]);

  const filteredBookings = todos.Booking.Booking.results
    ? todos.Booking.Booking.results.filter(
        (Booking) =>
          Booking.status === "COMPLETED" || Booking.status === "BOOKED"
      )
    : [dispatch];

  const groupedBookings = {};
  filteredBookings.forEach((booking) => {
    const idYachtInfo = booking.id_yachtInfo;
    if (!groupedBookings[idYachtInfo]) {
      groupedBookings[idYachtInfo] = [];
    }
    groupedBookings[idYachtInfo].push(booking);
  });

  // Step 3: Calculate Scores
  const yachtScores = Object.keys(groupedBookings).map((idYachtInfo) => ({
    idYachtInfo,
    score: groupedBookings[idYachtInfo].length,
  }));
  //console.log("todos", todos);

  const top3Scores = yachtScores.sort((a, b) => b.score - a.score).slice(0, 20);

  const destinationNames = todos.CenterDataSlice.Destination.results;
  const marinaNames = todos.CenterDataSlice.Marina.results;
  const provinceNames = todos.CenterDataSlice.Province.results;

  const top3WithDestinationMarinaAndProvince = top3Scores.map((scoreItem) => {
    const yachtInformation = todos.YachtInformationSlice.YachtInformation.results?.find(
      (yachtInfo) => yachtInfo.id_yachtInfo === scoreItem.idYachtInfo
    );
    const destinationIds = todos.YachtInfoDestinationPackageSlice.YachtInfoDestinationPackage.results?.find(
      (yachtInfo) => yachtInfo.id_yachtInfo === scoreItem.idYachtInfo
    )?.destination_included.split(","); // Split by commas to handle multiple destinations
    const firstDestinationId = destinationIds?.[0]; // Optional chaining
    const destination = destinationNames?.find(
      (destination) => destination.id_destination === firstDestinationId
    );
    const marinaId = yachtInformation?.id_marina; // Optional chaining
    const marina = marinaNames?.find((marina) => marina.id_marina === marinaId);
    const provinceId = marina?.id_province; // Optional chaining
    const province = provinceNames?.find(
      (province) => province.ProvinceID === provinceId
    );

    const {
      name_destination_en: destinationNameEn = "",
      name_destination_th: destinationNameTh = "",
    } = destination || {};
    const {
      name_marina_en: marinaNameEn = "",
      name_marina_th: marinaNameTh = "",
    } = marina || {};
    const {
      ProvinceEng: provinceNameEn = "",
      ProvinceThai: provinceNameTh = "",
    } = province || {};

    return {
      ...scoreItem,
      destination_name_en: destinationNameEn,
      destination_name_th: destinationNameTh,
      marina_name_en: marinaNameEn,
      marina_name_th: marinaNameTh,
      province_name_en: provinceNameEn,
      province_name_th: provinceNameTh,
    };
  });

  // console.log(
  //   "Top 3 with destination, marina, and province",
  //   top3WithDestinationMarinaAndProvince
  // );

  const uniqueProvinces = new Set();
  const uniqueMarinas = new Set();
  const uniqueDestinations = new Set();

  const uniqueTopProvinces = top3WithDestinationMarinaAndProvince.filter(
    (item) => {
      if (!uniqueProvinces.has(item.province_name_en)) {
        uniqueProvinces.add(item.province_name_en);
        return true;
      }
      return false;
    }
  );

  const uniqueTopMarinas = top3WithDestinationMarinaAndProvince.filter(
    (item) => {
      if (!uniqueMarinas.has(item.marina_name_en)) {
        uniqueMarinas.add(item.marina_name_en);
        return true;
      }
      return false;
    }
  );

  const uniqueTopDestinations = top3WithDestinationMarinaAndProvince.filter(
    (item) => {
      if (!uniqueDestinations.has(item.destination_name_en)) {
        uniqueDestinations.add(item.destination_name_en);
        return true;
      }
      return false;
    }
  );

  return (
    <div>
      <footer className="footer">
        <div className="footer-container">
          <h3>{t("Destination")}</h3>
          <div className="row">
            <div className="footer-col col">
              <h4>{t("Provinces")}</h4>
              <ul>
                {uniqueTopProvinces.slice(0, 3).map((item, index) => (
                  <li key={index}>
                    <a href={`/destination/detailProvince/${item.province_name_en}`}>
                      {selectedLanguage === 'en' ? item.province_name_en : item.province_name_th}, {t("Thailand")}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div className="footer-col col">
              <h4>{t("Marina")}</h4>
              <ul>
                {uniqueTopMarinas.slice(0, 3).map((item, index) => (
                  <li key={index}>
                    <a href={`/yacht/${item.marina_name_en}`}>
                      {selectedLanguage === 'en' ? item.marina_name_en : item.marina_name_th}, {selectedLanguage === 'en' ? item.province_name_en : item.province_name_th}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div className="footer-col col">
              <h4>{t("Island")}</h4>
              <ul>
                {uniqueTopDestinations.slice(0, 3).map((item, index) => (
                  <li key={index}>
                    <a href={`/destination/detailIsland/${item.destination_name_en}`}>
                      {selectedLanguage === 'en' ? item.destination_name_en : item.destination_name_th}, {selectedLanguage === 'en' ? item.province_name_en : item.province_name_th}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <div className="footline">
        <div className="Img-icon">
          <div className="Follow-text">
            <img className="Img-icon" draggable={false} src={Facebook} alt="Facebook icon" />
            <img className="Img-icon" draggable={false} src={Line} alt="Line icon" />
            <img className="Img-icon" draggable={false} src={Tel} alt="Telephone icon" />
            <img className="Img-icon" draggable={false} src={Mail} alt="Mail icon" />
          </div>
        </div>
        <p>Copyright &copy; {currentYear} Phuket Innovative Development Co., Ltd. All rights reserved.</p>
        <p>Partnership T&C Privacy API</p>
      </div>
    </div>
  );
}

export default Footer;
