import { combineReducers } from "redux";
import CenterDataSlice from "./centerData/CenterDataSlice";
import YachtSlice from "./yacht/YachtSlice";
import PromotionSlice from "./promotion/PromotionSlice";
import YachtInformationSlice from "./yachtInformation/YachtInformationSlice";
import YachtInfoSellingPriceRatePlanListSlice from "./YachtInfoSellingPriceRatePlanList/YachtInfoSellingPriceRatePlanListSlice";
import Booking from "./booking/BookingSlice";
import YachtTypesSlice from "./YachtTypes/YachtTypesSlice";
import YachtInfoAmenitiesSlice from "./YachtInfoAmenities/YachtInfoAmenitiesSlice";
import YachtInfoWaterToySlice from "./YachtInfoWaterToy/YachtInfoWaterToySlice";
import YachtInfoGallerySlice from "./yachtInfoGallery/YachtInfoGallerySlice";
import YachtInfoDestinationPackageSlice from "./yachtInfoDestinationPackage/YachtInfoDestinationPackageSlice";
import AuthSlice from "./Auth/AuthSlice";

const reducers = combineReducers({
  CenterDataSlice,
  YachtSlice,
  PromotionSlice,
  YachtInformationSlice,
  YachtInfoSellingPriceRatePlanListSlice,
  Booking,
  YachtInfoWaterToySlice,
  YachtInfoAmenitiesSlice,
  YachtTypesSlice,
  YachtInfoGallerySlice,
  YachtInfoDestinationPackageSlice,
  AuthSlice,
});

export default reducers;
