/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import RopeBlue from "../../../../../Asset/icon/rope_blue.svg";
import { Form } from "react-bootstrap";
import { YachtData } from "../../../../../components/data/YachtData"; // Import YachtData
import { useSelector, useDispatch } from "react-redux";
import { getYachtDetail } from "../../../../../redux/yacht/YachtSlice";
import { getYachtInfoSellingPriceRatePlanListDetail } from "../../../../../redux/YachtInfoSellingPriceRatePlanList/YachtInfoSellingPriceRatePlanListSlice";
import { useTranslation } from "react-i18next";
import "./customCheckbox.css"; // Import the custom CSS file



const TravelAgent = ({ travelAgents, setTravelAgentFilter, preYachtAgent }) => {
  const { t } = useTranslation();

  const [selectedAgents, setSelectedAgents] = useState(Array.from(preYachtAgent));
  const todos = useSelector((state) => state);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true); // Initial loading state
  useEffect(() => {
    // Fetch data from the API

    Promise.all([
      dispatch(getYachtDetail({})),
      dispatch(getYachtInfoSellingPriceRatePlanListDetail({})),
    ])
      .then(() => {
        // Data loading is complete, set loading state to false
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error if needed
      });
  }, [dispatch]);

  const YachtInfoName = loading
    ? [] // Set an initial value, for example, an empty array
    : todos.YachtInfoSellingPriceRatePlanListSlice.YachtInfoSellingPriceRatePlanList.results.map(
      (yacht) => ({
        ...yacht,
        name_yacht: todos.YachtSlice.Yacht.results.find(
          (yachtInfo) => yachtInfo.id_yacht === yacht.id_yacht
        )?.name_yacht,
      })
    );

  const handleCheckboxChange = (event) => {
    const selectedAgent = event.target.value;
    setSelectedAgents((prevSelected) => {
      if (prevSelected.includes(selectedAgent)) {
        return prevSelected.filter((agent) => agent !== selectedAgent);
      } else {
        return [...prevSelected, selectedAgent];
      }
    });
    setTravelAgentFilter((prevSelected) => {
      if (prevSelected.includes(selectedAgent)) {
        return prevSelected.filter((agent) => agent !== selectedAgent);
      } else {
        return [...prevSelected, selectedAgent];
      }
    });
  };

  const handleClearFilter = () => {
    setSelectedAgents([]);
    setTravelAgentFilter([]); // Clear the filter immediately
  };
  // console.log(todos);

  return (
    <div className="d-flex flex-column align-items-center position-relative">
      <div className="mb-4">
        <div
          style={{
            position: "absolute",
            left: "0px",
            color: "#151B47",
          }}
        >
          {t("Travel Agent")}
        </div>
        <div
          style={{
            position: "absolute",
            right: "0px",
            cursor: "pointer",
            color: "#007BFF",
          }}
        >
          <a onClick={handleClearFilter}>{t("CLEAR")}</a>
        </div>
      </div>
      <div className="mt-4" style={{ width: "80%" }}>
        <Form className="d-flex flex-column align-items-start">
          {travelAgents.map((agent) => (
            <Form.Check
              className="custom-checkbox"
              key={agent}
              type="checkbox"
              id={agent}
              label={
                <div style={{ textAlign: 'left' }}>
                  {agent} ({YachtInfoName.filter((yacht) => yacht.name_yacht === agent).length})
                </div>
              }
              value={agent}
              onChange={handleCheckboxChange}
              checked={selectedAgents.includes(agent)}
            />
          ))}
        </Form>
      </div>
      <div>
        <img className="m-4" draggable={false} src={RopeBlue} alt="RopeBlue" />
      </div>
    </div>
  );
};

export default TravelAgent;
