import React, { useState } from "react";
import { Form, FormGroup, FormControl, Button } from "react-bootstrap";
import BoatHelm from "../../../../Asset/icon/boat_helm.svg";
import { useTranslation } from "react-i18next";

function BecomePartner() {
  const { t } = useTranslation();
  const [showSuccess, setShowSuccess] = useState(false);
  const [tel, setTel] = useState("");

  const [formData, setFormData] = useState({
    firstName: "",
    jobTitle: "",
    company: "",
    country: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleTelChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Allow only digits
    setTel(value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //console.log("Form submitted:", formData);
    setShowSuccess(true);
  };

  const handleClose = () => setShowSuccess(false);

  return (
    <div style={{ position: "relative" }}>
      <div className="mb-4">
        <img className="BoatHelm-1" draggable={false} src={BoatHelm} alt="BoatHelm" />
        <h2>{t("Contact Us")}</h2>
      </div>
      <Form className="row justify-content-end text-start col-sm-6 col-md-12 mx-auto" onSubmit={handleSubmit}>
        <FormGroup className="d-flex col-12 mb-2">
          <Form.Label className="col-3 align-items-start m-0">
            {t("First Name")} *
          </Form.Label>
          <FormControl
            type="text"
            name="firstName"
            placeholder={t("First Name")}
            value={formData.firstName}
            onChange={handleChange}
            required
          />
        </FormGroup>

        <FormGroup className="d-flex col-12 mb-2">
          <Form.Label className="col-3 align-items-start m-0">
            {t("Job Title")} *
          </Form.Label>
          <FormControl
            type="text"
            name="jobTitle"
            placeholder={t("Job Title")}
            value={formData.jobTitle}
            onChange={handleChange}
            required
          />
        </FormGroup>

        <FormGroup className="d-flex col-12 mb-2">
          <Form.Label className="col-3 align-items-start m-0">
            {t("Company")}
          </Form.Label>
          <FormControl
            type="text"
            name="company"
            placeholder={t("Company")}
            value={formData.company}
            onChange={handleChange}
            required
          />
        </FormGroup>

        <FormGroup className="d-flex col-12 mb-2">
          <Form.Label className="col-3 align-items-start m-0">
            {t("Country")}
          </Form.Label>
          <FormControl
            type="text"
            name="country"
            placeholder={t("Country")}
            value={formData.country}
            onChange={handleChange}
            required
          />
        </FormGroup>

        <FormGroup className="d-flex col-12 mb-2">
          <Form.Label className="col-3 align-items-start m-0">
            {t("Business Email")} *
          </Form.Label>
          <FormControl
            type="email"
            name="email"
            placeholder={t("Email")}
            value={formData.email}
            onChange={handleChange}
            required
          />
        </FormGroup>

        <FormGroup className="d-flex col-12 mb-2">
          <Form.Label className="col-3 align-items-start m-0">
            {t("Phone")}
          </Form.Label>
          <FormControl
            type="tel"
            placeholder={t("Tel.")}
            value={tel}
            onChange={handleTelChange}
            required
          />
        </FormGroup>

        <FormGroup className="d-flex col-12 mb-2">
          <Form.Label className="col-3 align-items-start m-0">
            {t("Message")}
          </Form.Label>
          <FormControl
            as="textarea"
            rows={1}
            name="message"
            placeholder={t("Description")}
            value={formData.message}
            onChange={handleChange}
          />
        </FormGroup>

        <FormGroup className="d-flex col-12 mb-2 col-sm-12 justify-content-end">
          <div className="col-10 form-check p-0">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            <label className="form-check-label text-end" htmlFor="flexCheckDefault">
              {t("By proceeding, you indicate you have read and agree to the Cookie Policy. And Privacy Policy")}
            </label>
          </div>
        </FormGroup>

        <Button className="col-2" type="submit" id="province-dropdown">
          {t("Submit")}
        </Button>
      </Form>

      {showSuccess && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 999,
          }}
          onClick={handleClose}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              color: "black",
              padding: "20px",
              border: "1px solid #000",
              borderRadius: "5px",
              textAlign: "center",
              zIndex: 999,
              width: "600px",
              height: "auto",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <h2 style={{ textAlign: "left" }}>{t("Contact Us")}</h2>
            <button
              onClick={handleClose}
              style={{
                position: "absolute",
                top: "10px",
                right: "30px",
                background: "transparent",
                border: "none",
                fontSize: "40px",
                cursor: "pointer",
              }}
            >
              &times;
            </button>

            <hr
              style={{
                width: "100%",
                margin: "auto",
                marginTop: "15px",
                marginBottom: "15px",
                border: "none",
                borderBottom: "1px solid #000",
              }}
            />
            <p>{t("We will get back to you as soon as possible.")}</p>
            <Button id="province-dropdown" variant="primary" onClick={handleClose}>
              {t("Done")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default BecomePartner;
