import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        // Scroll to the top of the page whenever pathname changes (i.e., when navigating to a new page)
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [pathname]);

    // This component doesn't render anything, it's just for scrolling to top
    return null;
}

export default ScrollToTop;
