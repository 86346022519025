import React from "react";
import "react-multi-carousel/lib/styles.css";
import BoatHelm from "../../../../../../Asset/icon/boat_helm.svg";
import TopDestinationMultiRow from "../../../../../../components/multiRow/TopDestinationMultiRow";
import AllHeader from "../../../../../../components/Navbar/AllHeader";
import Footer from "../../../../../../components/footerPage/Footer";
import ProvinceCard from "../../../../../../components/card/ProvinceCard";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import { Card, CardContent, Typography, Grid } from "@material-ui/core";

function DetailProvince() {
  const { t } = useTranslation();

  return (
    <div>
      <AllHeader />
      <div className="container" style={{ marginTop: "5vh" }}>
        <div className="row align-items-stretch">

          <div className="col-lg-1 order-md-2 d-none d-lg-block"></div>

          <div className="col-lg-5 text-center order-md-2 col-md-6 mb-4">
            <div className="mb-4 ">
              <img className="BoatHelm-1" draggable={false} src={BoatHelm} alt="BoatHelm" />
              <h3>{t("Provinces")}</h3>
            </div >
            <ProvinceCard />
          </div>
          <div className="col-lg-1 order-md-1 d-none d-lg-block">{/* Content for the left column */}</div>
          <div className="col-lg-4 text-center order-md-1 col-md-6 mb-4">
            <TopDestinationMultiRow />
          </div>
          <div className="col-lg-1 order-md-3 d-none d-lg-block">{/* Content for the right column */}</div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default DetailProvince;