import React, { useState, useEffect } from "react";
import Anchor from "../../Asset/icon/anchor.svg";
import compass from "../../Asset/icon/compass.svg";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getDestinationDetail } from "../../redux/centerData/CenterDataSlice";
import { Card, CardContent, Typography, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import "../../Asset/scss/yacht/packagesCard.scss"
const PackagesCard = ({ name, islands, price, dayOption, slug }) => {

  const todos = useSelector((state) => state);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true); // Initial loading state
  useEffect(() => {
    // Fetch data from the API

    Promise.all([dispatch(getDestinationDetail({}))])
      .then(() => {
        // Data loading is complete, set loading state to false
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error if needed
      });
  }, [dispatch]);

  const formattedPrice = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const { t } = useTranslation();

  const mapDay = (dayOption) => {
    switch (dayOption) {
      case "DayTrip":
        return t("Full Day"); // Translate "DayTrip" to Thai
      case "HalfDay":
        return t("Half Day"); // Translate "HalfDay" to Thai
      case "Overnight":
        return t("Overnight"); // Translate "Overnight" to Thai
      default:
        return dayOption;
    }
  };

  const translatedDay = mapDay(dayOption);

  return (
    <div style={{ boxShadow: "0px 4px 10px 2px rgba(0, 0, 0, 0.1)" }}>
      <div className="row align-items-stretch mb-4 p-3">
        <div className="col-5">
          <div className="d-flex">
            <img src={Anchor} draggable={false} />
            <p className="m-0 p-2">{name}</p>
          </div>

          {islands.includes(",") ? (
            islands.split(",").map((item, index) => (
              <ul className="d-flex">
                <img src={compass} draggable={false} alt="compassImage" />
                <li key={index} className="m-0 p-2">
                  {item.trim()}
                </li>
              </ul>
            ))
          ) : (
            <ul className="d-flex">
            <img src={compass} draggable={false} alt="compassImage" />
            <li className="m-0 p-2">{islands}</li>
            </ul>
          )}


        </div>
        <div className="col-2 d-flex align-items-center justify-content-center">
          <img src={compass} draggable={false} alt="compassImage" />
        </div>
        <div className="col-5 d-flex flex-column align-items-end justify-content-between">
          <h4 style={{ marginRight: "10px", marginBottom: "10px" }} className="text-danger mb-0">{translatedDay}</h4>
          <div className="d-flex flex-column align-items-end">
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <Typography variant="body1" color="textPrimary" style={{ marginRight: "10px", marginBottom: "10px" }}>
                THB
              </Typography>
              <Typography variant="h5" style={{ marginRight: "10px", marginBottom: "10px", color: "green" }}>
                {formattedPrice}
              </Typography>
            </div>
            <div className="d-flex align-items-end content mb-0" style={{ marginRight: "-10px", marginBottom: "10px" }}>
              <Link
                to={`/bookingInquiry/${slug}`}
                className="btn btn-primary"
                id="buttonBooking"
              >
                {t("Booking Inquiry")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackagesCard;
