import React from "react";
import CircleFrame from "../../Asset/icon/circleFrame.svg";
import SteeringFrame from "../../Asset/icon/steeringFrame.svg";
import Location from "../../Asset/icon/location.svg";
const BestSellerCard = ({ image, headline, location, country, score }) => {
  return (
    <div>
      <div className="card-bestSeller" >
        <div className="image-container-bestSeller">
          <img src={CircleFrame} className="circleFrame" alt="circleFrame" />
          <img
            draggable={false}
            src={SteeringFrame}
            className="steeringFrame"
            alt="steeringFrame"
          />
          <img
            src={image}
            draggable={false}
            className="carouseld-bestSeller"
            alt="carouseld-bestSeller"
          />
        </div>
        <div className="content-bestSeller">
          <h3>{headline}</h3>
          <div className="location-bestSeller">
            <img src={Location} draggable={false} alt="Location-bestSeller" />
            <p>{location} , {country}</p>
            <p>{score}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BestSellerCard;
