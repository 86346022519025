import ProviderAPI from "../redux/ProviderAPI";


export const getYachtInfoWaterToy = async (data, token) => {
    const result = await ProviderAPI({
      type: "GET",
      url: "yachtInfoWaterToy/detailYachtInfoWaterToy",
      data,
      token,
    });
      // console.log(result);
    return result;
  };
    