import React, { useState, useEffect } from 'react';

// Predefined list of background colors for initials avatar
const avatarColors = ['#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', '#ff5722', '#795548', '#607d8b'];

const InitialsAvatar = ({ initials, onClick, classNameIcon }) => {
    const storedColor = localStorage.getItem('avatarBackgroundColor'); // Retrieve stored color from local storage
    const [backgroundColor, setBackgroundColor] = useState(storedColor || avatarColors[Math.floor(Math.random() * avatarColors.length)]);

    useEffect(() => {
        localStorage.setItem('avatarBackgroundColor', backgroundColor); // Store the current color in local storage
    }, [backgroundColor]);

    return (
        <div
            className={classNameIcon}
            style={{
                backgroundColor: backgroundColor,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '50%',
                color: '#fff',
                fontWeight: 'bold',
                fontSize: '1rem',
                textTransform: 'uppercase',
                cursor: 'pointer', // Allow cursor interaction
                border: '2px solid white',
            }}
            onClick={onClick} // Handle click event
        >
            {initials}
        </div>
    );
};

export default InitialsAvatar;
