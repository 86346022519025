import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import HomePage from "./views/app/homePages/HomePage";
import Destination from "./views/app/destination/Destination";
import DetailIsland from "./views/app/destination/components/detaildestination/detailIsland/DetailIsland";
import DetailProvince from "./views/app/destination/components/detaildestination/detailProvince/DetailProvince";
import DetailYacht from "./views/app/yacht/components/detailYacht/DetailYacht";
import Contact from "./views/app/contact/Contact";
import Become from "./views/app/become/Become";
import TestPage from "./views/app/testpage/testpage";
import Yacht from "./views/app/yacht/Yacht";
import BookingInquiry from "./views/app/booking/BookingInquiry";
import RegisterPage from "./views/user/register.js"
import LoginPage from "./views/user/login.js"
import ForgetPassPage from "./views/user/forgotPass.js"
import './i18n.js'
import ScrollToTop from './ScrollToTop';
import UserInformation from "./views/app/infomation/UserInformation.js"


const ProtectedRoute = ({ isAuthenticated, element }) => {
  return isAuthenticated ? element : <Navigate to="/" replace state={{ openLoginModal: true }} />;
};


function App() {
  const isAuthenticated = useSelector((state) => state.AuthSlice.isAuthenticated);

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/destination" element={<Destination />} />
        <Route path="/yacht" element={<Yacht />} />
        <Route path="/contact us" element={<Contact />} />
        <Route path="/become a partner" element={<Become />} />
        <Route path="/testpage" element={<TestPage />} />
        <Route path="/userinformation" element={<ProtectedRoute isAuthenticated={isAuthenticated} element={<UserInformation />} />} />

        <Route path="/destination/detailIsland/:slug" element={<DetailIsland />} />
        <Route path="/destination/detailProvince/:slug" element={<DetailProvince />} />
        <Route path="/yacht/:desName" element={<Yacht />} />
        <Route path="/yacht/:desName/:rateType" element={<Yacht />} />
        <Route path="/yacht/:desName/:rateType/:date" element={<Yacht />} />
        <Route path="/yacht/detailYacht/:slug" element={<DetailYacht />} />
        <Route path="/bookingInquiry/:slug" element={<BookingInquiry />} />
      </Routes>
    </Router>
  );
}

export default App;
