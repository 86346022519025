import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Button, Image } from 'react-bootstrap';
import { getUserDetail, editUserDetail } from '../../../../redux/Auth/AuthSlice';
import { getNationalityDetail, getCountryValueDetail } from '../../../../redux/centerData/CenterDataSlice';
import { useTranslation } from "react-i18next";
import { BsPencilSquare } from 'react-icons/bs'; // Import edit icon
import { FaUpload } from 'react-icons/fa'; // Import upload icon
import BoatHelm from "../../../../Asset/icon/boat_helm.svg";
import "../../../../components/card/bookingDepositCard.scss"
const PersonalInformation = () => {
  const selectedLanguage = localStorage.getItem("selectedLanguage");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const todos = useSelector((state) => state);
  const gettoken = useSelector((state) => state.AuthSlice.token);
  const [isEdit, setIsEdit] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const UserData = todos.AuthSlice.user;
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    birthDate: '',
    gender: '',
    passportId: '',
    email: '',
    mobile: '',
    nationality: '',
    country: '',
    address: ''
  });

  const [imageFile, setImageFile] = useState(null); // State to store uploaded image file


  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        dispatch(getUserDetail({ token: gettoken })),
        dispatch(getNationalityDetail({})),
        dispatch(getCountryValueDetail({})),
      ]);
    };
    fetchData();
  }, [dispatch, gettoken]);

  useEffect(() => {
    const UserData = todos.AuthSlice.user;
    if (UserData) {
      setFormData({
        firstName: UserData?.firstname || '',
        lastName: UserData?.lastname || '',
        birthDate: UserData?.date_of_birth ? formatDateForInput(UserData?.date_of_birth) : '',
        gender: UserData?.gender || '',
        passportId: UserData?.id_passport || '',
        email: UserData?.email || '',
        mobile: UserData?.mobile_no || '',
        nationality: UserData?.idNationality || '',
        country: UserData?.country_value || '',
        address: UserData?.address || '',
      });
    }
  }, [UserData]);


  const getNationalityName = (nationalityId) => {
    const nationalityResult = todos.CenterDataSlice.Nationality.results;
    const nationality = nationalityResult?.find((item) => item.idNationality === nationalityId);
    if (nationality) {
      return selectedLanguage === 'th' ? nationality.nationality_name_thai : nationality.nationality_name;
    }
    return '';
  };

  const getCountryName = (countryId) => {
    const countryResult = todos.CenterDataSlice.CountryValue.results;
    const country = countryResult?.find((item) => item.value === countryId);
    if (country) {
      return selectedLanguage === 'th' ? country.label_th : country.label;
    }
    return '';
  };


  const handleToggleEdit = () => {
    setIsEdit(!isEdit);
    const UserData = todos.AuthSlice.user;
    if (UserData) {
      setFormData({
        firstName: UserData?.firstname || '',
        lastName: UserData?.lastname || '',
        birthDate: UserData?.date_of_birth ? formatDateForInput(UserData?.date_of_birth) : '',
        gender: UserData?.gender || '',
        passportId: UserData?.id_passport || '',
        email: UserData?.email || '',
        mobile: UserData?.mobile_no || '',
        nationality: UserData?.idNationality || '',
        country: UserData?.country_value || '',
        address: UserData?.address || '',
      });
    }
    setImageFile(null)
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const formatDateForInput = (dateString) => {
    const [year, month, day] = dateString.split('/');
    return `${year}-${month}-${day}`;
  };

  const formatDateForOutput = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${year}/${month}/${day}`;
  };

  const handleMouseEnter = () => {
    if (isEdit) {
      setIsHover(true);
    }
  };

  const handleMouseLeave = () => {
    if (isEdit) {
      setIsHover(false);
    }
  };

  const handleSave = async () => {
    // Convert birthDate back to format yyyy/mm/dd
    const formattedFormData = {
      ...formData,
      birthDate: formatDateForOutput(formData.birthDate),
      imageFile // Add imageFile to formattedFormData
    };
    //console.log("formData Send", formattedFormData);

    // Dispatch the editUserDetail action
    const editUser = await dispatch(editUserDetail({ dataForm: formattedFormData, token: gettoken }));

    // Check if the status is true or false
    if (editUser.status) {
      // If true, refresh the page
      window.location.reload();
      setImageFile(null);
    } else {
      // If false, show a modal with a failure message
      // You can implement your modal logic here
      alert("Failed to change user details. Please try again.");
      window.location.reload();
    }

    // Disable edit mode after saving
    setIsEdit(false);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImageFile(reader.result); // Store base64 image data
    };
  };

  return (
    <div style={{ padding: '20px' }}>
      <img className="BoatHelm-1" draggable={false} style={{ marginTop: "5vh" }} src={BoatHelm} alt="BoatHelm" />
      <h3>{t("Personal Information")}</h3>
      <div className="row">
        <div className="col-md-5">
          <Form>
            <Form.Group className="mb-3 row" controlId="firstName">
              <Form.Label className="col-md-4 col-sm-4 col-form-label">{t('First Name')}</Form.Label>
              <div className="col-md-8 col-sm-8">
                <Form.Control
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  disabled={!isEdit}
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3 row" controlId="lastName">
              <Form.Label className="col-sm-4 col-form-label">{t('Last Name')}</Form.Label>
              <div className="col-sm-8">
                <Form.Control
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  disabled={!isEdit}
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3 row" controlId="birthDate">
              <Form.Label className="col-sm-4 col-form-label">{t('Birth Date')}</Form.Label>
              <div className="col-sm-8">
                <Form.Control
                  type="date"
                  name="birthDate"
                  value={formData.birthDate}
                  onChange={handleChange}
                  disabled={!isEdit}
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3 row" controlId="gender">
              <Form.Label className="col-sm-4 col-form-label">{t('Gender')}</Form.Label>
              <div className="col-sm-8 d-flex align-items-center">
                <Form.Check
                  inline
                  type="radio"
                  label="Male"
                  name="gender"
                  value="male"
                  checked={formData.gender === 'male'}
                  onChange={handleChange}
                  disabled={!isEdit}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Female"
                  name="gender"
                  value="female"
                  checked={formData.gender === 'female'}
                  onChange={handleChange}
                  disabled={!isEdit}
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3 row" controlId="passportId">
              <Form.Label className="col-sm-4 col-form-label">{t('Passport ID')}</Form.Label>
              <div className="col-sm-8">
                <Form.Control
                  type="text"
                  name="passportId"
                  value={formData.passportId}
                  onChange={handleChange}
                  disabled={!isEdit}
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3 row" controlId="email">
              <Form.Label className="col-sm-4 col-form-label">{t('Email')}</Form.Label>
              <div className="col-sm-8">
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  disabled
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3 row" controlId="mobile">
              <Form.Label className="col-sm-4 col-form-label">{t('Mobile')}</Form.Label>
              <div className="col-sm-8">
                <Form.Control
                  type="tel"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  disabled={!isEdit}
                />
              </div>
            </Form.Group>

          </Form>
        </div>

        <div className="col-md-7">
          <div
            className=''
            style={{ display: 'flex', position: 'relative', width: '150px', height: '150px', marginBottom: '15px', marginLeft: '30px' }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              style={{ display: 'none' }}
              disabled={!isEdit}
              id="profileImageUpload"
            />
            <label htmlFor="profileImageUpload">
              {UserData?.user_image || imageFile ? (
                <Image
                  src={imageFile || UserData?.user_image}
                  alt="Profile Picture"
                  style={{
                    width: '150px',
                    height: '150px',
                    borderRadius: '15px',
                    display: isHover && isEdit ? 'none' : 'block'
                  }}
                  className="mb-3"
                />
              ) : (
                <div
                  style={{
                    width: '150px',
                    height: '150px',
                    borderRadius: '15px',
                    backgroundColor: '#f0f0f0',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#888',
                    textAlign: 'center',
                    padding: '10px'
                  }}
                >
                  {t('You don\'t have a profile image, please upload.')}
                </div>
              )}
              {isHover && isEdit && (
                <div
                  style={{
                    width: '150px',
                    height: '150px',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '15px',
                    cursor: 'pointer'
                  }}
                >
                  <FaUpload size={30} color="white" />
                </div>
              )}
            </label>
          </div>

          <Form.Group className="mb-3 row" controlId="nationality">
            <Form.Label className="col-12 col-md-4 col-xs-12 col-form-label">{t('Nationality')}</Form.Label>
            <div className="col-md-8 col-xs-12">
              <Form.Control
                as="select"
                name="nationality"
                value={formData.nationality}
                onChange={handleChange}
                disabled={!isEdit}
              >
                {/* Render default option from userDetail */}
                {formData.nationality && (
                  <option value={getNationalityName(parseInt(formData.nationality))}>
                    {(formData.nationality)}
                  </option>
                )}

                {/* Render other nationality options if data exists */}
                {todos.CenterDataSlice.Nationality.results &&
                  todos.CenterDataSlice.Nationality.results.map((option) => (
                    <option key={option.idNationality} value={option.idNationality}>
                      {selectedLanguage === 'en' ? option.nationality_name : option.nationality_name_thai}
                    </option>
                  ))}
              </Form.Control>
            </div>
          </Form.Group>

          <Form.Group className="mb-3 row" controlId="country">
            <Form.Label className="col-12 col-md-4 col-xs-12 col-form-label">{t('Country')}</Form.Label>
            <div className="col-md-8 col-xs-12 ">
              <Form.Control
                as="select"
                name="country"
                value={formData.country}
                onChange={handleChange}
                disabled={!isEdit}
              >
                {/* Render default option from userDetail */}
                {formData.country && (
                  <option value={getCountryName(parseInt(formData.country))}>
                    {(formData.country)}
                  </option>
                )}

                {/* Render other nationality options if data exists */}
                {todos.CenterDataSlice.CountryValue.results &&
                  todos.CenterDataSlice.CountryValue.results.map((option) => (
                    <option key={option.value} value={option.value}>
                      {selectedLanguage === 'en' ? option.label : option.label_th}
                    </option>
                  ))}
              </Form.Control>

            </div>
          </Form.Group>

          <Form.Group className="mb-3 row" controlId="address" rowSpan={2}>
            <Form.Label className="col-12 col-md-4 col-xs-12 col-form-label">{t('Address')}</Form.Label>
            <div className="col-md-8 col-xs-12">
              <Form.Control
                as="textarea"
                rows={2}
                name="address"
                value={formData.address}
                onChange={handleChange}
                disabled={!isEdit}
              />
            </div>
          </Form.Group>

          <div className="col-xs-12 order-1 ">
            {isEdit ? (
              <div className="d-flex justify-content-between button-step1">
                <Button variant="primary" className="col-5 mb-3 button-Save" onClick={handleSave}>
                  {t('Save')}
                </Button>
                <Button variant="secondary" className="col-5 mb-3 button-Cancel" onClick={handleToggleEdit}>
                  {t('Cancel')}
                </Button>
              </div>
            ) : (
              <div className="d-flex justify-content-between button-step1">
                <Button variant="primary" className="mb-3 col-md-12 col-xs-12 button-Save" onClick={handleToggleEdit}>
                  {t('Edit Personal Information')}
                </Button>
              </div>
            )}
          </div>
          <div className="col-xs-12 button-step1">
            <Button className='mb-3 col-md-12 col-xs-12 button-Cancel' variant="secondary col-sm-12">{t('Change Password')}</Button>
          </div>

        </div>
      </div>
    </div>
  );
};

export default PersonalInformation;
