import React, { useState, useEffect } from 'react';
import { Popover, List, ListItem, ListItemText, ListItemIcon, Modal, Typography, Box, Button, IconButton } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import LoginModal from '../../../user/login';
import ProfileUnLogin from "../../../../Asset/icon/profileUnLogin.svg";
import PersonIcon from '@mui/icons-material/Person';
import HistoryIcon from '@mui/icons-material/History';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useLocation, useNavigate } from 'react-router-dom';
import { logout, getUserDetail } from '../../../../redux/Auth/AuthSlice';
import { Link } from 'react-router-dom';
import { isTokenExpired } from '../../../../utils/token';
import { useTranslation } from "react-i18next";
import CloseIcon from '@mui/icons-material/Close';
import InitialsAvatar from './InitialsAvatar'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#00305c',
        color: '#ffff',
        boxShadow: theme.shadows[10],
        fontSize: 14,
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: '#00305c',
    },
}));

const ProfileMenu = ({ isLoginModalOpen, handleOpenLoginModal, handleCloseLoginModal, switchToSignUp }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state) => state.AuthSlice.isAuthenticated);
    const token = useSelector((state) => state.AuthSlice.token);

    const [anchorEl, setAnchorEl] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
    const location = useLocation();
    const navigate = useNavigate(); // Use useNavigate for navigation
    const isHomePage = location.pathname === '/' || location.pathname === '#';
    const profileImageClassName = isAuthenticated && isHomePage ? 'profileUnLogin-svg profile-circle' :
        !isAuthenticated && isHomePage ? 'profileUnLogin-svg' :
            isAuthenticated && !isHomePage ? 'profileUnLogin-svg-a profile-circle-a' :
                'profileUnLogin-svg-a';

    const todos = useSelector((state) => state);


    useEffect(() => {
        dispatch(getUserDetail({ token: token }))
        if (token && isTokenExpired(token)) {
            dispatch(logout());
            setIsModalOpen(true); // Show modal on token expiry
        }

    }, [token, dispatch]);

    const handleProfileClick = (event) => {
        if (isAuthenticated) {
            setAnchorEl(event.currentTarget);
        } else {
            handleOpenLoginModal();
        }
    };

    const handleProfileMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        dispatch(logout());
        setAnchorEl(null);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        handleOpenLoginModal();
    };



    const handleNavigate = (component) => {
        const event = new CustomEvent('updateSelectedComponent', { detail: { selectedComponent: component } });
        window.dispatchEvent(event);
        navigate('/userinformation', { state: { selectedComponent: component } });
        handleProfileMenuClose();
    };

    const userImage = useSelector((state) => state.AuthSlice.user?.user_image || ProfileUnLogin);

    const renderProfileImage = () => {
        const user = todos?.AuthSlice?.user;

        if (isAuthenticated && user?.user_image) {
            return (
                <LightTooltip title={t("Profile")} arrow>
                    <img
                        draggable={false}
                        className={profileImageClassName}
                        src={user.user_image}
                        alt="Profile"
                        onClick={handleProfileClick}
                    />
                </LightTooltip>
            );
        } else if (isAuthenticated && user?.user_image === null) {
            const name = user?.firstname && user?.lastname ? `${user.firstname} ${user.lastname}` : '';
            const initials = name.trim().split(' ').map((n) => n[0]).join('').toUpperCase();
            return (
                <LightTooltip title={t("Profile")} arrow>
                    <InitialsAvatar initials={initials} onClick={handleProfileClick} classNameIcon={profileImageClassName} />
                </LightTooltip>
            );
        } else {
            return (
                <LightTooltip title={t("Profile")} arrow>
                    <img
                        draggable={false}
                        className={profileImageClassName}
                        src={ProfileUnLogin}
                        alt="Profile"
                        onClick={handleProfileClick}
                    />
                </LightTooltip>
            );
        }
    };



    return (
        <div>
            {renderProfileImage()}
            {/* <img
                draggable={false}
                className={profileImageClassName} // Conditional class application
                src={userImage}
                alt="Profile"
                onClick={handleProfileClick}
            /> */}
            <Popover
                id="menu"
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleProfileMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <List>
                    <ListItem button onClick={() => handleNavigate("personal")}>
                        <ListItemIcon>
                            <PersonIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("Personal Information")} />
                    </ListItem>
                    <ListItem button onClick={() => handleNavigate("booking")}>
                        <ListItemIcon>
                            <HistoryIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("Booking History")} />
                    </ListItem>
                    <ListItem button onClick={() => handleNavigate("review")}>
                        <ListItemIcon>
                            <ThumbUpIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("Review(s)")} />
                    </ListItem>
                    <ListItem button onClick={() => handleNavigate("whitelist")}>
                        <ListItemIcon>
                            <FavoriteIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("Whitelist(s)")} />
                    </ListItem>
                    <ListItem button onClick={handleLogout}>
                        <ListItemIcon>
                            <ExitToAppIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("Logout")} />
                    </ListItem>
                </List>
            </Popover>

            <LoginModal
                open={isLoginModalOpen}
                onClose={handleCloseLoginModal}
                onSignUp={switchToSignUp}
            />

            <Modal
                open={isModalOpen}
                onClose={handleModalClose}
                aria-labelledby="timeout-modal-title"
                aria-describedby="timeout-modal-description"
            >
                <Box sx={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)', position: 'absolute', bgcolor: 'background.paper', boxShadow: 24, p: 4, width: 300 }}>
                    <IconButton
                        aria-label="close"
                        onClick={handleModalClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography id="timeout-modal-title" variant="h6" component="h2" align="center">
                        {t("Session Expired")}
                    </Typography>
                    <Typography id="timeout-modal-description" sx={{ mt: 2 }} align="center">
                        {t("Your session has expired. Please log in again.")}
                    </Typography>
                    <Button
                        onClick={handleModalClose}
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2, display: 'block', mx: 'auto' }}
                    >
                        {t("Login again")}
                    </Button>
                </Box>
            </Modal>
        </div>
    );
};

export default ProfileMenu;
