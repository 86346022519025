import React from 'react';
import { useTranslation } from "react-i18next";
import "../../Asset/scss/destination/svgDetail.scss"
const YachtSVG = ({ cruisingSpeed, guestDay, guestSleeping }) => {
    const { t } = useTranslation();
    const selectedLanguage = localStorage.getItem("selectedLanguage");

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="yacht-svg"
            width="85"
            height="145"
            viewBox="0 0 85 145"
        >
            <g id="Group_3416" data-name="Group 3416" transform="translate(-1520.5 -468)">
                <g id="Rectangle_384" data-name="Rectangle 384" transform="translate(1520.5 565)" fill="#fff" stroke="#151b47" strokeWidth="1">
                    <path d="M0,0H85a0,0,0,0,1,0,0V39a9,9,0,0,1-9,9H9a9,9,0,0,1-9-9V0A0,0,0,0,1,0,0Z" stroke="none" />
                    <path d="M.5.5h84a0,0,0,0,1,0,0V39A8.5,8.5,0,0,1,76,47.5H9A8.5,8.5,0,0,1,.5,39V.5A0,0,0,0,1,.5.5Z" fill="none" />
                </g>
                <g id="Rectangle_385" data-name="Rectangle 385" transform="translate(1520.5 518)" fill="#fff" stroke="#151b47" strokeWidth="1">
                    <rect width="85" height="48" stroke="none" />
                    <rect x="0.5" y="0.5" width="84" height="47" fill="none" />
                </g>
                <g id="Rectangle_390" data-name="Rectangle 390" transform="translate(1520.5 470)" fill="#fff" stroke="#151b47" strokeWidth="1">
                    <path d="M9,0H76a9,9,0,0,1,9,9V48a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V9A9,9,0,0,1,9,0Z" stroke="none" />
                    <path d="M9,.5H76A8.5,8.5,0,0,1,84.5,9V47.5a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0V9A8.5,8.5,0,0,1,9,.5Z" fill="none" />
                </g>
                <g id="Rectangle_386" data-name="Rectangle 386" transform="translate(1520.5 518)" fill="#151b47" stroke="#151b47" strokeWidth="1">
                    <rect width="85" height="15" stroke="none" />
                    <rect x="0.5" y="0.5" width="84" height="14" fill="none" />
                </g>
                <g id="Rectangle_389" data-name="Rectangle 389" transform="translate(1520.5 470)" fill="#151b47" stroke="#151b47" strokeWidth="1">
                    <path d="M9,0H76a9,9,0,0,1,9,9v6a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V9A9,9,0,0,1,9,0Z" stroke="none" />
                    <path d="M9,.5H76A8.5,8.5,0,0,1,84.5,9v5.5a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0V9A8.5,8.5,0,0,1,9,.5Z" fill="none" />
                </g>
                <text id="Cruising_Speed" data-name="Cruising Speed" transform="translate(1523.5 481)" fill="#fff" fontSize="12" fontFamily="SegoeUI, Segoe UI">
                    <tspan x={selectedLanguage === 'en' ? "0" : "10"} y="0">{t("Cruising Speed")}</tspan>
                    <tspan x="40" y="20" fill="#151b47" textAnchor="middle" fontSize="16" fontWeight="bold">{cruisingSpeed}</tspan>
                    <tspan x="42" y="30" fill="#999999" textAnchor="middle" fontSize="12" fontWeight="bold">knots</tspan>
                </text>
                <text id="Guest_Day" data-name="Guest Day" transform="translate(1536.5 528)" fill="#fff" fontSize="12" fontFamily="SegoeUI, Segoe UI">
                    <tspan x={selectedLanguage === 'en' ? "0" : "-3"} y="0">{t("Guest Day")}</tspan>
                    <tspan x="25" y="25" fill="#151b47" textAnchor="middle" fontSize="16" fontWeight="bold">{guestDay}</tspan>
                </text>
                <g id="Rectangle_387" data-name="Rectangle 387" transform="translate(1520.5 565)" fill="#151b47" stroke="#151b47" strokeWidth="1">
                    <rect width="85" height="15" stroke="none" />
                    <rect x="0.5" y="0.5" width="84" height="14" fill="none" />
                </g>
                <text id="Guest_Sleeping" data-name="Guest Sleeping" transform="translate(1523.5 576)" fill="#fff" fontSize="12" fontFamily="SegoeUI, Segoe UI">
                    <tspan x={selectedLanguage === 'en' ? "3" : "15"} y="0">{t("Guest Sleeping")}</tspan>
                    <tspan x="40" y="20" fill="#151b47" textAnchor="middle" fontSize="16" fontWeight="bold">{guestSleeping}</tspan>
                    <tspan x="42" y="30" fill="#999999" textAnchor="middle" fontSize="12" fontWeight="bold">pax</tspan>
                    s
                </text>
            </g>
        </svg>
    );
};

export default YachtSVG;
