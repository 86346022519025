import { createSlice } from "@reduxjs/toolkit";
import { getYachtInfoAmenities } from "../../API/YachtInfoAmenitiesAPI";

const initialState = {
  YachtInfoAmenities: [],
};

export const YachtInfoAmenitiesSlice = createSlice({
  name: "YachtInfoAmenitiesState",
  initialState,
  reducers: {
    updateGetYachtInfoAmenities: (state, action) => {
      state.YachtInfoAmenities = action.payload;
    },
  },
});

export const { updateGetYachtInfoAmenities } = YachtInfoAmenitiesSlice.actions;
export const getYachtInfoAmenitiesDetail = (value) => async (dispatch) => {
  await getYachtInfoAmenities(value).then(async (data) => {
    await dispatch(updateGetYachtInfoAmenities(data));
    //   await dispatch(stopLoading());
  });
};

export default YachtInfoAmenitiesSlice.reducer;
