import React, { useState, useEffect } from "react";
import BoatHelm from "../../../../Asset/icon/boat_helm.svg";
import { useTranslation } from "react-i18next";
import BookingHistoryCard from "../../../../components/card/bookingHistoryCard";
import { getBookingHistoryDetail, getBookingDetail } from "../../../../redux/booking/BookingSlice";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown, Pagination } from 'react-bootstrap';
import BookingDepositCard from "../../../../components/card/bookingDepositCard";

const BookingHistory = () => {
  const { t } = useTranslation();
  const todos = useSelector((state) => state);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const getToken = useSelector((state) => state.AuthSlice.token);

  // Pagination states
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  // Track which booking card should display the additional box
  const [expandedBooking, setExpandedBooking] = useState(null);

  useEffect(() => {
    Promise.all([
      dispatch(getBookingHistoryDetail({ token: getToken })),
      dispatch(getBookingDetail({})),
    ])
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [dispatch, getToken]);

  const booking = loading ? null : todos.Booking.BookingHistroy.results;

  // Create lookup objects
  const yachtInfoLookup = todos.YachtInformationSlice.YachtInformation.results?.reduce((acc, yacht) => {
    acc[yacht.id_yachtInfo] = yacht.id_marina;
    return acc;
  }, {});

  const marinaLookup = todos.CenterDataSlice.Marina.results?.reduce((acc, marina) => {
    acc[marina.id_marina] = marina;
    return acc;
  }, {});

  const provinceLookup = todos.CenterDataSlice.Province.results?.reduce((acc, province) => {
    acc[province.ProvinceID] = province;
    return acc;
  }, {});

  const yachtLookup = todos.YachtSlice.Yacht.results?.reduce((acc, yacht) => {
    acc[yacht.id_yacht] = yacht.name_yacht;
    return acc;
  }, {});

  const enhancedBooking = booking?.map(item => {
    const id_marina = yachtInfoLookup[item.id_yachtInfo];
    const marinaDetails = marinaLookup[id_marina] || {};
    const provinceDetails = provinceLookup[marinaDetails.id_province] || {};
    const yachtName = yachtLookup[item.id_yacht] || '';
    const yachtInfo = todos.YachtInformationSlice.YachtInformation.results?.find(yacht => yacht.id_yachtInfo === item.id_yachtInfo);
    const yachtImageUrl = yachtInfo ? yachtInfo.logo_yachtInfo_url : '';

    return {
      ...item,
      id_marina,
      ...marinaDetails,
      provinceNameEng: provinceDetails.ProvinceEng,
      provinceNameThai: provinceDetails.ProvinceThai,
      yachtName,
      yachtImageUrl
    };
  }).sort((a, b) => b.id_booking - a.id_booking);;

  // Separate recent booking
  const recentBooking = enhancedBooking?.slice(0, 1);
  const bookingHistory = enhancedBooking?.slice(1);


  //console.log("enhancedBooking", enhancedBooking)
 // console.log("bookingHistory", bookingHistory)

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentBookings = bookingHistory?.slice(indexOfFirstItem, indexOfLastItem);

  const handleItemsPerPageChange = (num) => {
    setItemsPerPage(num);
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  const handleNextPage = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage(prevPage => prevPage - 1);
  };

  const totalPages = Math.ceil(bookingHistory?.length / itemsPerPage);

  const handleBookNowClick = (idBooking) => {
    setExpandedBooking(prevState => (prevState === idBooking ? null : idBooking));
  };



  const [activeStep, setActiveStep] = useState(0); // Initialize activeStep state

  const handleStepChange = (step) => {
    setActiveStep(step); // Update activeStep state
  };

  const [initialFormFields, setInitialFormFields] = useState(); // Initialize formFields state


  return (
    <div>
      <img className="BoatHelm-1" draggable={false} style={{ marginTop: "5vh" }} src={BoatHelm} alt="BoatHelm" />
      <h3>{t("Booking History")}</h3>
      <div style={{ textAlign: "left" }}>
        <h2>{t("Recent Booking")}</h2>
      </div>
      {recentBooking?.length === 0 ? (
        <p>{t("No Booking History")}</p>
      ) : (
        recentBooking?.map((item, index) => (
          <div style={{ marginBottom: "30px" }} key={index}>
            <BookingHistoryCard
              pending={item.pending}
              yachtName={item.yacht_name}
              date={item.activity_date}
              agent={item.yachtName}
              status={item.status}
              rateType={item.activity_type}
              price={item.sum_all_price}
              marinaNameEn={item.name_marina_en}
              marinaNameTh={item.name_marina_th}
              provinceNameEng={item.provinceNameEng}
              provinceNameThai={item.provinceNameThai}
              yachtImageUrl={item.yachtImageUrl}
              idBooking={item.id_booking}
              onBookNowClick={handleBookNowClick} // Pass the callback
            />
            {expandedBooking === item.id_booking && (
              <div style={{ marginTop: '5px' }}>
                <BookingDepositCard
                  initialFormFields={{
                    contactPerson: item.guest_name,
                    mobileNumber: item.mobileNo,
                    email: item.email,
                    noOfAdults: item.adult,
                    noOfChildren: item.child,
                    depositAmount: item.sum_all_price
                  }}
                  activeStep={activeStep}
                  handleStepChange={handleStepChange}
                />
              </div>
            )}
          </div>
        ))
      )}
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h2>{t("Booking History")}</h2>
        <div>
          <span>{t("show bookings")} </span>
          <Dropdown onSelect={(e) => handleItemsPerPageChange(Number(e))} className="d-inline-block">
            <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="dropdown-items">
              {itemsPerPage}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item eventKey="5">5</Dropdown.Item>
              <Dropdown.Item eventKey="10">10</Dropdown.Item>
              <Dropdown.Item eventKey="15">15</Dropdown.Item>
              <Dropdown.Item eventKey="20">20</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <hr style={{ borderTop: "2px solid #000", width: "100%" }} />
      {bookingHistory?.length === 0 ? (
        <p>{t("No Booking History")}</p>
      ) : (
        currentBookings?.map((item, index) => (
          <div style={{ marginBottom: "30px" }} key={index}>
            <BookingHistoryCard
              pending={item.pending}
              yachtName={item.yacht_name}
              date={item.activity_date}
              agent={item.yachtName}
              status={item.status}
              rateType={item.activity_type}
              price={item.sum_all_price}
              marinaNameEn={item.name_marina_en}
              marinaNameTh={item.name_marina_th}
              provinceNameEng={item.provinceNameEng}
              provinceNameThai={item.provinceNameThai}
              yachtImageUrl={item.yachtImageUrl}
              idBooking={item.id_booking}
              onBookNowClick={handleBookNowClick} // Pass the callback
            />
            {expandedBooking === item.id_booking && (
              <div style={{ marginTop: '5px' }}>
                <BookingDepositCard
                  initialFormFields={{
                    contactPerson: item.guest_name,
                    mobileNumber: item.mobileNo,
                    email: item.email,
                    noOfAdults: item.adult,
                    noOfChildren: item.child,
                    depositAmount: item.sum_all_price
                  }}
                  activeStep={activeStep}
                  handleStepChange={handleStepChange}
                />
              </div>
            )}
          </div>
        ))
      )}
      <Pagination className="d-flex justify-content-center circular-pagination">
        <Pagination.Item className="prev" onClick={handlePreviousPage} disabled={currentPage === 1}>
          &lt;
        </Pagination.Item>
        {Array.from({ length: totalPages }, (_, index) => index + 1).map(number => (
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => handlePageChange(number)}
          >
            {number}
          </Pagination.Item>
        ))}
        <Pagination.Item className="next" onClick={handleNextPage} disabled={currentPage === totalPages}>
          &gt;
        </Pagination.Item>
      </Pagination>
    </div>
  );
};

export default BookingHistory;
