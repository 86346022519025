import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import '../../Asset/scss/yacht/yachtCard.scss'; // Import the CSS file

import YachtDetail from './yachtAssetDetail';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#00305c',
        color: '#ffff',
        boxShadow: theme.shadows[10],
        fontSize: 14,
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: '#00305c',
    },
}));

library.add(fas);
library.add(fab);

const YachtCard = ({
  headline,
  image,
  marina,
  province,
  travelAgent,
  dayOption,
  destination,
  price,
  amenities,
  waterToy,
  cruising_speed,
  guests_day,
  guests_sleeping,
  style,
}) => {
  const formattedPrice = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const { t } = useTranslation();

  const mapDay = (dayOption) => {
    switch (dayOption) {
      case "DayTrip":
        return t("Full Day");
      case "HalfDay":
        return t("Half Day");
      case "Overnight":
        return t("Overnight");
      default:
        return dayOption;
    }
  };

  const translatedDay = mapDay(dayOption);

  // Split destinations and display only first three, with tooltip for more
  const destinationsArray = destination?.split(",");
  const truncatedDestinations = destinationsArray?.slice(0, 3).join(", ");
  const moreDestinations = destinationsArray?.length > 3 ? "..." : "";
  const fullDestination = destination;


  return (
    <Card className="yacht-card">
      <Grid container spacing={0}>
        <Grid item xs={4} md={4}>
          <Box className="image-container-yacht">
            <img src={image} draggable={false} alt={headline} />
          </Box>
        </Grid>
        <Grid item xs={5} md={6}>
          <CardContent className="card-body">
            <Typography className="headline-text">{headline}</Typography>
            <Typography className="destination1-text">
              <FontAwesomeIcon icon={["fas", "map-marker-alt"]} className="mr-2" />
              <span>{marina}, {province}</span>
            </Typography>
            <Typography variant="body2" className="destination2-text">
              <LightTooltip title={fullDestination} placement="bottom" arrow>
                <span>{truncatedDestinations}{moreDestinations}</span>
              </LightTooltip>
            </Typography>
            <Typography className="text-muted destination3-text">{t("Operated")} {travelAgent.length >= 20 ? `${travelAgent.substring(0, 17)}...` : travelAgent}</Typography>
            <h2 className="text-danger text-left day-text">{translatedDay}</h2>
            <div className="destination5-text">
              <Typography variant="body2" className="destination4-text">{t("Amenities-Water")}</Typography>
              <div>
                {amenities.map((amenity, index) => (
                  <LightTooltip key={index} title={amenity.name_amenities} placement="bottom" arrow>
                    <FontAwesomeIcon icon={amenity.icon_amenities.split(" ")} className="mr-2 amenities-icon" />
                  </LightTooltip>
                ))}
                {waterToy.map((waterToy, index) => (
                  <LightTooltip key={index} title={waterToy.name_waterToy} placement="bottom" arrow>
                    <FontAwesomeIcon icon={waterToy.icon_waterToy.split(" ")} className="mr-2 amenities-icon" />
                  </LightTooltip>
                ))}
              </div>
            </div>
          </CardContent>
        </Grid>
        <Grid item xs={2} md={2}>
          <CardContent>
            <Box textAlign="right" sx={{ marginBottom: '1vh' }}>
              <YachtDetail cruisingSpeed={cruising_speed} guestDay={guests_day} guestSleeping={guests_sleeping} />
            </Box>
            <Box textAlign="right">
              <div>
                <Typography className="start-from">{t("Start-from")}</Typography>
                <div className="price-align">
                  <Typography className="thb-text">THB</Typography>
                  <Typography className="text-success price-text">{formattedPrice}</Typography>
                </div>
              </div>
            </Box>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
};

export default YachtCard;

