//
import React, { useRef, useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import BoatHelm from "../../../../Asset/icon/boat_helm.svg";
import Crab from "../../../../Asset/icon/Crab.svg";
import BinocularsRight from "../../../../Asset/icon/binoculars-right.svg";
import BinocularsLeft from "../../../../Asset/icon/Binoculars-left.svg";
import CustomButtonGroupAsArrows from "../../../../components/carousel/CustomButtonGroupAsArrows";
import BestSellerCard from "../../../../components/card/BestSellerCard";
import Image from "../../../../Asset/image/yacht/cremeschnitte-thumb.jpg";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getBookingDetail } from "../../../../redux/booking/BookingSlice";
import { getYachtInformationDetail } from "../../../../redux/yachtInformation/YachtInformationSlice";
import { getYachtInfoSellingPriceRatePlanListDetail } from "../../../../redux/YachtInfoSellingPriceRatePlanList/YachtInfoSellingPriceRatePlanListSlice";
import { getYachtDetail } from "../../../../redux/yacht/YachtSlice";
import {
  getCountryDetail,
  getProvinceDetail,
  getMarinaDetail,
} from "../../../../redux/centerData/CenterDataSlice";
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from "react-i18next";
import zIndex from "@mui/material/styles/zIndex";




function BestSeller() {

  const CustomDot = ({ onClick, index, active }) => (
    <button
      style={{
        background: active ? "#00305c" : "grey",
        width: "10px",
        height: "10px",
        margin: "5px 5px",
        border: "none",
        borderRadius: "50%",
        cursor: "pointer",
      }}
      onClick={() => onClick()}
    ></button>
  );


  const { t } = useTranslation();
  const selectedLanguage = localStorage.getItem("selectedLanguage");
  const carouselRef1 = useRef(null);
  const todos = useSelector((state) => state);
  const dispatch = useDispatch();


  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 2999, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1023, min: 720 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 719, min: 0 },
      items: 1,
      partialVisibility: true,
    },
  };


  const [selectedProvince, setSelectedProvince] = useState("Phuket");

  const [loading, setLoading] = useState(true); // Initial loading state

  // const handleLocationClick = (location) => {
  //   setSelectedLocation(location);
  // };

  // Filter YahctData based on selected location
  // useEffect(() => {
  //   const filteredData = YachtData.filter(
  //     (item) => item.province === selectedLocation
  //   );
  //   setFilteredYahctData(filteredData);
  // }, [selectedLocation]);
  useEffect(() => {
    Promise.all([
      dispatch(getProvinceDetail({})),
      dispatch(getCountryDetail({})),
      dispatch(getMarinaDetail({})),
      dispatch(getBookingDetail({})),
      dispatch(getYachtInformationDetail({})),
      dispatch(getYachtDetail({})),
      dispatch(getYachtInfoSellingPriceRatePlanListDetail({})),
    ])
      .then(() => {
        // Data loading is complete, set loading state to false
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error if needed
      });
  }, [dispatch]);
  // console.log(todos);


  const filteredBookings = todos.Booking.Booking.results
    ? todos.Booking.Booking.results.filter(
      (Booking) =>
        Booking.status === "COMPLETED" || Booking.status === "BOOKED"
    )
    : [dispatch];

  const groupedBookings = {};
  filteredBookings.forEach((booking) => {
    const idYachtInfo = booking.id_yachtInfo;
    if (!groupedBookings[idYachtInfo]) {
      groupedBookings[idYachtInfo] = [];
    }
    groupedBookings[idYachtInfo].push(booking);
  });

  // Step 3: Calculate Scores
  const yachtScores = Object.keys(groupedBookings).map((idYachtInfo) => ({
    idYachtInfo,
    score: groupedBookings[idYachtInfo].length,
  }));

  // Step 4: Sort and Select Top 5

  // edit value of top score is here
  const top5Scores = yachtScores.sort((a, b) => b.score - a.score).slice(0, 20);

  return (
    <div
      className="bodyTop-container"
      style={{
        width: " 100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        alignContent: " center",
        flexWrap: "wrap",
        flexDirection: "column",
      }}
    >
      <img className="BoatHelm-Tophotdeal" draggable={false} src={BoatHelm} alt="BoatHelm" />

      <div className="Header-Frame" style={{
        width: " 100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        alignContent: " center",
        flexWrap: "wrap",
        flexDirection: "column",
      }}>

        <div className="hotdeal-ofday">
          <h2>
            <img className="BoatHelm-hotdeal" draggable={false} src={Crab} alt="BoatHelm" />
          </h2>
          <h2 style={{ cursor: "pointer" }} onClick={() => setSelectedProvince("")} >{t("bestSeller")}</h2>
          <h2>
            <img className="BoatHelm-hotdeal" draggable={false} src={Crab} alt="BoatHelm" />
          </h2>
        </div>

        <div className="hotdeal-ofday">
          <h2>
            <img
              draggable={false}
              className="BoatHelm-hotdeal"
              src={BinocularsRight}
              alt="BoatHelm"
            />
          </h2>

          <h2 >
            {todos?.CenterDataSlice?.Marina?.results?.length > 0 ? (
              Array.from(
                new Set(
                  todos.CenterDataSlice.Marina.results.map((filteredItem) => {
                    const matchingProvince = todos.CenterDataSlice.Province.results?.find(
                      (provinceItem) => provinceItem.ProvinceID === filteredItem.id_province
                    );

                    const provinceName = matchingProvince
                      ? selectedLanguage === "th"
                        ? matchingProvince.ProvinceThai
                        : matchingProvince.ProvinceEng
                      : null;

                    return JSON.stringify({
                      name: provinceName,
                      engName: matchingProvince?.ProvinceEng,
                    });
                  })
                )
              )
                .slice(0, 5)
                .map((provinceStr, index, array) => {
                  const { name, engName } = JSON.parse(provinceStr);
                  return (
                    <div
                      key={index}
                      className={selectedProvince === engName ? "selected" : ""}
                      onClick={() => setSelectedProvince(engName)}
                    >
                      <span className="province-hot2"> {name} </span>
                      {index < array.length - 1}
                    </div>
                  );
                })
            ) : (
              <div>
                <CircularProgress style={{ color: '#00305c' }} />
              </div>
            )}
          </h2>



          <h2>
            <img
              draggable={false}
              className="BoatHelm-hotdeal"
              src={BinocularsLeft}
              alt="BoatHelm"
            />
          </h2>
        </div>
      </div>
      {loading ? (
        <div><CircularProgress style={{ color: '#00305c' }} /></div>
      ) : (
        <div style={{ position: "relative", margin: "0rem", width: "100%" }}>
          <div
            className="carousel-container"
            style={{ width: "80%", margin: "0 auto" }}
          >
            <Carousel
              ref={carouselRef1}
              customButtonGroup={
                window.innerWidth >= 719 ? (
                  <CustomButtonGroupAsArrows carouselRefs={[carouselRef1]}
                    customPreviousStyle={{ left: "-10vh", zIndex: 999 }}
                    customNextStyle={{ right: "-10vh", zIndex: 999 }}
                  />
                ) : null
              }
              renderButtonGroupOutside
              additionalTransfrom={0}
              arrows={false}
              centerMode={window.innerWidth >= 719 ? true : false}
              className=""
              containerClass="container"
              dotListClass=""
              draggable
              focusOnSelect={false}
              infinite
              itemClass=""
              keyBoardControl
              minimumTouchDrag={80}
              pauseOnHover
              renderArrowsWhenDisabled={false}
              renderDotsOutside={false}
              responsive={responsive}
              rewind={false}
              rewindWithAnimation={false}
              rtl={false}
              shouldResetAutoplay
              showDots={window.innerWidth >= 719 ? false : true}
              customDot={<CustomDot />}
              sliderClass=""
              slidesToSlide={1}
              swipeable
              autoPlay={true}
              autoPlaySpeed={4000}
            >
              {todos.YachtInfoSellingPriceRatePlanListSlice
                .YachtInfoSellingPriceRatePlanList.results &&
                todos.YachtInfoSellingPriceRatePlanListSlice.YachtInfoSellingPriceRatePlanList.results
                  .filter((item) => item.rate_plan_use_in_TMY_web_site === 1)
                  .map((filteredItem, index) => {
                    // Find matching YachtInformation
                    const matchingYachtInformation =
                      todos.YachtInformationSlice.YachtInformation.results.find(
                        (yachtItem) =>
                          yachtItem.id_yachtInfo === filteredItem.id_yachtInfo
                      );

                    // Find matching Marina
                    const matchingMarina =
                      todos.CenterDataSlice.Marina.results.find(
                        (MarinaItem) =>
                          MarinaItem.id_marina ===
                          matchingYachtInformation.id_marina
                      );

                    // Find matching Province
                    const matchingProvince =
                      todos.CenterDataSlice.Province.results.find(
                        (provinceItem) =>
                          provinceItem.ProvinceID === matchingMarina.id_province
                      );

                    // Check if all necessary data is available before rendering
                    if (
                      matchingYachtInformation &&
                      matchingMarina &&
                      matchingProvince
                    ) {
                      const matchingScore = top5Scores.find(
                        (score) =>
                          score.idYachtInfo === filteredItem.id_yachtInfo
                      );

                      // Only render if matchingProvince is found
                      if (
                        !selectedProvince ||
                        (matchingProvince &&
                          matchingProvince.ProvinceEng === selectedProvince)
                      ) {
                        return (
                          <div
                            key={index}
                            className={` ${selectedProvince === matchingProvince.ProvinceEng
                              ? `${matchingProvince}`
                              : ""
                              } ${matchingScore ? "top5" : ""}`}
                          >
                            <Link
                              to={`/yacht/detailYacht/${filteredItem.id_yachtInfo}`}

                            >
                              <BestSellerCard
                                image={
                                  matchingYachtInformation.logo_yachtInfo_url
                                }
                                headline={
                                  matchingYachtInformation.name_yacht_yachtInfo
                                }
                                location={selectedLanguage === "en" ? matchingMarina.name_marina_en : matchingMarina.name_marina_th}
                                country={selectedLanguage === "en" ? matchingProvince.ProvinceEng : matchingProvince.ProvinceThai}
                              // score={
                              //   matchingScore ? matchingScore.score : null
                              // }
                              />
                            </Link>
                          </div>
                        );
                      }
                    }
                    return null;
                  })}
            </Carousel>
          </div>
        </div>
      )}
    </div>
  );
}

export default BestSeller;
