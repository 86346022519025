import { createSlice } from "@reduxjs/toolkit";
import { getYachtInformation } from "../../API/YachtInformationAPI";

const initialState = {
  YachtInformation: [],
};

export const YachtInformationSlice = createSlice({
  name: "YachtInformationState",
  initialState,
  reducers: {
    updateGetYachtInformation: (state, action) => {
      state.YachtInformation = action.payload;
    },
  },
});

export const { updateGetYachtInformation } = YachtInformationSlice.actions;
export const getYachtInformationDetail = (value) => async (dispatch) => {
  await getYachtInformation(value).then(async (data) => {
    await dispatch(updateGetYachtInformation(data));
    //   await dispatch(stopLoading());
  });
};

export default YachtInformationSlice.reducer;
