import React, { useState, useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import TopDestinationMultiRow from "../../../../../components/multiRow/TopDestinationMultiRow";
import Footer from "../../../../../components/footerPage/Footer";
import AllHeader from "../../../../../components/Navbar/AllHeader";
import YachtCard from "../../../../../components/card/YachtCard";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { YachtData } from "../../../../../components/data/YachtData";
import ImageUnderCard from "../../../../../components/card/ImageUnderCard";
import BoatHelm from "../../../../../Asset/icon/boat_helm.svg";
import detailsAssetsYachtType from "../../../../../Asset/icon/detailsAssetsYachtType.svg";
import TripDetailCard from "../../../../../components/card/TripDetailCard";
import PackagesCard from "../../../../../components/card/PackagesCard";
import BestSeller from "../../../homePages/components/BestSeller";
import { getYachtInfoSellingPriceRatePlanListDetail } from "../../../../../redux/YachtInfoSellingPriceRatePlanList/YachtInfoSellingPriceRatePlanListSlice";
import { getYachtInfoAmenitiesDetail } from "../../../../../redux/YachtInfoAmenities/YachtInfoAmenitiesSlice";
import { getYachtInfoWaterToyDetail } from "../../../../../redux/YachtInfoWaterToy/YachtInfoWaterToySlice";
import { getYachtInfoGalleryDetail } from "../../../../../redux/yachtInfoGallery/YachtInfoGallerySlice";
import { getYachtInfoDestinationPackageDetail } from "../../../../../redux/yachtInfoDestinationPackage/YachtInfoDestinationPackageSlice";
import {
  getMarinaDetail,
  getProvinceDetail,
  getDestinationDetail
} from "../../../../../redux/centerData/CenterDataSlice";
import { getYachtDetail } from "../../../../../redux/yacht/YachtSlice";
import { useTranslation } from "react-i18next";
import CircularProgress from '@mui/material/CircularProgress';
import YachtType from "../../../../../components/card/yachtTypeDetail"


function DetailYacht() {
  const { t } = useTranslation();
  const selectedLanguage = localStorage.getItem("selectedLanguage");

  const { slug } = useParams();
  // const selectedYacht = YachtData.find((yacht) => yacht.headline === slug);
  const navigate = useNavigate();



  const todos = useSelector((state) => state);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true); // Initial loading state
  useEffect(() => {
    // Fetch data from the API

    Promise.all([
      dispatch(getDestinationDetail({})),
      dispatch(getYachtInfoSellingPriceRatePlanListDetail({})),
      dispatch(getMarinaDetail({})),
      dispatch(getProvinceDetail({})),
      dispatch(getYachtDetail({})),
      dispatch(getYachtInfoAmenitiesDetail({})),
      dispatch(getYachtInfoWaterToyDetail({})),
      dispatch(getYachtInfoGalleryDetail({})),
      dispatch(getYachtInfoDestinationPackageDetail({})),
    ])
      .then(() => {
        // Data loading is complete, set loading state to false
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error if needed
      });
  }, [dispatch]);



  const selectedYacht = loading
    ? []
    : (todos.YachtInfoSellingPriceRatePlanListSlice.YachtInfoSellingPriceRatePlanList?.results || []).filter(
      (YachtInfoSellingPriceRatePlanList) =>
        YachtInfoSellingPriceRatePlanList.id_yachtInfo === slug
    );

  //console.log("selectedYacht", selectedYacht);

  const selectedYachtWithMarinaAndProvince = loading
    ? []
    : selectedYacht.map((yacht) => {
      const marina = todos.CenterDataSlice.Marina?.results.find(
        (marina) => marina.id_marina === yacht.id_marina
      );

      const province = todos.CenterDataSlice.Province?.results.find(
        (province) => province.ProvinceID === marina?.id_province
      );

      const travelAgent = todos.YachtSlice.Yacht?.results.find(
        (y) => y.id_yacht === yacht.id_yacht
      );

      const YachtInfoAmenities = todos.YachtInfoAmenitiesSlice.YachtInfoAmenities?.results.filter(
        (yachtInfoAmenities) =>
          yachtInfoAmenities.id_yachtInfo === yacht.id_yachtInfo
      );

      const YachtInfoWaterToy = todos.YachtInfoWaterToySlice.YachtInfoWaterToy?.results.filter(
        (yachtInfoWaterToy) =>
          yachtInfoWaterToy.id_yachtInfo === yacht.id_yachtInfo
      );

      const YachtInfoGallery = todos.YachtInfoGallerySlice.YachtInfoGallery?.results.filter(
        (yachtInfoGallery) =>
          yachtInfoGallery.id_yachtInfo === yacht.id_yachtInfo
      );

      const YachtInfoDestinationPackage = todos.YachtInfoDestinationPackageSlice.YachtInfoDestinationPackage?.results.find(
        (yachtInfoDestinationPackage) =>
          yachtInfoDestinationPackage.id_destination_package ===
          yacht.id_destination_package
      );

      // Extract and format the destination list
      const destinationIds = YachtInfoDestinationPackage?.destination_included?.split(",") || [];

      const destinationNamesEn = destinationIds.map((destinationId) => {
        const destination = todos.CenterDataSlice.Destination.results.find(
          (destination) => destination.id_destination === destinationId
        );
        return destination ? destination.name_destination_en : null;
      });

      const destinationNamesTh = destinationIds.map((destinationId) => {
        const destination = todos.CenterDataSlice.Destination.results.find(
          (destination) => destination.id_destination === destinationId
        );
        return destination ? destination.name_destination_th : null;
      });

      const formattedDestinationListEn = destinationNamesEn.join(",");
      const formattedDestinationListTh = destinationNamesTh.join(",");

      return {
        ...yacht,
        name_marina_en: marina ? marina.name_marina_en : null,
        name_marina_th: marina ? marina.name_marina_th : null,
        ProvinceEng: province ? province.ProvinceEng : null,
        ProvinceThai: province ? province.ProvinceThai : null,
        ProvinceID: province ? province.ProvinceID : null,
        travelAgent: travelAgent ? travelAgent.name_yacht : null,
        YachtInfoAmenities: YachtInfoAmenities || [],
        YachtInfoWaterToy: YachtInfoWaterToy || [],
        YachtInfoGallery: YachtInfoGallery || [],
        YachtInfoDestinationPackage: YachtInfoDestinationPackage || null,
        formattedDestinationListEn: formattedDestinationListEn || null,
        formattedDestinationListTh: formattedDestinationListTh || null,
      };
    });

  //console.log("selectedYachtWithMarinaAndProvince", selectedYachtWithMarinaAndProvince);


  return (
    <div>
      <AllHeader />
      <div className="container" style={{ marginTop: "5vh" }}>
        <div className="row align-items-stretch">


          <div className="col-lg-1 d-none d-lg-block"></div>
          <div className="col-lg-5 text-center order-md-2 col-md-6 mb-4 p-0">
            <div className="mb-4">
              <img className="BoatHelm-1" draggable={false} src={BoatHelm} alt="BoatHelm" />
              <h2>{t("Yacht lists")}</h2>
            </div>

            <div className="mb-4" >
              {!loading ? (
                <div>
                  {selectedYachtWithMarinaAndProvince[0] ? (
                    <div>
                      <YachtCard
                        headline={
                          selectedYachtWithMarinaAndProvince[0].name_yacht_yachtInfo
                        }
                        image={
                          selectedYachtWithMarinaAndProvince[0].logo_yachtInfo_url
                        }
                        marina={selectedLanguage === 'en' ? selectedYachtWithMarinaAndProvince[0].name_marina_en : selectedYachtWithMarinaAndProvince[0].name_marina_th}
                        province={selectedLanguage === 'en' ?
                          selectedYachtWithMarinaAndProvince[0].ProvinceEng : selectedYachtWithMarinaAndProvince[0].ProvinceThai
                        }
                        travelAgent={
                          selectedYachtWithMarinaAndProvince[0].travelAgent
                        }
                        dayOption={selectedYachtWithMarinaAndProvince[0].rate_type}
                        price={
                          selectedYachtWithMarinaAndProvince[0].value_sellingPrice
                        }
                        amenities={selectedYachtWithMarinaAndProvince[0].YachtInfoAmenities}
                        waterToy={selectedYachtWithMarinaAndProvince[0].YachtInfoWaterToy}
                        cruising_speed={selectedYachtWithMarinaAndProvince[0].cruising_speed}
                        guests_day={selectedYachtWithMarinaAndProvince[0].guests_day}
                        guests_sleeping={selectedYachtWithMarinaAndProvince[0].guests_sleeping}
                      />

                      <ImageUnderCard
                        Image={selectedYachtWithMarinaAndProvince[0].YachtInfoGallery.map(
                          (YachtInfoGallery) =>
                            YachtInfoGallery.photoUrl_yachtInfoGallery
                        )}
                      />
                    </div>
                  ) : (
                    <div>{t("No yachts available")}</div>
                  )}
                </div>
              ) : (
                <div> <CircularProgress style={{ color: '#00305c' }} /> </div>
              )}
              <div className="row align-items-start">
                <div className="col-lg-4 text-center order-md-4 col-md-6 mb-4 " style={{ marginRight: 'auto' }}>
                  <YachtType
                    value_yachtTypes={selectedYachtWithMarinaAndProvince[0] ? selectedYachtWithMarinaAndProvince[0].value_yachtTypes || '' : ''}
                    refit_year={selectedYachtWithMarinaAndProvince[0] ? selectedYachtWithMarinaAndProvince[0].refit_year : ''}
                    crew={selectedYachtWithMarinaAndProvince[0] ? selectedYachtWithMarinaAndProvince[0].crew : ''}
                    max_speed={selectedYachtWithMarinaAndProvince[0] ? selectedYachtWithMarinaAndProvince[0].max_speed : ''}
                    year={selectedYachtWithMarinaAndProvince[0] ? selectedYachtWithMarinaAndProvince[0].year : ''}
                  />
                </div>
                <div className="col-lg-6 text-center order-md-3 col-md-6 mb-4 ">
                  {selectedYachtWithMarinaAndProvince[0] && (
                    <TripDetailCard
                      tripDetail={
                        selectedLanguage === 'en' ? selectedYachtWithMarinaAndProvince[0].trip_inclusions_en :
                          selectedYachtWithMarinaAndProvince[0].trip_inclusions_th
                      }
                      title="Trip Inclusions"
                    />
                  )}

                  {selectedYachtWithMarinaAndProvince[0] && (
                    <TripDetailCard
                      tripDetail={
                        selectedLanguage === 'en' ?
                          selectedYachtWithMarinaAndProvince[0].trip_excluded_en :
                          selectedYachtWithMarinaAndProvince[0].trip_excluded_th
                      }
                      title="Trip Excluded"
                    />
                  )}
                </div>

              </div>
              <div>
                <img className="BoatHelm-1" draggable={false} src={BoatHelm} alt="BoatHelm" />
                <h2>{t("Destination Packages")}</h2>
                {loading ? (
                  <div><CircularProgress style={{ color: '#00305c' }} /></div>
                ) : (
                  <div className="col-lg-12 text-center order-md-5 col-md-6 mb-4 " style={{ marginRight: 'auto' }}>
                    <ul className="p-0">
                      {selectedYachtWithMarinaAndProvince.map((item, index) => (
                          <PackagesCard
                            name={
                              item.YachtInfoDestinationPackage.name_destination_package
                            }
                            islands={selectedLanguage === 'en' ? item.formattedDestinationListEn : item.formattedDestinationListTh}
                            price={
                              item.value_sellingPrice
                            }
                            dayOption={item.rate_type}
                            slug={item.id_sellingPrice}
                          />
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-4 text-center order-md-1 col-md-6 mb-4">
            <TopDestinationMultiRow />
          </div>
          <div className="col-lg-1 order-md-6 d-none d-lg-block"></div>
          <div className="col-lg-12 text-center order-md-6 col-md-12 mb-4">
            <BestSeller />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default DetailYacht;
