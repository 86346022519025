import React, { useState, useEffect } from 'react';
import { Button, Modal, TextField, Typography, Stack, IconButton, SvgIcon, Link, InputAdornment, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import GoogleIcon from '@mui/icons-material/Google';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import leafLeft from "../../Asset/icon/leafLeft.svg";
import leafRight from "../../Asset/icon/leafRight.svg";
import { useDispatch, useSelector } from 'react-redux';
import { LoginNew, getUserDetail } from '../../redux/Auth/AuthSlice';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    fbButton: {
        backgroundColor: '#1877F2',
        color: 'white',
        '&:hover': {
            backgroundColor: '#155DB2',
        },
        borderRadius: '40px',
    },
    googleButton: {
        backgroundColor: 'white',
        color: '#4285F4',
        border: '1px solid #DADCE0',
        '&:hover': {
            backgroundColor: '#F5F5F5',
        },
        borderRadius: '40px',
    },
    googleIcon: {
        color: '#4285F4',
    },
    signUpText: {
        marginTop: '20px',
        textAlign: 'center',
    },
    textField: {
        '& .MuiOutlinedInput-root': {
            borderRadius: '40px',
        },
    },
    modalContent: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '40px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
        maxWidth: '400px',
        width: '80%',
    },
    '@media (max-width: 720px)': {
        modalContent: {
            maxWidth: '300px',
            width: '90%',
        },
    },
}));

const FacebookWhiteIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M22.675 0H1.325C0.593 0 0 0.593 0 1.325v21.351C0 23.407 0.593 24 1.325 24H12.82V14.708h-3.286V11.083h3.286V8.413c0-3.261 1.994-5.038 4.905-5.038 1.392 0 2.589 0.104 2.937 0.15v3.408h-2.016c-1.578 0-1.883 0.75-1.883 1.85v2.424h3.766l-0.491 3.625h-3.275V24h6.419C23.407 24 24 23.407 24 22.675V1.325C24 0.593 23.407 0 22.675 0z" fill="white" />
    </SvgIcon>
);

const LoginModal = ({ open, onClose, onSignUp }) => {

    const { t } = useTranslation();
    const selectedLanguage = localStorage.getItem("selectedLanguage")
    const classes = useStyles();
    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state) => state.AuthSlice.isAuthenticated);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const todos = useSelector((state) => state);
    const [loading, setLoading] = useState(false); // State to track loading state

    useEffect(() => {
        setModalOpen(open);
    }, [open]);

    const handleLogin = async () => {
        setLoading(true); // Start loading state
        const dataLogin = await dispatch(LoginNew({ email: username, password: password }));
        if (dataLogin.status) {
            const dataUser = await dispatch(getUserDetail({ token: dataLogin.token }));
            if (dataUser.status) {
                setLoading(false); // End loading state
            }
        }
        else {
            alert("Login failed, please try again")
            setLoading(false);

        }
        handleClose();
    };

    const handleForgotPassword = () => {
        console.log('Forgot password clicked');
    };

    const handleSignUp = () => {
        console.log('Sign up clicked');
        onSignUp();
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleClose = () => {
        setModalOpen(false);
        onClose();
        setUsername('');
        setPassword('');
    };

    return (
        <Modal
            open={modalOpen} // Use separate state for modal visibility
            onClose={handleClose} // Use handleClose function
            aria-labelledby="login-modal"
            aria-describedby="login-modal-description"
        >
            <div className={classes.modalContent}>
                <IconButton
                    style={{ position: 'absolute', top: '10px', right: '10px' }}
                    onClick={handleClose}
                >
                    <CloseIcon />
                </IconButton>

                <div className="d-flex align-items-center" style={{ justifyContent: 'center', marginBottom: '20px' }}>
                    <img className="img-fluid" draggable={false} src={leafLeft} alt="leftImage" />
                    <h3 className="m-2">{t("Login")}</h3>
                    <img className="img-fluid" draggable={false} src={leafRight} alt="rightImage" />
                </div>

                <Stack spacing={3} alignItems="center">
                    <TextField
                        className={classes.textField}
                        label={t("Username")}
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        className={classes.textField}
                        label={t("Password")}
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        fullWidth
                        variant="outlined"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={togglePasswordVisibility}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <div style={{ textAlign: 'right', width: '100%' }}>
                        <Typography variant="body2" color="error" onClick={handleForgotPassword} style={{ cursor: 'pointer' }}>
                            {t("Forgot Password?")}
                        </Typography>
                    </div>
                    {loading ? ( // Render CircularProgress when loading is true
                        <CircularProgress style={{ color: '#00305c' }} />
                    ) : (
                        <Button
                            variant="contained"
                            onClick={handleLogin}
                            style={{ backgroundColor: '#00305c', borderRadius: '40px' }}
                            fullWidth
                        >
                            {t("Login")}
                        </Button>
                    )}
                </Stack>
                <div style={{ display: 'flex', alignItems: 'center', margin: '20px 0', justifyContent: 'center' }}>
                    <div style={{ borderBottom: '1px solid black', width: '100%', margin: '0 10px' }} />
                    <Typography variant="body2" align="center" style={{ padding: '0 10px' }}>
                        {t("OR")}
                    </Typography>
                    <div style={{ borderBottom: '1px solid black', width: '100%', margin: '0 10px' }} />
                </div>
                <Stack direction="row" spacing={2} justifyContent="center">
                    <Button variant="contained" className={classes.fbButton} startIcon={<FacebookWhiteIcon />} fullWidth>
                        {t("Log in with Facebook")}
                    </Button>
                </Stack>
                <Stack direction="row" spacing={2} justifyContent="center" style={{ marginTop: '10px' }}>
                    <Button variant="contained" className={classes.googleButton} startIcon={<GoogleIcon className={classes.googleIcon} />} fullWidth>
                        {t("Log in with Google")}
                    </Button>
                </Stack>
                <div className={classes.signUpText}>
                    <Typography variant="body2">
                        {t("Don't have an account?")}{' '}
                        <Link component="button" variant="body2" onClick={handleSignUp} style={{ color: '#1877F2' }}>
                            {t("Sign up")}
                        </Link>
                    </Typography>
                </div>
            </div>
        </Modal>
    );
};

export default LoginModal;
