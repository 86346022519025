import React from "react";
import Rope from "../../Asset/icon/Rope.svg";

const InfoCard = ({ image, headline, location, country }) => {
  return (
    <div className="card">
      <div className="image-container">
        <img
          aria-hidden={true}
          draggable={false}
          src={image}
          className="carousel-image"
          style={{ aspectRatio: "1 / 1" }}
          alt="Carousels Image"
        />
        <img src={Rope} alt="rope" draggable={false} className="rope-Topdes" />
      </div>
      <div className="content">
        <h4 className="headline">{headline}</h4>
        <p className="SubHeadline">
          {location}, {country}
        </p>
      </div>
    </div>
  );
};

export default InfoCard;
