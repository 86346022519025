import { createSlice } from "@reduxjs/toolkit";
import { getYacht } from "../../API/YachtAPI";

const initialState = {
  Yacht: [],
};

export const YachtSlice = createSlice({
  name: "YachtState",
  initialState,
  reducers: {
    updateGetYacht: (state, action) => {
      state.Yacht = action.payload;
    },
  },
});

export const { updateGetYacht } = YachtSlice.actions;
export const getYachtDetail = (value) => async (dispatch) => {
  await getYacht(value).then(async (data) => {
    await dispatch(updateGetYacht(data));
    //   await dispatch(stopLoading());
  });
};

export default YachtSlice.reducer;
