// Header.jsx
import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import LogoSail from "../../../../Asset/image/home/logo_Sail.png";
import MenuSail from "../../../../Asset/icon/menuSail.svg";
import enIcon from "../../../../Asset/image/home/EN_FLAG.png";
import thIcon from "../../../../Asset/image/home/TH_FLAG.png";
import ProfileUnLogin from "../../../../Asset/icon/profileUnLogin.svg";
import ModalLang from "../../../../components/pagesModal/ModalLang";
import ModalPage from "../../../../components/pagesModal/ModalPage";

import "react-datepicker/dist/react-datepicker.css";
import BgHome from "../../../../Asset/image/home/backgrondHome.jpg";
import ShapeTop from "../../../../Asset/image/home/shape-top.png";

import "../../../../Asset/scss/Homepages/header.scss";

import SignUpModal from '../../../user/register';
import SearchForm from "./SearchForm";
import ProfileMenu from './ProfileMenu';
import { useLocation } from 'react-router-dom';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#00305c',
        color: '#ffff',
        boxShadow: theme.shadows[10],
        fontSize: 14,
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: '#00305c',
    },
}));

function Header() {
  const location = useLocation();

  // Login modal
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const handleOpenLoginModal = () => setLoginModalOpen(true);
  const handleCloseLoginModal = () => setLoginModalOpen(false);

  // Register modal
  const [isRegisterOpen, setIsRegisterOpen] = useState(false);
  const handleOpenRegister = () => setIsRegisterOpen(true);
  const handleCloseRegister = () => setIsRegisterOpen(false);

  const switchToSignUp = () => setIsRegisterOpen(true);

  // Language change
  const { t } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem("selectedLanguage") || i18n.language);


  useEffect(() => {
    if (location.state?.openLoginModal) {
      handleOpenLoginModal();
    }
  }, [location.state]);


  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  const handleChangeLanguage = (newLanguage) => {
    i18n.changeLanguage(newLanguage);
    setCurrentLanguage(newLanguage);
    localStorage.setItem("selectedLanguage", newLanguage);
    setLangVisible(false);
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem("selectedLanguage");
    if (storedLanguage) {
      setCurrentLanguage(storedLanguage);
    }
  }, []);

  const [isLangVisible, setLangVisible] = useState(false);
  const handleLangToggle = () => setLangVisible(!isLangVisible);

  // Modal for MenuSail
  const [isModalVisible, setModalVisible] = useState(false);
  const handleModalToggle = () => setModalVisible(!isModalVisible);

  //
  return (
    <div>
      <div className="container">
        <nav className="navbarhome" id="navbar">
          <ul className="nav-left">
            <li>
              <LightTooltip title={t('Change Language')} arrow>
                <img
                  className="lang-svg"
                  src={currentLanguage === "en" ? enIcon : thIcon}
                  alt="Lang"
                  onClick={handleLangToggle}
                  draggable={false}
                />
              </LightTooltip>
              <ModalLang show={isLangVisible} onHide={handleLangToggle} onLangChange={handleChangeLanguage} />
            </li>
          </ul>
          <ul className="nav-right">
            <li>
              <LightTooltip title={t('Open Menu')} arrow>
                <img
                  className="menuSail-svg"
                  src={MenuSail}
                  alt="MenuSail"
                  onClick={handleModalToggle}
                  draggable={false}
                />
              </LightTooltip>

              <ModalPage show={isModalVisible} onHide={handleModalToggle} />
            </li>
            <li>
              <ProfileMenu
                isLoginModalOpen={isLoginModalOpen}
                handleOpenLoginModal={handleOpenLoginModal}
                handleCloseLoginModal={handleCloseLoginModal}
                switchToSignUp={switchToSignUp}
              />
            </li>
          </ul>
        </nav>

        <img className="shape-image" draggable={false}
          src={ShapeTop} alt="Shape-Top" />
        <img className="logoSail-image" draggable={false}
          src={LogoSail} alt="logo-Sail" />
        <h1 className="journey-a">
          JOURNEY YOUR TRIP, {''} DEGSIGN YOU EXPERIENCE
        </h1>
        <div className="container-text">
          <div className="welcome-text">
            {t("welcome")}
            <h1 className="yacht-text">
              Take My Yacht
            </h1>
          </div>
        </div>
        <SearchForm />
        <SignUpModal
          open={isRegisterOpen}
          onClose={handleCloseRegister}
        />
        <div className="overlay"></div>
        <img
          draggable={false}
          className="background-image"
          src={BgHome}
          alt="Header-Background"
        />
      </div>
    </div>
  );
}

export default Header;
