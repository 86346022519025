import React, { useState, useEffect } from "react";
import { Modal, Button, Form, FormGroup, FormControl } from "react-bootstrap";
import BoatHelm from "../../../../Asset/icon/boat_helm.svg";
import { useTranslation } from "react-i18next";
import { ContactData } from "../../../../components/data/ContactData"; // Make sure to provide the correct path

function ContactUs() {
  const { t } = useTranslation();
  const companyInfo = ContactData[0];
  const [showModal, setShowModal] = useState(false);
  const [tel, setTel] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || "en"
  );
  const handleTelChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Allow only digits
    setTel(value);
  };

  useEffect(() => {
    // Set the selected language from local storage
    const language = localStorage.getItem("selectedLanguage");
    if (language) {
      setSelectedLanguage(language);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Form submission logic here
    // console.log("Form submitted:", {
    //   tel,
    // });
    setShowSuccess(true);
  };

  const handleClose = () => setShowSuccess(false);

  return (
    <div style={{ position: 'relative' }}>
      <div className="mb-4">
        <img className="BoatHelm-1" draggable={false} src={BoatHelm} alt="BoatHelm" />
        <h2>{t("Contact Us")}</h2>
      </div>
      <div className="row justify-content-center">
        <div className="col-5 mt-0 mb-5 p-0">
          <p>{t("PKINname")}</p>
          <p>{`${t("Address")}: ${t("AddressPKIN")}`}</p>
          <div>
            <p className="m-0">{`${t("Tax ID")}: 0835557016697`}</p>
            <p className="m-0">{`${t("TAT License")}: 33/07758`}</p>
            <p className="m-0">{`${t("TEL")}: (+66)93-697-9665`}</p>
          </div>
        </div>
      </div>
      <Form className="row justify-content-center col-sm-6 col-md-12" onSubmit={handleSubmit}>
        <FormGroup className="col-6 mb-2">
          <Form.Label className="d-flex align-items-start m-0">
            {t("First Name")} *
          </Form.Label>
          <FormControl type="text" placeholder={t("First Name")} required />
        </FormGroup>

        <FormGroup className="col-6 mb-2">
          <Form.Label className="d-flex align-items-start m-0">
            {t("Last Name")} *
          </Form.Label>
          <FormControl type="text" placeholder={t("Last Name")} required />
        </FormGroup>

        <FormGroup className="col-6 mb-2">
          <Form.Label className="d-flex align-items-start m-0">
            {t("Tel.")} *
          </Form.Label>
          <FormControl
            type="tel"
            placeholder={t("Tel.")}
            value={tel}
            onChange={handleTelChange}
            required
          />
        </FormGroup>

        <FormGroup className="col-6 mb-2">
          <Form.Label className="d-flex align-items-start m-0">
            {t("Email")} *
          </Form.Label>
          <FormControl type="email" placeholder={t("Email")} required />
        </FormGroup>

        <FormGroup className="col-12 mb-2">
          <Form.Label className="d-flex align-items-start m-0">
            {t("Message")}
          </Form.Label>
          <FormControl as="textarea" rows={3} placeholder={t("Message")} />
        </FormGroup>

        <Button className="col-2" type="submit" id="province-dropdown">
          {t("Submit")}
        </Button>
      </Form>

      {showSuccess && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '999'
          }}
          onClick={handleClose}
        >
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'white',
              color: 'black',
              padding: '20px',
              border: '1px solid #000',
              borderRadius: '5px',
              textAlign: 'center',
              zIndex: '999',
              width: '600px', // Adjust width as needed
              height: 'auto', // Adjust height as needed
            }}
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal box
          >
            <h2 style={{ textAlign: 'left' }}>{t("Contact Us")}</h2>
            <button
              onClick={handleClose}
              style={{
                position: 'absolute',
                top: '10px',
                right: '30px',
                background: 'transparent',
                border: 'none',
                fontSize: '40px',
                cursor: 'pointer'
              }}
            >
              &times;
            </button>

            <hr style={{ width: '100%', margin: 'auto', marginTop: '15px', marginBottom: '15px', border: 'none', borderBottom: '1px solid #000' }} />
            <p>{t()}</p>
            <p>{t("We will get back to you as soon as possible.")}</p>
            <Button id="province-dropdown" variant="primary" onClick={handleClose}>
              {t("Done")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactUs;
