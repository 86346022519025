import ProviderAPI from "../redux/ProviderAPI";

export const getPromotion = async (data, token) => {
  const result = await ProviderAPI({
    type: "GET",
    url: "promotion/detailpromotion",
    data,
    token,
  });
  // console.log(result);
  return result;
};
