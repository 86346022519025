import React from "react";
import { Row, Col } from "react-bootstrap";
import { Element } from "react-scroll";
import Location from "../../Asset/icon/location.svg";
import IslandDetail from "./islandAssetDetail"
import { useTranslation } from "react-i18next";
import "../../Asset/scss/destination/ProvinceCard.scss"

const IslandCard = ({
  Image,
  name,
  provinceName,
  provinceCountry,
  agentLenght,
  yachtLenght,
  description,
}) => {
  const { t } = useTranslation();
  //
  const IconStyle = {
    maxWidth: "2vh",
    height: "auto",
    marginRight: "1vh",
  };
  return (
    <div>
      <Row className="d-flex align-items-start">
        <Col xs={12} md={5} className="image-provice order-3 order-md-1">
          <img
            style={{
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
            }}
            src={Image}
            draggable={false}
            alt="ProvinceImage"
          />
        </Col>
        <Col xs={7} md={5} className="province-des text-left order-1 order-md-2">
          <div>
            <div className="align-items-center">
              <h4 className="mt-2 mb-0">{name}</h4>
              <div className="d-flex align-items-center content mb-0">
                <img
                  draggable={false}
                  src={Location}
                  alt="location-IslandCard"
                  className="img-fluid"
                  style={IconStyle}
                />
                <p style={{ fontSize: "1rem", margin: "0" }}>
                  {provinceName}
                </p>
              </div>

              <p className="mb-0 text-decoration-underline">{t("Description")}</p>

              <Element
                name="test7"
                className="element"
                id="containerElement"
                style={{
                  position: "relative",
                  maxHeight: "10vh",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                <Element
                  name="firstInsideContainer"
                  style={{
                    marginBottom: "1vh",
                  }}
                >
                  <p>{description}</p>
                </Element>
              </Element>
            </div>
          </div>
        </Col>
        <Col xs={3} md={2} className="provice-detail align-self-end order-2 order-md-3">
          <div>
            <IslandDetail AgentLenght={agentLenght} YachtLenght={yachtLenght} />
            <p></p>
            <p></p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default IslandCard;
