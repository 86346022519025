import React, { useState, useEffect } from "react";
import RopeBlue from "../../../../../Asset/icon/rope_blue.svg";
import { Form } from "react-bootstrap";
import { YachtData } from "../../../../../components/data/YachtData"; // Import YachtData
import { useSelector, useDispatch } from "react-redux";
import { getYachtInfoSellingPriceRatePlanListDetail } from "../../../../../redux/YachtInfoSellingPriceRatePlanList/YachtInfoSellingPriceRatePlanListSlice";
import { getYachtTypesDetail } from "../../../../../redux/YachtTypes/YachtTypesSlice";
import { useTranslation } from "react-i18next";

import "./customCheckbox.css"; // Import the custom CSS file



const YachtType = ({ yachtTypes, setYachtTypeFilter, preYachtTypes }) => {
  const { t } = useTranslation();

  const [selectedTypes, setSelectedTypes] = useState(Array.from(preYachtTypes));

  const todos = useSelector((state) => state);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true); // Initial loading state
  useEffect(() => {
    // Fetch data from the API

    Promise.all([
      dispatch(getYachtInfoSellingPriceRatePlanListDetail({})),
      dispatch(getYachtTypesDetail({})),
    ])
      .then(() => {
        // Data loading is complete, set loading state to false
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error if needed
      });
  }, [dispatch]);

  const YachtTypes = loading
    ? [] // Set an initial value, for example, an empty array
    : todos.YachtInfoSellingPriceRatePlanListSlice.YachtInfoSellingPriceRatePlanList.results.map(
      (yacht) => ({
        ...yacht,
      })
    );
  //console.log(YachtTypes);
  const handleCheckboxChange = (event) => {
    const selectedType = event.target.value;
    setSelectedTypes((prevSelected) => {
      if (prevSelected.includes(selectedType)) {
        return prevSelected.filter((type) => type !== selectedType);
      } else {
        return [...prevSelected, selectedType];
      }
    });
    setYachtTypeFilter((prevSelected) => {
      if (prevSelected.includes(selectedType)) {
        return prevSelected.filter((type) => type !== selectedType);
      } else {
        return [...prevSelected, selectedType];
      }
    });
  };

  const handleClearFilter = () => {
    setSelectedTypes([]);
    setYachtTypeFilter([]); // Clear the filter immediately
  };
  return (
    <div className="d-flex flex-column align-items-center position-relative">
      <div className="mb-4">
        <div
          style={{
            position: "absolute",
            left: "0px",
            color: "#151B47",
          }}
        >
          {t("Yacht Type")}
        </div>
        <div
          style={{
            position: "absolute",
            right: "0px",
            cursor: "pointer",
            color: "#007BFF",
          }}
        >
          <a onClick={handleClearFilter}>{t("CLEAR")}</a>
        </div>
      </div>
      <div className="mt-4 " style={{ width: "80%" }}>
        <Form className="d-flex flex-column align-items-start ">
          {yachtTypes.map((type) => (
            <Form.Check
              className="custom-checkbox"
              key={type}
              type="checkbox"
              id={type}
              //   label={type}
              label={
                <div style={{ textAlign: 'left' }}>
                  {type} (
                  {YachtTypes.filter((yacht) => yacht.value_yachtTypes === type)
                    .length}
                  )
                </div>
              }
              value={type}
              onChange={handleCheckboxChange}
              checked={selectedTypes.includes(type)}
            />
          ))}
        </Form>
      </div>
      <div>
        <img className="m-4" draggable={false} src={RopeBlue} alt="RopeBlue" />
      </div>
    </div>
  );
};

export default YachtType;
